import { Route, Routes } from 'react-router-dom';

import Login from './Member/routes/Login';
import SignUp from './Member/routes/SignUp';
import EmailConfirmation from './Member/routes/OTP';
import ForgotPassword from './Member/routes/ForgotPassword';
import ResetPassword from './Member/routes/ResetPassword';

import { LOGIN, SIGN_UP, OTP, FORGOT_PASSWORD, RESET_PASSWORD } from '@constants/urls';

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path={LOGIN} element={<Login />} />
      <Route path={SIGN_UP} element={<SignUp />} />
      <Route path={OTP} element={<EmailConfirmation />} />
      <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={RESET_PASSWORD} element={<ResetPassword />} />
    </Routes>
  );
};

export default AuthRoutes;
