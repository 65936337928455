import { Global } from '@emotion/react';

export const Fonts = () => (
  <Global
    styles={`
      /* Copied from https://www.cdnfonts.com/avenir-next-cyr.font */

      @font-face {
        font-family: 'Avenir Next Cyr';
        font-style: normal;
        font-weight: 400;
        src: local('Avenir Next Cyr'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-Regular.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr';
        font-style: italic;
        font-weight: 400;
        src: local('Avenir Next Cyr'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-Italic.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr';
        font-style: normal;
        font-weight: 700;
        src: local('Avenir Next Cyr'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-Demi.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr';
        font-style: italic;
        font-weight: 700;
        src: local('Avenir Next Cyr'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-DemiItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr Thin';
        font-style: normal;
        font-weight: 275;
        src: local('Avenir Next Cyr Thin'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-Thin.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr Ultra Light';
        font-style: normal;
        font-weight: 275;
        src: local('Avenir Next Cyr Ultra Light'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-UltraLight.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr Thin';
        font-style: italic;
        font-weight: 275;
        src: local('Avenir Next Cyr Thin'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-ThinItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr Ultra Light';
        font-style: italic;
        font-weight: 275;
        src: local('Avenir Next Cyr Ultra Light'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-UltraLightIt.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr Light';
        font-style: normal;
        font-weight: 300;
        src: local('Avenir Next Cyr Light'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-Light.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr Light';
        font-style: italic;
        font-weight: 300;
        src: local('Avenir Next Cyr Light'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-LightItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr Medium';
        font-style: normal;
        font-weight: 450;
        src: local('Avenir Next Cyr Medium'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-Medium.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr Medium';
        font-style: italic;
        font-weight: 450;
        src: local('Avenir Next Cyr Medium'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-MediumItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr Medium';
        font-style: normal;
        font-weight: 750;
        src: local('Avenir Next Cyr Medium'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-Bold.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr Medium';
        font-style: italic;
        font-weight: 750;
        src: local('Avenir Next Cyr Medium'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-BoldItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr Heavy';
        font-style: normal;
        font-weight: 800;
        src: local('Avenir Next Cyr Heavy'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-Heavy.woff') format('woff');
    }
    @font-face {
        font-family: 'Avenir Next Cyr Heavy';
        font-style: italic;
        font-weight: 800;
        src: local('Avenir Next Cyr Heavy'), url('https://fonts.cdnfonts.com/s/14867/AvenirNextCyr-HeavyItalic.woff') format('woff');
    }
    

      `}
  />
);
