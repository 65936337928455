import dayjs from 'dayjs';
import parse, {
  DOMNode,
  domToReact,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import {
  Box,
  Center,
  Divider,
  HStack,
  Image,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';



import { ICONS } from '@routes/Service/constants/icons';

import CustomButton from '@components/Button';
import Category from '@components/Category';
import CustomBreadcrumb from '@components/CustomBreadcrumb';

import type { InfoDetailProps } from '@customTypes/info';
import type { QuickLinkProps } from '@customTypes/quickLink';

import { useGlobalState } from '@contexts/globalState';

import fetchRequest from '@utils/fetcher';

import { BREADCRUMB_PUBLIKASI } from '@constants/breakcrumbs';
import { BLACK, GREY } from '@constants/colors';
import { AUTH, LOGIN } from '@constants/urls';

const options: HTMLReactParserOptions = {
  replace: (domNode: DOMNode) => {
    if ((domNode as Element).name == 'ol') {
      return (
        <ol style={{ paddingLeft: '20px' }}>
          {domToReact((domNode as Element).children)}
        </ol>
      );
    } else if ((domNode as Element).name == 'ul') {
      return (
        <ul style={{ paddingLeft: '20px' }}>
          {domToReact((domNode as Element).children)}
        </ul>
      );
    }
  },
};

const PublicationDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryKey = location.pathname.split('/');

  const [categoryList, setCategoryList] = useState([]);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const [publicationData, setPublicationData] = useState<InfoDetailProps>();

   const [isPreviewMode, setIsPreviewMode] = useState(false);

  const toast = useToast();

  const { loginInfo } = useGlobalState();

  const getPublicationDetail = async () => {
    let param = '/' + queryKey[3];

    try {
      await fetchRequest({ method: 'GET', path: 'info' + param }).then(
        (response) => {
          setCategoryList(response.categories || []);
          setQuickLinkList(response.quickLinks || []);
          const responseData = response.data || {};
          setPublicationData(responseData);
           setIsPreviewMode(response.isPreviewMode || false);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPublicationDetail();
  }, []);

  const handleSelectedCategoryClick = () => {};

  const handleButtonClick = async () => {
    let param = '/' + queryKey[3];
    try {
      await fetchRequest({ method: 'GET', path: 'info/download' + param }).then(
        (response) => {
          if (response.linkUrl) {
            window.open(response.linkUrl);
          } else {
            toast({
              title: 'Error',
              description: response.message,
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
          }
        }
      );
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Box h="30px" paddingLeft={{ base: '10px', lg: '3%' }} w="100%">
        <CustomBreadcrumb
          breadcrumbs={[
            ...BREADCRUMB_PUBLIKASI,
            { name: publicationData?.title || '', url: '' },
          ]}
        />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop={{ base: '0px', lg: '20px' }}
      >
        <Box
          w={{ md: '20%' }}
          h="400px"
          display={{ md: 'inline', base: 'none' }}
        >
          <Category headers={[]} />
          <VStack align="start" paddingTop="8px">
            {categoryList.map((c, i) => (
              <Text
                fontSize="md"
                color={BLACK}
                key={i}
                cursor="pointer"
                onClick={() => handleSelectedCategoryClick()}
              >
                {c}
              </Text>
            ))}
          </VStack>
        </Box>
        <Box w={{ md: '60%' }}>
          <Image
            w="100%"
            h="50vh"
            objectFit="cover"
            src={publicationData?.headerUrl || ''}
          ></Image>
          <Text
            className={'fontBold'}
            fontSize={{ md: '40px', base: '24px' }}
            color={BLACK}
            textAlign={{ base: 'center', lg: 'start' }}
            marginBottom="10"
            marginTop="4"
          >
            {publicationData?.title || ''}
          </Text>
          <Box textAlign="justify" fontSize={{ lg: 'md', base: 'sm' }} w="100%">
            {parse(publicationData?.content || '', options)}
             {isPreviewMode && (
              <>
                <Box
                  w={{ md: 'calc(60% - 100px)', base: '100%' }}
                  position="absolute"
                  marginTop="-150px"
                  display="block"
                  height="150px"
                  backgroundImage="linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 100%)"
                />
                <Center>
                  <CustomButton
                    text="BACA SELENGKAPNYA"
                    variant="transparent"
                    icon="none"
                    w=""
                    onClick={() => navigate(AUTH + LOGIN)}
                  />
                </Center>
              </>
            )} 
          </Box>
          <Divider paddingTop="20px" />
        </Box>
        <Box w={{ md: '20%' }} fontSize={{ lg: 'md', base: 'sm' }}>
          <VStack align="start">
            <HStack>
              <Image src={ICONS.author} />
              <Text color={GREY}>{publicationData?.author}</Text>
            </HStack>
            <HStack>
              <Image src={ICONS.date} />
              <Text color={GREY}>
                {dayjs(publicationData?.createdAt).format('DD/MM/YYYY')}
              </Text>
            </HStack>
            <HStack>
              <Image src={ICONS.category} />
              <Text color={GREY}>
                {publicationData?.categories?.join(', ')}
              </Text>
            </HStack>
            {publicationData?.subCategories &&
              publicationData?.subCategories?.length > 0 &&
              publicationData?.subCategories[0] !== '' && (
                <HStack>
                  <Image src={ICONS.subCategory} />
                  <Text color={GREY}>
                    {publicationData?.subCategories?.join(', ')}
                  </Text>
                </HStack>
              )}
            <HStack>
              <Image src={ICONS.view} />
              <Text color={GREY}>{publicationData?.views} pembaca</Text>
            </HStack>
            <HStack>
              <Image src={ICONS.download} />
              <Text color={GREY}>{publicationData?.downloads} unduhan</Text>
            </HStack>
            {loginInfo.isLogin && (
              <CustomButton
                w="100%"
                text="UNDUH"
                icon="download"
                variant="white"
                onClick={handleButtonClick}
              />
            )}
          </VStack>
        </Box>
      </Stack>
    </Box>
  );
};

export default PublicationDetail;