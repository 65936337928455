import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';

import FilePicker from 'chakra-ui-file-picker';

import {
  Box,
  Stack,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
  Image,
  Center,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Checkbox,
  HStack,
  Button,
  useToast,
} from '@chakra-ui/react';

import {
  CheckIcon,
  EmailIcon,
  PhoneIcon,
  CalendarIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';

import imgLogo from '@assets/logo.svg';
import imgUsernameIcon from '@assets/member/person-icon.svg';
import imgPasswordIcon from '@assets/member/password.svg';
import imgPasswordConfirmIcon from '@assets/member/password-confirm.svg';

import CustomBreadcrumb from '@components/CustomBreadcrumb';
import CustomButton from '@components/Button';

import fetchRequest, { fetchFormData } from '@utils/fetcher';

import { BREADCRUMB_PENGATURAN } from '@constants/breakcrumbs';
import { MemberDataProps } from '@customTypes/member';

import { GREY, RED, LIGHTGREY } from '@constants/colors';
import { MEMBER_SETTING_RESET_PASSWORD } from '@constants/urls';

import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

const Setting = () => {
  const [memberData, setMemberData] = useState<MemberDataProps>();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [expiredDate, setExpiredDate] = useState('');
  const [isPhoneInvalid, setIsPhoneInvalid] = useState(false);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [newPicture, setNewPicture] = useState<any[]>([]);


  // const onChange: UploadProps['onChange'] = ({ fileList: FileList }) => {
  //   setNewPicture(FileList);
  // };

  const handleImageUpload = async (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setNewPicture(fileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new (Image as any)();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };


  console.log(newPicture);
  const toast = useToast();

  const navigate = useNavigate();

  const handlePhoneNumber = (value: string) => {
    const isNumber = value.match(/^[0-9\b]+$/);

    setPhoneNumber(value);

    if (isNumber) {
      setIsPhoneInvalid(false);
    } else {
      setIsPhoneInvalid(true);
    }

    if (value.length < 11) {
      setIsPhoneInvalid(true);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('phoneNumber', phoneNumber);
    if (newPicture) {
      formData.append('profilePhoto', newPicture[0]?.originFileObj || newPicture[0]?.url);
    }
    formData.append('subscribeNewsletter', String(isSubscribe));

    try {
      await fetchFormData({
        method: 'PATCH',
        path: 'member/data',
        data: formData,
      }).then((response) => {
        if (!response.error) {
          toast({
            title: 'Berhasil',
            description: 'Profil Anda telah berhasil diperbaharui',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          getMemberData();
        } else {
          toast({
            title: 'Error',
            description: response.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const getMemberData = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'member' }).then((response) => {
        if (!response.error) {
          setMemberData(response);
          setPhoneNumber(response.phoneNumber);
          setExpiredDate(
            dayjs(response.membershipValidEnd).format('DD MMMM YYYY')
          );
          setEmail(response.email);
          setName(response.name);
          setIsSubscribe(response.subscribeNewsletter);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMemberData();
  }, []);

  return (
    <Box>
      <Box h="30px" paddingLeft={{ base: '10px', lg: '3%' }} w="100%">
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_PENGATURAN} />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop={{ base: '0px', lg: '20px' }}
      >
        <Box
          w={{ md: '20%' }}
          h="400px"
          display={{ md: 'inline', base: 'none' }}
        />
        <Box w="100%">
          <VStack w="100%" marginBottom="10px">
         

<ImgCrop cropShape="round">
      <Upload
        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        name="Profile Picture"
        listType="picture-circle"
        fileList={newPicture}
        onChange={handleImageUpload}
        onPreview={onPreview}
        // beforeUpload={()=> false}
        showUploadList={{
          showPreviewIcon:false
        }}
       
      >
        {newPicture.length < 1 && '+ Edit'}
      </Upload>
      
    </ImgCrop>
 {/* <Image
              borderRadius="full"
              boxSize="150px"
              objectFit={'cover'}
              src={
                memberData?.photoUrl ||
                'https://www.seekpng.com/png/detail/966-9665317_placeholder-image-person-jpg.png'
              }
              alt="Profile Picture"
            />
            <FilePicker
              onFileChange={(fileList) => setNewPicture(fileList)}
              placeholder="Pilih Gambar"
              clearButtonLabel="Hapus File"
              multipleFiles={false}
              accept=".jpg,.png,.jpeg"
              hideClearButton={false}
            /> */}
           
          </VStack>
          <VStack align="start">
            <Text className="fontSemiBold" fontSize={{ lg: 'md', base: 'sm' }}>
              Name
            </Text>
            <InputGroup paddingTop="5px" zIndex={0}>
              <InputLeftElement
                pointerEvents="none"
                children={<Image src={imgUsernameIcon} w="30%" />}
                paddingTop="5px"
              />
              <Input
                type="text"
                placeholder="Masukkan Nama"
                _focus={{ boxShadow: 'none', borderColor: RED }}
                fontSize={{ lg: 'md', base: 'sm' }}
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                errorBorderColor={RED}
                isInvalid={name === ''}
              />
            </InputGroup>
            <Text className="fontSemiBold" fontSize={{ lg: 'md', base: 'sm' }}>
              Email
            </Text>
            <InputGroup paddingTop="5px" zIndex={0}>
              <InputLeftElement
                pointerEvents="none"
                children={<EmailIcon color="#AAAAAA" />}
                paddingTop="5px"
              />
              <Input
                type="email"
                placeholder="Masukkan Email"
                _focus={{ boxShadow: 'none', borderColor: RED }}
                fontSize={{ lg: 'md', base: 'sm' }}
                value={email}
                disabled
              />
            </InputGroup>
            <Checkbox
              isChecked={isSubscribe}
              onChange={(e: any) => setIsSubscribe(e.target.checked)}
              colorScheme="red"
            >
              Kirimkan saya berita rutin dari Astagatra Institute
            </Checkbox>
            <Text className="fontSemiBold" fontSize={{ lg: 'md', base: 'sm' }}>
              Nomor Telepon
            </Text>
            <InputGroup paddingTop="5px" zIndex={0}>
              <InputLeftElement
                pointerEvents="none"
                children={<PhoneIcon color="#AAAAAA" />}
                paddingTop="5px"
              />
              <Input
                type="text"
                placeholder="Masukkan Nomor HP"
                _focus={{ boxShadow: 'none', borderColor: RED }}
                fontSize={{ lg: 'md', base: 'sm' }}
                value={phoneNumber}
                onChange={(e: any) => handlePhoneNumber(e.target.value)}
                isInvalid={isPhoneInvalid}
                errorBorderColor={RED}
              />
            </InputGroup>
            <Text className="fontSemiBold" fontSize={{ lg: 'md', base: 'sm' }}>
              Masa Berlaku Keanggotaan
            </Text>
            <InputGroup paddingTop="5px" zIndex={0}>
              <InputLeftElement
                pointerEvents="none"
                children={<CalendarIcon color="#AAAAAA" />}
                paddingTop="5px"
              />
              <Input
                type="text"
                placeholder="Masa Berlaku Keanggotaan"
                _focus={{ boxShadow: 'none', borderColor: RED }}
                fontSize={{ lg: 'md', base: 'sm' }}
                value={expiredDate}
                disabled
              />
            </InputGroup>
            <Text className="fontSemiBold" fontSize={{ lg: 'md', base: 'sm' }}>
              Password
            </Text>
            <Button
              rightIcon={<ChevronRightIcon color={GREY} />}
              w="100%"
              bg={LIGHTGREY}
              color={GREY}
              _focus={{ boxShadow: 'none' }}
              justifyContent="space-between"
              onClick={() => navigate(MEMBER_SETTING_RESET_PASSWORD)}
            >
              <Text>Ubah Password</Text>
            </Button>
            <Box textAlign="end" w="100%">
              <CustomButton
                text="SIMPAN"
                variant="red"
                icon="none"
                w="150px"
                onClick={handleSubmit}
              />
            </Box>
          </VStack>
        </Box>
        <Box
          w={{ md: '20%' }}
          h="400px"
          display={{ md: 'inline', base: 'none' }}
        />
      </Stack>
    </Box>
  );
};

export default Setting;
