import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';

import {
  Box,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
  Center,
  Image,
} from '@chakra-ui/react';

import Category from '@components/Category';
import CustomBreadcrumb from '@components/CustomBreadcrumb';

import type { GalleryProps } from 'customTypes/info';
import type { QuickLinkProps } from 'customTypes/quickLink';

import fetchRequest from '@utils/fetcher';

import { BREADCRUMB_GALERI } from '@constants/breakcrumbs';
import { BLACK, WHITE } from '@constants/colors';
import { GALERI } from '@constants/urls';

const Gallery = () => {
  const [galleryList, setGalleryList] = useState<GalleryProps[]>([]);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  const getGalleryListData = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'gallery' }).then(
        (response) => {
          setGalleryList(response.dataList || []);
          setQuickLinkList(response.quickLinks || []);
          setTotal(response.count || 0);
          setLastPage(response.lastPage || 1);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenGallery = (queryKey: string) => {
    navigate(GALERI + '/' + encodeURIComponent(queryKey));
  };

  useEffect(() => {
    getGalleryListData();
  }, []);

  return (
    <Box>
      <Box h="30px" paddingLeft={{ base: '10px', lg: '3%' }} w="100%">
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_GALERI} />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop={{ base: '0px', lg: '20px' }}
      >
        <Box
          w={{ md: '20%' }}
          h="400px"
          display={{ md: 'inline', base: 'none' }}
        >
          {quickLinkList.length > 0 && <Category headers={quickLinkList} />}
        </Box>
        <Box w="100%">
          <Text
            className={'fontBold'}
            fontSize={{ md: '40px', base: '24px' }}
            color={BLACK}
            textAlign={{ base: 'center', lg: 'start' }}
          >
            Galeri
          </Text>
          <Box w="100%" h="40px" textAlign="end"></Box>
          <Text paddingBottom="30px">Menampilkan {total} galeri</Text>
          <Wrap>
            {galleryList.map((gl) => (
              <WrapItem key={gl.queryKey} w={{ lg: '350px', base: '100%' }}>
                <Box w={{ lg: '350px', base: '100%' }} h="350px">
                  <Image
                    src={gl.headerUrl}
                    objectFit="cover"
                    w={{ lg: '350px', base: '100%' }}
                    h="350px"
                  />
                  <Box
                    w={{ lg: '350px', base: '100%' }}
                    h="350px"
                    marginTop="-350px"
                    position="sticky"
                    opacity="0"
                    _hover={{
                      opacity: '0.8',
                      backgroundColor: BLACK,
                    }}
                  >
                    <Center w="100%" height="350px">
                      <VStack padding="10px">
                        <Text color={WHITE} as="b" textAlign="center">
                          {gl.title}
                        </Text>
                        <Text color={WHITE}>
                          {dayjs(gl.createdAt).format('DD MMMM YYYY')}
                        </Text>
                        <Text
                          color={WHITE}
                          as="u"
                          _hover={{
                            cursor: 'pointer',
                          }}
                          fontSize="sm"
                          onClick={() => handleOpenGallery(gl.queryKey)}
                        >{`Lihat Galeri >`}</Text>
                      </VStack>
                    </Center>
                  </Box>
                </Box>
              </WrapItem>
            ))}
          </Wrap>
          <Box
            w={{ md: '20%' }}
            h="100%"
            display={{ md: 'none', base: 'block' }}
            paddingTop="50px"
            paddingBottom="100px"
          >
            <Category headers={quickLinkList} />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Gallery;
