import {
  Image,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react';
import { RED } from '@constants/colors';

interface Props {
  itemUrl: string;
  onClose: () => void;
  isOpen: boolean;
}

const PhotoModal = (props: Props) => {
  const { itemUrl, onClose, isOpen } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton
          _focus={{ boxShadow: 'none' }}
          color={RED}
          bg="white"
          _hover={{ bg: RED, color: 'white' }}
        />
        <ModalBody padding="0">
          <Image src={itemUrl} objectFit="cover" w="100%" height="auto" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PhotoModal;
