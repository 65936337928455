import { useEffect, useState } from 'react';

import {
  Box,
  Stack,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';

import ReactPaginate from 'react-paginate';

import Card from '@routes/Service/components/Card';

import CustomButton from '@components/Button';
import Category from '@components/Category';
import CustomBreadcrumb from '@components/CustomBreadcrumb';
import Sort from '@routes/Service/components/Sort';

import type { InfoProps } from 'customTypes/info';
import type { QuickLinkProps } from 'customTypes/quickLink';

import fetchRequest from '@utils/fetcher';

import { BREADCRUMB_ARTIKEL } from '@constants/breakcrumbs';
import { BLACK } from '@constants/colors';
import SkeletonCard from '@routes/Service/components/SkeletonCard';

import '@routes/Service/styles/index.css';

const Article = () => {
  const [articleList, setAtricleList] = useState<InfoProps[]>([]);
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const [total, setTotal] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [articleCardWidth, setArticleCardWidth] = useState<Array<string>>([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const getArticleListData = async ({
    sort = 'latest',
    category = '',
    pg,
  }: {
    sort?: string;
    category?: string;
    pg?: number;
  }) => {
    let param =
      '?type=article&sort=' +
      sort +
      '&page=' +
      (typeof pg === 'undefined' ? page : pg);
    if (category !== '') param += '&categories=' + category;

    try {
      await fetchRequest({ method: 'GET', path: 'info' + param }).then(
        (response) => {
          setAtricleList(response.dataList || []);
          setQuickLinkList(response.quickLinks || []);
          setTotal(response.count || 0);
          setCategoryList(response.categories || []);
          setTotalPage(response.lastPage || 1);
          calculateCardWidth(response.dataList);

          setIsLoading(false);
        }
      );
    } catch (error) {
      console.log(error);

      setIsLoading(false);
    }
  };

  const handleSelectedCategoryClick = (c: string) => {
    setSelectedCategory(c);
    getArticleListData({
      sort: 'latest',
      category: c,
    });
  };

  const calculateCardWidth = (list: InfoProps[]) => {
    let result: Array<string> = [];
    for (let i = 0; i < list.length; i++) {
      let currentLength =
        (list[i].summary?.length || 0) + (list[i].title?.length || 0);
      if (i > 2 && i != list.length - 1) {
        let nextLength =
          (list[i + 1].summary?.length || 0) + (list[i + 1].title?.length || 0);
        if (
          result[i - 1] == '48%' &&
          result[i - 2] == '48%' &&
          nextLength > 250
        ) {
          result.push('100%');
        } else if (result[i - 1] == '100%' && nextLength > 250) {
          result.push('100%');
        } else {
          result.push(currentLength > 250 ? '100%' : '48%');
        }
      }
    }
    setArticleCardWidth(result);
  };

  const handlePageClick = (event: any) => {
    const selectedPage = event.selected + 1;
    setPage(selectedPage);
    getArticleListData({ pg: selectedPage });
  };

  useEffect(() => {
    getArticleListData({});
  }, []);

  return (
    <Box>
      <Box h="30px" paddingLeft={{ base: '10px', lg: '3%' }} w="100%">
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_ARTIKEL} />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop={{ base: '0px', lg: '20px' }}
      >
        <Box
          w={{ md: '20%' }}
          h="400px"
          display={{ md: 'inline', base: 'none' }}
        >
          <Category headers={quickLinkList} />
          <VStack align="start" paddingTop="8px">
            {categoryList.map((c, i) => (
              <Text
                fontSize="md"
                color={BLACK}
                key={i}
                onClick={() => handleSelectedCategoryClick(c)}
                _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {c}
              </Text>
            ))}
          </VStack>
        </Box>

        <Box w="100%">
          <Text
            className={'fontBold'}
            fontSize={{ md: '40px', base: '24px' }}
            color={BLACK}
            textAlign={{ base: 'center', lg: 'start' }}
          >
            Artikel
          </Text>
          <SkeletonCard isLoaded={!isLoading} numberOfCard={2}>
            <Box w="100%" h="40px" textAlign="end">
              <CustomButton
                text="URUTKAN"
                variant="transparent"
                icon="sort"
                w="135px"
                onClick={() => onOpen()}
              />
              <Sort
                isOpen={isOpen}
                onClose={onClose}
                handleSort={(sort) => getArticleListData({ sort: sort })}
              />
            </Box>
            <Text paddingBottom="30px">
              Menampilkan {total} artikel
              {selectedCategory !== '' && ` "${selectedCategory}"`}
            </Text>
            <Wrap justify="space-between" spacing="16px">
              {articleList.map((article, i) => (
                <WrapItem w={{ base: '100%' }} key={i}>
                  <Card {...article} />
                </WrapItem>
              ))}
            </Wrap>
            <Box marginTop={{ md: '50px', base: '30px' }}>
              <ReactPaginate
                breakLabel="..."
                nextLabel="selanjutnya"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="sebelumnya"
                className="styPagination"
              />
            </Box>
            <Box
              w={{ md: '20%' }}
              h="100%"
              display={{ md: 'none', base: 'block' }}
              paddingTop="50px"
              paddingBottom="100px"
            >
              <Category headers={quickLinkList} />
              <VStack align="start" paddingTop="8px">
                {categoryList.map((c, i) => (
                  <Text
                    fontSize="md"
                    color={BLACK}
                    key={i}
                    cursor="pointer"
                    onClick={() => handleSelectedCategoryClick(c)}
                  >
                    {c}
                  </Text>
                ))}
              </VStack>
            </Box>
          </SkeletonCard>
        </Box>
      </Stack>
    </Box>
  );
};

export default Article;
