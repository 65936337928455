import { Center, Grid, GridItem, Image, Box, CloseButton } from "@chakra-ui/react";

import imgBackground from "@assets/member/background.svg";
import imgPerson from "@assets/member/login/people-illust.svg";

import { PINK, RED, WHITE } from "@constants/colors";

import LoginCard from "./LoginCard";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Desktop view */}
      <Grid templateColumns="repeat(10, 1fr)" display={{ base: "none", lg: "grid" }}>
        <GridItem
          w="100%"
          h="100vh"
          colSpan={6}
          bg={RED}
          backgroundImage={`url(${imgBackground})`}
          backgroundRepeat="no-repeat"
          backgroundSize="100%"
          backgroundPosition="bottom"
        >
          <Center w="100%" h="100%">
            <Image src={imgPerson} objectFit="contain" boxSize="80%" />
          </Center>
        </GridItem>
        <GridItem colSpan={4} w="100%" h="100vh">
          <CloseButton
            position={"absolute"}
            right={"5px"}
            top={"10px"}
            display={{ md: "inline", base: "none" }}
            onClick={() => navigate(-1)}
          ></CloseButton>
          <Center w="100%" h="100%" padding="50px">
            <LoginCard />
          </Center>
        </GridItem>
      </Grid>

      {/* Mobile view */}
      <Box
        w="100vw"
        h="100vh"
        display={{ base: "block", lg: "none" }}
        bg={RED}
        backgroundImage={`url(${imgBackground})`}
        backgroundRepeat="no-repeat"
        backgroundSize="100%"
        backgroundPosition="bottom"
      >
        <Center w="100%" h="100%">
          <Box w="80vw" bg={WHITE} borderRadius="lg" padding="20px">
            <LoginCard />
          </Box>
        </Center>
      </Box>
    </>
  );
};

export default Login;
