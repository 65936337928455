import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import CryptoJS from 'crypto-js';

import { Box, Grid, GridItem, Center, Image } from '@chakra-ui/react';

import { BLACK } from '@constants/colors';

import imgLogo from '@assets/logo.svg';
import imgNavbarPattern from '@assets/navbar-pattern.png';

import NavbarMenu from './Menu';

import CustomButton from '@components/Button';

import { MENU } from '@constants/menus';
import { AUTH, MEMBER_DASHBOARD, LOGIN, MEMBER } from '@constants/urls';

import SearchButton from '@components/MobileNavbar/SearchButton';

import { useGlobalState } from '@contexts/globalState';

import fetchRequest from '@utils/fetcher';

const Navbar = () => {
  const navigate = useNavigate();
  const { loginInfo, setLoginInfo } = useGlobalState();
  const cookies = new Cookies();

  const username = loginInfo.username.slice(0, 12) + '...';

  const handleLoginButtonClicked = () => {
    if (loginInfo.isLogin) {
      navigate(MEMBER_DASHBOARD);
    } else {
      navigate(AUTH + LOGIN);
    }
  };

  const handleCheckToken = useCallback(async () => {
    const emailFromCookie = cookies.get('UE') || '';
    const refreshTokenFromCookie = cookies.get('member-r-token') || '';

    const decryptedEmail = emailFromCookie
      ? CryptoJS.AES.decrypt(emailFromCookie, 'user-email').toString(
          CryptoJS.enc.Utf8
        )
      : '';

    const bodyParam = {
      email: loginInfo.email || decryptedEmail,
      refreshToken: loginInfo.refreshToken || refreshTokenFromCookie,
    };

    try {
      await fetchRequest({
        method: 'POST',
        path: 'auth/refresh-token',
        data: bodyParam,
      }).then((response) => {
        const exp = response.accessToken.payload.exp;

        setLoginInfo({
          isLogin: true,
          username: 'Member',
          email: response.idToken.payload.email,
          refreshToken: response.refreshToken.token,
          expireAt: new Date(exp * 1000),
        });
        cookies.set('member-token', response.accessToken.jwtToken, {
          path: '/',
          expires: new Date(exp * 1000),
        });
      });
    } catch (error) {
      navigate(AUTH + LOGIN);
      setLoginInfo({
        isLogin: false,
        username: '',
        email: '',
        refreshToken: '',
        expireAt: new Date(),
      });
      cookies.remove('member-r-token', { path: '/' });
      cookies.remove('member-token', { path: '/' });
      cookies.remove('UE', { path: '/' });
    }
  }, []);

  useEffect(() => {
    if (loginInfo.isLogin) {
      const currentDate = new Date();

      if (currentDate >= loginInfo.expireAt) {
        handleCheckToken();
      }
    }

    if (!loginInfo.isLogin && cookies.get('member-r-token')) {
      handleCheckToken();
    }

    if (
      !loginInfo.isLogin &&
      window.location.pathname.match('/member/') &&
      !window.location.pathname.match('/v1/') &&
      !cookies.get('member-r-token')
    ) {
      navigate(AUTH + LOGIN);
    }
  }, [window.location.pathname]);

  return (
    <Box
      w="100%"
      color={BLACK}
      position="fixed"
      top="0px"
      bg="white"
      backgroundImage={`url(${imgNavbarPattern})`}
      backgroundRepeat="no-repeat"
      backgroundSize="100%"
      display={{ base: 'none', lg: 'block' }}
      zIndex={1}
      boxShadow="lg"
    >
      <Grid templateColumns="repeat(9, 1fr)" gap={12}>
        <GridItem colSpan={2} w="100%" h="124px">
          <Center h="100%">
            <Image
              src={imgLogo}
              alt="Astagatra Logo"
              w="60%"
              onClick={() => navigate('/')}
              _hover={{ cursor: 'pointer' }}
            />
          </Center>
        </GridItem>
        <GridItem w="100%" h="124px" colSpan={5}>
          <Grid templateColumns="repeat(5, 1fr)" gap={2}>
            {MENU.map((m, i) => (
              <GridItem h="124px" key={i}>
                <Center h="100%">
                  <NavbarMenu menu={m} />
                </Center>
              </GridItem>
            ))}
          </Grid>
        </GridItem>
        <GridItem w="100%" h="124px">
          <Center h="100%">
            <CustomButton
              text={loginInfo.isLogin ? username : 'MASUK'}
              variant="red"
              icon={loginInfo.isLogin ? 'user' : 'none'}
              w="152px"
              onClick={handleLoginButtonClicked}
            />
          </Center>
        </GridItem>
        <GridItem w="100%" h="124px">
          <Center h="100%">
            <SearchButton />
          </Center>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Navbar;
