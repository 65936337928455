import React from "react";

import { Link, Text, VStack } from "@chakra-ui/react";

import { BLACK, RED } from "@constants/colors";

type Props = {
  headers: {
    name: string;
    linkTo: string;
  }[];
};

const Category = ({ headers }: Props) => {
  return (
    <VStack align="start">
      <Text fontSize="lg" as="b" color={RED}>
        Kategori
      </Text>
      {headers.map((h, i) => (
        <Link key={i} href={h.linkTo} fontSize="md" color={BLACK}>
          {h.name}
        </Link>
      ))}
    </VStack>
  );
};

export default Category;
