import { Box, VStack, Image, Text, Center, useToast } from '@chakra-ui/react';

import { LIGHTGREY } from '@constants/colors';

import imgNotActive from '@assets/member/member-not-active.svg';

import CustomButton from '@components/Button';
import fetchRequest from '@utils/fetcher';

const MEMBER_ERROR_STATUS = {
  UserNotConfirmedException: {
    title: 'Keanggotaan Anda Belum Aktif',
    content:
      'Mohon konfirmasi email Anda terlebih dahulu untuk dapat mengakses konten eksklusif khusus anggota Astagatra Institute',
  },
  AccountNotActivated: {
    title: 'Keanggotaan Anda Belum Aktif',
    content:
      'Mohon menunggu akun Anda diaktivasi oleh admin terlebih dahulu untuk dapat mengakses konten eksklusif khusus anggota Astagatra Institute',
  },
  ExpiredMembership: {
    title: 'Keanggotaan Anda Sudah Expired',
    content:
      'Mohon perbarui langganan Anda terlebih dahulu untuk dapat mengakses konten eksklusif khusus anggota Astagatra Institute',
  },
};

const NotActiveCard = ({ status }: { status: string }) => {
  const toast = useToast();

  const handleClick = async () => {
    try {
      await fetchRequest({
        method: 'POST',
        path: 'member/extend-membership',
      }).then((response) => {
        if (!response.error) {
          toast({
            title: 'Berhasil',
            description:
              'Permintaan perpanjangan berlangganan Anda telah berhasil dikirim',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Error',
            description: response.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      });
    } catch (error: any) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box w="100%" h="520px" bg={LIGHTGREY} borderRadius="lg">
      <Center w="100%" h="100%">
        <VStack gap={4}>
          <Image src={imgNotActive} />
          <Text fontSize="lg" as="b">
            {
              MEMBER_ERROR_STATUS[status as keyof typeof MEMBER_ERROR_STATUS]
                .title
            }
          </Text>
          <Text fontSize="lg" textAlign="center">
            {
              MEMBER_ERROR_STATUS[status as keyof typeof MEMBER_ERROR_STATUS]
                .content
            }
          </Text>
          {status === 'ExpiredMembership' && (
            <CustomButton
              text="PERBARUI KEANGGOTAAN"
              variant="transparent"
              icon="none"
              w="300px"
              onClick={handleClick}
            />
          )}
        </VStack>
      </Center>
    </Box>
  );
};

export default NotActiveCard;
