export const SORTS = [
  {
    name: 'Terbaru',
    value: 'latest',
  },
  {
    name: 'Judul (A-Z)',
    value: 'az',
  },
  {
    name: 'Judul (Z-A)',
    value: 'za',
  },
  {
    name: 'Jumlah Penyuka Terbanyak',
    value: 'like',
  },
  {
    name: 'Jumlah Pembaca Terbanyak',
    value: 'view',
  },
  {
    name: 'Jumlah Unduhan Terbanyak',
    value: 'download',
  },
];
