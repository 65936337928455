import { useEffect,useRef, useState } from 'react';
import { useInterval } from 'react-use';
import FlipNumbers from 'react-flip-numbers';

import {
  Box,
  Grid,
  GridItem,
  Center,
  Image,
  Text,
  VStack,
  HStack,
  Flex,
} from '@chakra-ui/react';

import imgLogoMd from '@assets/logo-md.svg';
import imgFooterPattern from '@assets/footer-pattern.png';
import imgMobileFooterPattern from '@assets/mobile-footer-pattern.svg';
import imgLocation from '@assets/location.svg';
import imgPhone from '@assets/phone.svg';
import imgFax from '@assets/fax.svg';
import imgMail from '@assets/mail.svg';

import { BLACK, RED } from '@constants/colors';
import { SOCIAL_MEDIA_LOGO } from '@constants/logos';

import type { FooterDataType } from 'customTypes/footer';

import CustomButton from '@components/Button';

import fetchRequest from '@utils/fetcher';


type visitortype={
  count: number;
  
}


//   const result = `${value.count}`.split("");

//   return (
//     <div style={{ display: "flex" }}>
//       {result.map((val) => (
//         <div
//           style={{
//             fontSize: 42,
//             marginRight: 10,
//             background: "#8D0000",
//             color: "white",
//             width: 40,
//             height: 55,
//             fontWeight: "bold",
//             textAlign: "center"
//           }}
//         >
//           {val}
//         </div>
//       ))}
//     </div>
//   );
// };

const DIGIT_COUNT = 5

function Card(props:any) {
  let digits = `${props.number}`.split('');
  // Determine the number of digits
  const numDigits = digits.length;
  // If the number has less than DIGIT_COUNT digits, add leading zeros
  if (numDigits < DIGIT_COUNT) {
    digits = Array(DIGIT_COUNT - numDigits).fill(0).concat(digits);
  }

  return (
    <div style={{ display: "flex" }}>
      {digits.map((val, index) => (
        <div
          key={index}
          style={{
            fontSize: 25,
           //marginTop:20,
            marginRight: 10,
            background: "#8D0000",
            color: "white",
            width: 30,
            height: 35,
            fontWeight: "bold",
            textAlign: "center"
          }}
        >
          {val}
        </div>
      ))}
    </div>
  );
}





const Footer = () => {
  const [data, setData] = useState<FooterDataType>();
  const [visitor, setVisitor] = useState({
    count: 0,
    click: 0
  });



  const getContactData = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'contact' }).then(
        (response) => {
          setData(response);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  


  useEffect(() => {
    getContactData();
    //getvisitor();
    fetch('https://count.cab/hit/ghG6Oirn0b')
      .then(response => response.json())
      .then(v =>  setVisitor(v));
  }, []);

  const handleOpenCatalog = () => {
    if (data?.companyCatalog) {
      window.open(data?.companyCatalog);
    }
  };

 

  const handleGoTo = (platformName: string, accountName: string) => {
    switch (platformName) {
      case 'Facebook':
        window.open('https://www.facebook.com/astagatra');
        break;
      case 'Youtube':
        window.open('https://www.youtube.com/channel/UCgKLxv4A1vs_i1Wcb5baL1g');
        break;
      case 'Instagram':
        window.open('https://www.instagram.com/astagatra_institute/');
        break;
      case 'Twitter':
        window.open('https://www.twitter.com/astagatra');
        break;
      case 'Whatsapp':
        let waNumber = accountName.replace(/[^\w\s]/gi, '');
        window.open(`https://api.whatsapp.com/send?phone=${waNumber}&text=`);
        break;
       
      default:
        break;
    }
  };

  return (
    <Box
      w="100%"
      color={BLACK}
      bg="white"
      backgroundImage={{
        base: `url(${imgMobileFooterPattern})`,
        md: `url(${imgFooterPattern})`,
      }}
      backgroundRepeat="no-repeat"
      backgroundSize="100%"
      backgroundPosition="bottom"
    >
      <Grid templateColumns="repeat(1, 1fr)">
        <GridItem w="100%" marginBottom="3">
          <Grid
            templateColumns="repeat(8, 1fr)"
            gap={{ base: 1, lg: 12 }}
            paddingX={{ md: '10px', base: '0' }}
          >
            <GridItem
              w="100%"
              h={{ base: '80px', lg: '300px' }}
              colSpan={{ base: 8, lg: 4 }}
              
            >
              <VStack
                align={{ base: 'center', lg: 'stretch' }}
                marginTop={{ base: '0', lg: '60px' }}
                marginBottom={{ base: '0', lg: '40px',md:'50px',sm:'60px' }}
                marginLeft={{ base: '0', lg: '50px' }}
              >
                <Box w={{ base: '100px', lg: '220px' }} paddingBottom={'10px'}>
                  <Image src={imgLogoMd} alt="Astagatra Logo" w="100%" />
                </Box>
                <CustomButton
                  text="Lihat Profil Perusahaan"
                  icon="download"
                  variant="transparent"
                  w="220px"
                  wMobile="171px"
                  onClick={() => handleOpenCatalog()}
                />
               
                
              
              
              </VStack>
           
           {/* <GridItem  w="100%"
              h={{ base: '80px', lg: '200px' }}> */}

           <HStack
                align="start"
               // marginTop={{ base: '0px', lg: '40px' }}
                marginBottom={{ base: '0px', lg: '0px',md:'0px',sm:'50px' }}
                marginLeft={{ base: '0px', lg: '50px' }}
                className={'fontSemiBold'}
              >
                <Text
                fontSize={{ base: '16px', lg: '20px' }}
              >
                Total Pengunjung:
              </Text>
                 <Card number={visitor.count}/>
              </HStack>
           {/* </GridItem> */}
           
            </GridItem>

          
            <GridItem
              w={{ base: '100%', lg: '300px' }}
              h={{ base: '200px', lg: '300px' }}
              colSpan={{ base: 4, lg: 2 }}
              padding={{ base: '10px', lg: '0px' }}
            >
              <VStack
                align="start"
                marginTop={{ base: '0px', lg: '50px' , sm:'50px'}}
                className={'fontSemiBold'}
              >
                <Text fontSize={{ base: 'md', lg: '2xl' }}>Lokasi Kantor</Text>
                <HStack>
                  <Image src={imgLocation} alt="Location" marginBottom="auto" />
                  <Text
                    fontSize={{ base: 'xs', lg: 'md' }}
                    textAlign={'justify'}
                  >
                    {data?.address}
                  </Text>
                </HStack>
              </VStack>
            </GridItem>
            <GridItem
              w="100%"
              colSpan={{ base: 4, lg: 2 }}
              padding={{ base: '10px', lg: '0px' }}
            >
              <VStack
                align="start"
                marginTop={{ base: '0px', lg: '50px', sm:'50px' }}
                className={'fontSemiBold'}
              >
                <Text fontSize={{ base: 'md', lg: '2xl' }}>Kontak Kami</Text>
                <HStack>
                  <Image src={imgPhone} alt="Phone" />
                  <Text fontSize={{ base: 'xs', lg: 'md' }}>{data?.phone}</Text>
                </HStack>
                <HStack
                 onClick={() =>
                  window.open('mailto:kontak@astagatra.com')
                }
                _hover={{ cursor: 'pointer' }}
                >
                  <Image src={imgMail} alt="Email" />
                  <Text fontSize={{ base: 'xs', lg: 'md' }}>{data?.email}</Text>
                  
                </HStack>
                {data?.socialMedia.map((sm, i) => {
                  const logoName = sm.platformName;
                  return (
                    <HStack
                      key={i}
                      onClick={() =>
                        handleGoTo(sm.platformName, sm.accountName)
                      }
                      _hover={{ cursor: 'pointer' }}
                    >
                      <Image
                        src={SOCIAL_MEDIA_LOGO[logoName]}
                        alt={sm.platformName}
                      />
                      <Text fontSize={{ base: 'xs', lg: 'md' }}>
                        {sm.accountName}
                      </Text>
                    </HStack>
                  );
                })}
              </VStack>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem w="100%" h={{ base: '20px', lg: '47px' }}>
          <Box bg={RED} h="100%">
            <Center w="100%" h="100%">
              <Text
                fontSize={{ base: 'xx-small', lg: 'sm' }}
                as="b"
                color="white"
              >
                © 2021 Astagatra Institute
              </Text>
            </Center>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Footer;
