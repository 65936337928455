import { useNavigate } from 'react-router';

import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from '@chakra-ui/react';

import { RED } from '@constants/colors';

import CustomButton from '@components/Button';

import illusContactUs from '@assets/service/contact-us-illustration.svg';
import { KONTAK } from '@constants/urls';

const ContactUs = () => {
  const navigate = useNavigate();

  const handleClickButton = () => {
    navigate(KONTAK);
  };

  return (
    <Box
      w={{ base: '100%' }}
      borderRadius={'lg'}
      borderColor={RED}
      borderWidth={'1px'}
    >
      <Grid
        templateColumns={'repeat(2, 1fr)'}
        gap={{ xl: 1, base: 3 }}
        paddingX={{ md: '40px' }}
        paddingY={{ md: '30px', base: '10px' }}
      >
        <GridItem colSpan={{ base: 3, md: 1 }}>
          <Center>
            <Image
              src={illusContactUs}
            />
          </Center>
        </GridItem>
        <GridItem
          colSpan={{ base: 3, md: 1 }}
          textAlign={{ md: 'left', base: 'center' }}
        >
          <Text className={'fontBold'}>Tertarik Dengan Layanan Kami?</Text>
          <Text className={'fontRegular'}>
            Hubungi kami pada jalur yang tersedia
          </Text>
          <br />
          <Flex justify={{ base: 'center', md: 'start' }}>
            <CustomButton
              text="Kontak Kami"
              variant="red"
              icon="none"
              w="152px"
              onClick={() => handleClickButton()}
            />
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default ContactUs;
