import { useEffect, useState } from 'react';

import {
  Box,
  Stack,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';

import ReactPaginate from 'react-paginate';

import Card from '@routes/Service/components/Card';

import CustomButton from '@components/Button';
import Category from '@components/Category';
import CustomBreadcrumb from '@components/CustomBreadcrumb';
import Sort from '@routes/Service/components/Sort';

import type { InfoProps } from 'customTypes/info';
import type { QuickLinkProps } from 'customTypes/quickLink';

import fetchRequest from '@utils/fetcher';

import { BREADCRUMB_PUBLIKASI } from '@constants/breakcrumbs';
import { BLACK } from '@constants/colors';
import SkeletonCard from '@routes/Service/components/SkeletonCard';

import '@routes/Service/styles/index.css';

const Publication = () => {
  const [publicationList, setPublicationList] = useState<InfoProps[]>([]);
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const [total, setTotal] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(1);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const getPublicationData = async ({
    sort = 'latest',
    category = '',
    pg,
  }: {
    sort?: string;
    category?: string;
    pg?: number;
  }) => {
    let param =
      '?type=publication&sort=' +
      sort +
      '&page=' +
      (typeof pg === 'undefined' ? page : pg);
    if (category !== '') param += '&categories=' + category;

    try {
      await fetchRequest({ method: 'GET', path: 'info' + param }).then(
        (response) => {
          setPublicationList(response.dataList || []);
          setQuickLinkList(response.quickLinks || []);
          setTotal(response.count || 0);
          setCategoryList(response.categories || []);
          setTotalPage(response.lastPage || 1);

          setIsLoading(false);
        }
      );
    } catch (error) {
      console.log(error);

      setIsLoading(false);
    }
  };

  const handleSelectedCategoryClick = (c: string) => {
    setSelectedCategory(c);
    getPublicationData({
      sort: 'latest',
      category: c,
    });
  };

  const handlePageClick = (event: any) => {
    const selectedPage = event.selected + 1
    setPage(selectedPage);
    getPublicationData({ pg: selectedPage });
  };

  useEffect(() => {
    getPublicationData({});
  }, []);

  return (
    <Box>
      <Box h="30px" paddingLeft={{ base: '10px', lg: '3%' }} w="100%">
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_PUBLIKASI} />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop={{ base: '0px', lg: '20px' }}
      >
        <Box
          w={{ md: '20%' }}
          h="400px"
          display={{ md: 'inline', base: 'none' }}
        >
          <Category headers={quickLinkList} />
          <VStack align="start" paddingTop="8px">
            {categoryList.map((c, i) => (
              <Text
                fontSize="md"
                color={BLACK}
                key={i}
                onClick={() => handleSelectedCategoryClick(c)}
                _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {c}
              </Text>
            ))}
          </VStack>
        </Box>
        <Box w="100%">
          <Text
            className={'fontBold'}
            fontSize={{ md: '40px', base: '24px' }}
            color={BLACK}
            textAlign={{ base: 'center', lg: 'start' }}
          >
            Publikasi
          </Text>
          <SkeletonCard isLoaded={!isLoading} numberOfCard={2}>
            <Box w="100%" h="40px" textAlign="end">
              <CustomButton
                text="URUTKAN"
                variant="transparent"
                icon="sort"
                w="135px"
                onClick={() => onOpen()}
              />
              <Sort
                isOpen={isOpen}
                onClose={onClose}
                handleSort={(sort) => getPublicationData({ sort: sort })}
              />
            </Box>
            <Text paddingBottom="30px">
              Menampilkan {total} publikasi
              {selectedCategory !== '' && ` "${selectedCategory}"`}
            </Text>
            <Wrap justify="space-between" spacing="2%">
              {publicationList.map((article, i) => (
                <WrapItem w={{ base: '100%' }} key={i}>
                  <Card {...article} />
                </WrapItem>
              ))}
            </Wrap>
            <Box marginTop={{ md: '50px', base: '30px' }}>
              <ReactPaginate
                breakLabel="..."
                nextLabel="selanjutnya"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="sebelumnya"
                className="styPagination"
              />
            </Box>
            <Box
              w={{ md: '20%' }}
              h="100%"
              display={{ md: 'none', base: 'block' }}
              paddingTop="50px"
              paddingBottom="100px"
            >
              <Category headers={quickLinkList} />
              <VStack align="start" paddingTop="8px">
                {categoryList.map((c, i) => (
                  <Text
                    fontSize="md"
                    color={BLACK}
                    key={i}
                    cursor="pointer"
                    onClick={() => handleSelectedCategoryClick(c)}
                  >
                    {c}
                  </Text>
                ))}
              </VStack>
            </Box>
          </SkeletonCard>
        </Box>
      </Stack>
    </Box>
  );
};

export default Publication;
