import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import parse, {
  DOMNode,
  domToReact,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';

import {
  Grid,
  GridItem,
  Box,
  Stack,
  Text,
  useDisclosure,
  VStack,
  HStack,
  Image,
  Divider,
  useToast,
} from '@chakra-ui/react';

import { ICONS } from '@routes/Service/constants/icons';

import CustomBreadcrumb from '@components/CustomBreadcrumb';
import Category from '@components/Category';
import CustomButton from '@components/Button';

import type { InfoDetailProps } from '@customTypes/info';
import type { QuickLinkProps } from '@customTypes/quickLink';

import { useGlobalState } from '@contexts/globalState';

import fetchRequest from '@utils/fetcher';

import { BREADCRUMB_BERITA } from '@constants/breakcrumbs';
import { BLACK, GREY } from '@constants/colors';
import CommentSection from './CommentSection';

const options: HTMLReactParserOptions = {
  replace: (domNode: DOMNode) => {
    if ((domNode as Element).name == 'ol') {
      return (
        <ol style={{ paddingLeft: '20px' }}>
          {domToReact((domNode as Element).children)}
        </ol>
      );
    } else if ((domNode as Element).name == 'ul') {
      return (
        <ul style={{ paddingLeft: '20px' }}>
          {domToReact((domNode as Element).children)}
        </ul>
      );
    }
  },
};

const NewsDetail = () => {
  const location = useLocation();
  const queryKey = location.pathname.split('/');

  const [categoryList, setCategoryList] = useState([]);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const [newsData, setNewsData] = useState<InfoDetailProps>();
  const [isUserLike, setIsUserLike] = useState(false);

  const toast = useToast();

  const { loginInfo } = useGlobalState();

  const getNewsDetail = async () => {
    const param = '/' + queryKey[3];

    try {
      await fetchRequest({ method: 'GET', path: 'info' + param }).then(
        (response) => {
          setCategoryList(response.categories || []);
          setQuickLinkList(response.quickLinks || []);
          const responseData = response.data || {};
          setNewsData(responseData);
          setIsUserLike(responseData?.isUserLike || false);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNewsDetail();
  }, []);

  const handleSelectedCategoryClick = () => {};

  const handleButtonClick = async () => {
    let param = '/' + queryKey[3];
    try {
      await fetchRequest({ method: 'POST', path: 'info/like' + param }).then(
        (response) => {
          console.log(response);
          //change button variant to be red

          setIsUserLike(true);
        }
      );
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Box h="30px" paddingLeft={{ base: '10px', lg: '3%' }} w="100%">
        <CustomBreadcrumb
          breadcrumbs={[
            ...BREADCRUMB_BERITA,
            { name: newsData?.title || '', url: '' },
          ]}
        />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop={{ base: '0px', lg: '20px' }}
      >
        <Box
          w={{ md: '20%' }}
          h="400px"
          display={{ md: 'inline', base: 'none' }}
        >
          <Category headers={[]} />
          <VStack align="start" paddingTop="8px">
            {categoryList.map((c, i) => (
              <Text
                fontSize="md"
                color={BLACK}
                key={i}
                cursor="pointer"
                onClick={() => handleSelectedCategoryClick()}
              >
                {c}
              </Text>
            ))}
          </VStack>
        </Box>
        <Box w={{ md: '60%' }}>
          <Image
            w="100%"
            h="50vh"
            objectFit="cover"
            src={newsData?.headerUrl || ''}
          ></Image>
          <Text
            className={'fontBold'}
            fontSize={{ md: '40px', base: '24px' }}
            color={BLACK}
            textAlign={{ base: 'center', lg: 'start' }}
            marginBottom="10"
            marginTop="4"
          >
            {newsData?.title || ''}
          </Text>
          <Box textAlign="justify" fontSize={{ lg: 'md', base: 'sm' }}>
            {parse(newsData?.content || '', options)}
          </Box>
          <Divider paddingTop="20px" />
          <Box display={{ md: 'block', base: 'none' }}>
            <CommentSection queryKey={queryKey[3]} />
          </Box>
        </Box>
        <Box w={{ md: '20%' }} fontSize={{ lg: 'md', base: 'sm' }}>
          <VStack align="start">
            <HStack>
              <Image src={ICONS.author} />
              <Text color={GREY}>{newsData?.author}</Text>
            </HStack>
            <HStack>
              <Image src={ICONS.date} />
              <Text color={GREY}>
                {dayjs(newsData?.createdAt).format('DD/MM/YYYY')}
              </Text>
            </HStack>
            <HStack>
              <Image src={ICONS.category} />
              <Text color={GREY}>{newsData?.categories?.join(', ')}</Text>
            </HStack>
            {newsData?.subCategories &&
              newsData?.subCategories?.length > 0 &&
              newsData?.subCategories[0] !== '' && (
                <HStack>
                  <Image src={ICONS.subCategory} />
                  <Text color={GREY}>
                    {newsData?.subCategories?.join(', ')}
                  </Text>
                </HStack>
              )}
            <HStack>
              <Image src={ICONS.view} />
              <Text color={GREY}>{newsData?.views} pembaca</Text>
            </HStack>
            {/* { <HStack>
              <Image src={ICONS.like} />
              <Text color={GREY}>{newsData?.likes} penyuka</Text>
            </HStack> } */}

            <HStack>
              <Image src={ICONS.comment} />
              <Text color={GREY}>{newsData?.comments} komentar</Text>
            </HStack>
            {/* {loginInfo.isLogin && (
              <CustomButton
                w="100%"
                text={isUserLike ? 'DISUKAI' : 'SUKAI'}
                icon="like"
                variant={isUserLike ? 'red' : 'white'}
                onClick={handleButtonClick}
              />
            )} */}
          </VStack>
          <Box
            display={{ md: 'none', base: 'block' }}
            marginTop="20px"
            marginBottom="20px"
          >
            <Divider />
            <CommentSection queryKey={queryKey[3]} />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default NewsDetail;
