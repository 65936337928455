import React, { useEffect, useState } from 'react';
import {
  Center,
  Box,
  Image,
  Text,
  Grid,
  GridItem,
  Stack,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Divider,
  Flex,
} from '@chakra-ui/react';
import {
  HOMEPAGE_TITLE,
  HOMEPAGE_DESC,
  PRODUCT_TITLE,
  TESTIMONIAL_TITLE,
} from '@constants/texts';
import { RED, BABYGREY, GOLD, WHITE } from '@constants/colors';
import CustomButton from '@components/Button';
import Hero from './Hero';
import Testimonial from './Testimonial';
import {
  ALF,
  KAJIAN_DAN_KONSULTASI,
  PELATIHAN,
  
} from '@constants/urls';
import { HomepageProps } from '@customTypes/homepage';
import fetchRequest from '@utils/fetcher';
import { useNavigate } from 'react-router-dom';

const Homepage = () => {
  const [banner, setBanner] = useState<string>('');
  const [productData, setProductData] = useState<HomepageProps[]>([]);

  const navigate = useNavigate();

  const product_url = [KAJIAN_DAN_KONSULTASI,PELATIHAN];

  const getHomepageData = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'homepage' }).then(
        (response) => {
          setBanner(response.banner);
          setProductData(response.ourStrength);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHomepageData();
  }, []);

  return (
    <Box>
      <Box h={{ lg:'60vh', md: '40vh', base: '25vh' }}>
        <Image
          src={banner}
          w={{ base: '100%'}}
          objectFit={'contain'}
        />
      </Box>
        <Box w={'100%'} position={'absolute'}>
          <Hero />
        </Box>
        {/* karna carouselnya ngambang */}
        <Box h={{ lg: '550px', md: '400px', sm: '250px', base: '250px' }}></Box>
        <Box margin={4}>
          <Center>
            <Stack margin={'20px'}>
              <Text
                className="fontBold"
                fontSize={{ md: '40px', base: '24px' }}
                color={RED}
                align={'center'}
              >
                {PRODUCT_TITLE}
              </Text>
              <br />
              <Tabs w={{ lg: '1000px', md: '800px' }} zIndex="0" isFitted variant='enclosed'>
                <TabList>
                  {productData.map((m, i) => (
                    <Tab key={i}
                      className="fontRegular"
                      w={{ lg: '450px', md: '300px', base: '180%' }}
                      fontSize={{ md: '18px', base: '10px' }}
                      borderBottomColor={'white'}
                      color={BABYGREY}
                      _selected={{
                        color: RED,
                        borderBottom: '4px solid',
                        borderBottomColor: GOLD,
                        fontWeight: '700',
                      }}
                    >
                      {m.title}
                    </Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {productData.map((m, i) => (
                    <TabPanel key={i}>
                      <Grid
                        w={{ lg: '1000px', md: '800px', base: '100%' }}
                        h={{ lg: '350px', md: '350px', base: '400px' }}
                        gap={{ md: 5, base: 4 }}
                        bg={'white'}
                        templateColumns="repeat(6, 1fr)"
                        paddingTop={{ lg: '40px', md: '28px', base: '14px' }}
                      >
                        <GridItem
                          rowSpan={{ md: 6, base: 2 }}
                          colSpan={{ lg: 3, md: 2, base: 6 }}
                        >
                          <Center>
                            <Image
                              src={m.icon}
                              h={{ lg: '250px', md: '200px', base: '150px' }}
                              maxW={{ lg: '450px', base: '250px' }}
                            ></Image>
                          </Center>
                        </GridItem>
                        <GridItem
                          rowSpan={{ md: 1, base: 2 }}
                          colSpan={{ lg: 3, md: 4, base: 6 }}
                        >
                          <Text
                            className="fontBold"
                            fontSize={{ lg: '36px', md: '28px', base: '20px' }}
                            textAlign={{ md: 'left', base: 'center' }}
                          >
                            {m.title}
                          </Text>
                          <Divider
                            h={'10px'}
                            borderColor={'transparent'}
                          ></Divider>
                          <Text
                            className="fontRegular"
                            fontSize={{ lg: '18px', md: '16px', base: '12px' }}
                            textAlign={{ base: 'justify' }}
                          >
                            {m.content}
                          </Text>
                          <Divider
                            h={'15px'}
                            borderColor={'transparent'}
                          ></Divider>
                          <Flex justify={{ md: 'left', base: 'center' }}>
                            <CustomButton
                              text="SELENGKAPNYA"
                              variant="white"
                              icon="none"
                              w="245px"
                              onClick={() => navigate(product_url[i])}
                            />
                          </Flex>
                        </GridItem>
                      </Grid>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Stack>
          </Center>
        </Box>
        <Box>
          <Center>
            <Text
              className="fontBold"
              fontSize={{ md: '40px', base: '24px' }}
              color={RED}
              align={'center'}
            >
              {TESTIMONIAL_TITLE}
            </Text>
          </Center>
          <br />
          <Box w={'100%'} position={'absolute'}>
            <Testimonial />
          </Box>
          <Box
            h={{ lg: '550px', md: '400px', sm: '250px', base: '250px' }}
          ></Box>
        </Box>
        <br />
    </Box>
  );
};

export default Homepage;
