import React, { useEffect, useState } from 'react';
import {
  Box,
  Center,
  Stack,
  Text,
  Image,
  Wrap,
  WrapItem,
  Container,
} from '@chakra-ui/react';
import {
  LEARNING_EXECUTIVE_TITLE,
  LEARNING_METHOD_TITLE,
  LEARNING_SERVICE_TITLE,
  LEARNING_TITLE,
} from '@constants/texts';
import { BLACK, LIGHTGREY, RED } from '@constants/colors';
import ContactUs from '@routes/Product/components/ContactUs';
import Category from '@components/Category';
import DownloadCatalog from '@routes/Product/components/DownloadCatalog';
import fetchRequest from '@utils/fetcher';
import CustomBreadcrumb from '@components/CustomBreadcrumb';
import { QuickLinkProps } from '@customTypes/quickLink';
import { BREADCRUMB_PELATIHAN } from '@constants/breakcrumbs';
import { ItemProps, PictureProps } from '@customTypes/service';

const Learning = () => {
  const [catalog, setCatalog] = useState('');
  const [mainPicture, setMainPicture] = useState('');
  const [description, setDescription] = useState('');
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const [items, setItems] = useState<ItemProps[]>([]);
  const [methods, setMethods] = useState<PictureProps[]>([]);

  const getResearchData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'service/training',
      }).then((response) => {
        setCatalog(response.catalog || []);
        setQuickLinkList(response.quickLinks || []);
        setItems(response.item || []);
        setMainPicture(response.mainPicture || []);
        setDescription(response.mainDesc || []);
        setMethods(response.method || []);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getResearchData();
  }, []);

  return (
    <Box w={'100%'}>
      <Box
        h="30px"
        paddingLeft={{ base: '10px', lg: '3%' }}
        paddingRight={{ base: '10px' }}
        w="100%"
      >
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_PELATIHAN} />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop="20px"
      >
        <Box
          w={{ md: '20%' }}
          h={'400px'}
          top={{ md: '150', base: '0' }}
          position={'sticky'}
          display={{ md: 'inline', base: 'none' }}
        >
          <Category headers={[...quickLinkList]} />
        </Box>
        <Box w={{ lg: '60%', md: '75%', sm: '90%' }}>
          <Center>
            <Text
              className={'fontSemiBold'}
              fontSize={{ xl: '40px', lg: '32px', md: '24px' }}
              color={BLACK}
              align={'center'}
            >
              {LEARNING_TITLE}
            </Text>
          </Center>
          <Box paddingTop="30px" paddingBottom="30px">
            <DownloadCatalog pageTitle={LEARNING_TITLE} catalogUrl={catalog} />
          </Box>
          <Box>
            <Text
              className={'fontSemiBold'}
              fontSize={{ md: '24px', base: '16px' }}
              paddingTop="30px"
              paddingBottom="30px"
              color={BLACK}
            >
              {LEARNING_EXECUTIVE_TITLE}
            </Text>
            <Text
              className={'fontRegular'}
              fontSize={{ md: '18px', base: '14px' }}
              color={BLACK}
              textAlign="justify"
            >
              {description}
            </Text>
          </Box>
          {/* <br />
          <Box>
            <Image src={mainPicture} width={'100%'} />
          </Box> */}
          {/* <br /> */}
          <Box>
            <Text
              className={'fontSemiBold'}
              fontSize={{ md: '24px', base: '16px' }}
              paddingTop="30px"
              paddingBottom="30px"
              color={BLACK}
            >
              {LEARNING_SERVICE_TITLE}
            </Text>
            <Wrap spacing={{ base: '10px' }} justify={{ base: 'center' }}>
              {items.map((item, i) => (
                <WrapItem key={i}>
                  <Center
                    w={{ xl: '800px', md: '600px', base: '100%' }}
                    padding={'30px'}
                    bg={LIGHTGREY}
                    borderRadius={'lg'}
                    textAlign={'center'}
                    flexDirection={'column'}
                  >
                    <Text
                      className={'fontBold'}
                      color={RED}
                      fontSize={{ xl: '20px', md: '18px', base: '16px' }}
                    >
                      {item.title}
                    </Text>
                    <Box h={'2'}></Box>
                    <Text
                      className={'fontRegular'}
                      fontSize={{ xl: '18px', md: '16px', base: '14px' }}
                    >
                      {item.content}
                    </Text>
                  </Center>
                </WrapItem>
              ))}
            </Wrap>
          </Box>
          <br />
          <Box>
            <Text
              className={'fontSemiBold'}
              fontSize={{ md: '24px', base: '16px' }}
              paddingTop="30px"
              paddingBottom="30px"
            >
              {LEARNING_METHOD_TITLE}
            </Text>
            <Wrap spacing={'10px'} justify={{ base: 'center' }}>
              {methods.map((m, i) => (
                <WrapItem key={i} w={{ xl: '48%' }}>
                  <Container>
                    <Center>
                      <Image src={m.url} boxSize={'240px'} />
                    </Center>
                    <Text
                      className={'fontBold'}
                      fontSize={{ md: '20px', base: '14px' }}
                      color={BLACK}
                      align={{ base: 'center' }}
                    >
                      {m.title}
                    </Text>
                    <br />
                  </Container>
                </WrapItem>
              ))}
            </Wrap>
          </Box>
          <br />
          <Center>
            <ContactUs />
          </Center>
          <Box
            w={{ md: '20%' }}
            h="100%"
            display={{ md: 'none', base: 'block' }}
            paddingTop="50px"
            paddingBottom="100px"
          >
            <Category headers={quickLinkList} />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Learning;
