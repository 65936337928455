import { Route, Routes } from 'react-router-dom';

import { Grid, GridItem } from '@chakra-ui/react';

import Navbar from '@components/Navbar';
import MobileNavbar from '@components/MobileNavbar';
import Footer from '@components/Footer';

import Homepage from './Homepage';
import Research from './Product/routes/Research';
import Article from './Service/routes/Article';
import News from './Service/routes/News';
import Publication from './Service/routes/Publication';
import Webinar from './Product/routes/Webinar';
import Learning from './Product/routes/Learning';
import AboutUs from './AboutUs';
import VisiMisi from './AboutUs/routes/VisiMisi';
import Membership from './AboutUs/routes/Membership';
import Forum from './AboutUs/routes/Forum';
import Contact from './Contact';
import Dashboard from './Member/routes/Dashboard';
import Manajemen from './AboutUs/routes/Manajemen';
import Event from './Service/routes/Event';
import NewsDetail from './Service/routes/News/routes/NewsDetail';
import ArticleDetail from './Service/routes/Article/routes/ArticleDetail';
import PublicationDetail from './Service/routes/Publication/routes/PublicationDetail';
import Gallery from './Service/routes/Gallery';
import GalleryDetail from './Service/routes/Gallery/routes/GalleryDetail';
import Certificate from './Member/routes/Certificate';
import Setting from './Member/routes/Setting';
import ChangePassword from './Member/routes/ChangePassword';

import {
  ARTIKEL,
  BERANDA,
  BERITA,
  KAJIAN_DAN_KONSULTASI,
  PUBLIKASI,
  WEBINAR,
  PELATIHAN,
  TENTANG_KAMI,
  VISI_MISI,
  MEMBERSHIP,
  ALF,
  KONTAK,
  MEMBER_DASHBOARD,
  MANAJEMEN,
  ACARA,
  GALERI,
  SERTIFIKAT,
  MEMBER_SETTING,
  MEMBER_SETTING_RESET_PASSWORD,
} from '@constants/urls';

const GeneralRoutes = () => {
  return (
    <>
      <Grid templateColumns="repeat(1, 1fr)">
        <GridItem w="100%" h={{ base: '48px', lg: '124px' }}>
          <Navbar />
          <MobileNavbar />
        </GridItem>
        <GridItem w="100%" h="100%">
          <Routes>
            <Route path={BERANDA} element={<Homepage />} />
            <Route path={KAJIAN_DAN_KONSULTASI} element={<Research />} />
            <Route path={ARTIKEL} element={<Article />} />
            <Route path={BERITA} element={<News />} />
            <Route path={PUBLIKASI} element={<Publication />} />
            <Route path={WEBINAR} element={<Webinar />} />
            <Route path={PELATIHAN} element={<Learning />} />
            <Route path={TENTANG_KAMI} element={<AboutUs />} />
            <Route path={VISI_MISI} element={<VisiMisi />} />
            <Route path={ALF} element={<Forum />} />
            <Route path={MEMBERSHIP} element={<Membership />} />
            <Route path={KONTAK} element={<Contact />} />
            <Route path={MEMBER_DASHBOARD} element={<Dashboard />} />
            <Route path={MANAJEMEN} element={<Manajemen />} />
            <Route path={ACARA} element={<Event />} />
            <Route path={BERITA + '/*'} element={<NewsDetail />} />
            <Route path={ARTIKEL + '/*'} element={<ArticleDetail />} />
            <Route path={PUBLIKASI + '/*'} element={<PublicationDetail />} />
            <Route path={GALERI} element={<Gallery />} />
            <Route path={GALERI + '/*'} element={<GalleryDetail />} />
            <Route path={SERTIFIKAT} element={<Certificate />} />
            <Route path={MEMBER_SETTING} element={<Setting />} />
            <Route
              path={MEMBER_SETTING_RESET_PASSWORD}
              element={<ChangePassword />}
            />
          </Routes>
        </GridItem>
        <GridItem w="100%" h={{ base: '330px', lg: '347px' }}>
          <Footer />
        </GridItem>
      </Grid>
    </>
  );
};

export default GeneralRoutes;
