import { useNavigate } from 'react-router-dom';

import {
  Box,
  Grid,
  GridItem,
  Center,
  Image,
  Button,
  useDisclosure,
} from '@chakra-ui/react';

import { BLACK } from '@constants/colors';
import { AUTH, LOGIN, MEMBER_DASHBOARD } from '@constants/urls';

import imgLogo from '@assets/logo.svg';
import imgNavbarPattern from '@assets/navbar-pattern.png';
import imgMenu from '@assets/menu.svg';

import { useGlobalState } from '@contexts/globalState';

import MobileMenu from './Menu';

import CustomButton from '@components/Button';
import SearchButton from './SearchButton';

const MobileNavbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { loginInfo } = useGlobalState();

  const username = loginInfo.username.slice(0, 6) + '...';

  const handleLoginButtonClicked = () => {
    if (loginInfo.isLogin) {
      navigate(MEMBER_DASHBOARD);
    } else {
      navigate(AUTH + LOGIN);
    }
  };

  return (
    <Box
      w="100%"
      color={BLACK}
      position="fixed"
      top="0px"
      bg="white"
      backgroundImage={`url(${imgNavbarPattern})`}
      backgroundRepeat="no-repeat"
      backgroundSize="100%"
      display={{ base: 'block', lg: 'none' }}
      zIndex={1}
      boxShadow="md"
    >
      <Grid templateColumns="repeat(7, 1fr)" gap={1}>
        <GridItem w="100%" h="48px">
          <Center h="100%">
            <Button
              variant="ghost"
              _hover={{ bg: 'transparent' }}
              _focus={{ boxShadow: 'none' }}
              onClick={onOpen}
            >
              <Image src={imgMenu} alt="Menu" />
            </Button>
            <MobileMenu isOpen={isOpen} onClose={onClose} />
          </Center>
        </GridItem>
        <GridItem w="100%" h="48px">
          <Center h="100%">
            <SearchButton />
          </Center>
        </GridItem>
        <GridItem w="100%" h="48px" colSpan={3}>
          <Center h="100%">
            <Image src={imgLogo} alt="Astagatra Logo" w="60%" h="36px" onClick={() => navigate('/')}/>
          </Center>
        </GridItem>
        <GridItem w="100%" h="48px" colSpan={2}>
          <Center h="100%">
            <CustomButton
              text={loginInfo.isLogin ? username : 'MASUK'}
              variant="red"
              icon={loginInfo.isLogin ? 'user' : 'none'}
              w="71px"
              onClick={handleLoginButtonClicked}
            />
          </Center>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default MobileNavbar;
