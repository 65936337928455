import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Center,
  Box,
  VStack,
  Image,
  Text,
  Input,
  useToast,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

import { EmailIcon } from '@chakra-ui/icons';

import imgBackground from '@assets/member/background.svg';
import imgLogo from '@assets/logo.svg';

import { RED, WHITE, BLACK } from '@constants/colors';
import { AUTH, BERANDA, FORGOT_PASSWORD, OTP } from '@constants/urls';

import CustomButton from '@components/Button';

import fetchRequest from '@utils/fetcher';

import { useGlobalState } from '@contexts/globalState';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isEmailInvalid, setIsEmailInvalid] = useState(true);

  const navigate = useNavigate();

  const toast = useToast();

  const { setForgotPassInfo } = useGlobalState();

  const handleEmail = (value: string) => {
    const isEmail = value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    setEmail(value);

    if (isEmail) {
      setIsEmailInvalid(false);
    } else {
      setIsEmailInvalid(true);
    }
  };

  const handleForgotPassword = async () => {
    try {
      await fetchRequest({
        method: 'POST',
        path: 'auth/forget-password?email=' + email,
      }).then((response) => {
        if (response.CodeDeliveryDetails) {
          setForgotPassInfo({ email });
          navigate(AUTH + FORGOT_PASSWORD);
        }
      });
    } catch (error: any) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      w="100vw"
      h="100vh"
      bg={RED}
      backgroundImage={`url(${imgBackground})`}
      backgroundRepeat="no-repeat"
      backgroundSize="100%"
      backgroundPosition="bottom"
    >
      <Center w="100%" h="100%">
        <Box
          w={{ base: '80vw', lg: '40vw', xl: '30vw' }}
          h={{ sm: '30vh', lg: '50vh' }}
          bg={WHITE}
          borderRadius="lg"
          padding="20px"
        >
          <Center w="100%" h="100%">
            <VStack align="start">
              <Image
                src={imgLogo}
                w={{ base: '40%', lg: '35%' }}
                onClick={() => navigate(BERANDA)}
              />
              <Text
                className="fontBold"
                fontSize={{ lg: '40px', base: '24px' }}
                color={BLACK}
              >
                Lupa Password
              </Text>
              <Text fontSize={{ lg: 'lg', base: 'sm' }}>
                Masukkan Email Anda yang sudah terdaftar untuk mengatur ulang
                password Akun Anda
              </Text>
              <Box w="100%" paddingTop="10px" paddingBottom="20px">
                <InputGroup paddingTop="5px">
                  <InputLeftElement
                    pointerEvents="none"
                    children={<EmailIcon color="#AAAAAA" />}
                    paddingTop="5px"
                  />
                  <Input
                    type="email"
                    placeholder="Masukkan Email"
                    _focus={{ boxShadow: 'none', borderColor: RED }}
                    fontSize={{ lg: 'md', base: 'sm' }}
                    value={email}
                    onChange={(e) => handleEmail(e.target.value)}
                    isInvalid={isEmailInvalid}
                    errorBorderColor={RED}
                  />
                </InputGroup>
              </Box>
              <CustomButton
                text="KONFIRMASI"
                w="100%"
                wMobile="100%"
                variant="red"
                icon="none"
                disabled={isEmailInvalid}
                onClick={handleForgotPassword}
              />
            </VStack>
          </Center>
        </Box>
      </Center>
    </Box>
  );
};

export default ForgotPassword;
