import React from 'react';

import { Box, Flex, Grid, GridItem, Text, Image } from '@chakra-ui/react';

import { LIGHTGREY, RED } from '@constants/colors';

import { ALFProps } from '@customTypes/alf';
import ModalCV from '@routes/AboutUs/components.tsx/Modal';
import ModalMobileCV from '@routes/AboutUs/components.tsx/ModalMobile';

const TreeComponent = (data: ALFProps) => {
  const { name, photo, position, content } = data;
  return (
    <Grid
      w={{ md: '340px', base: '240px' }}
      margin={'auto'}
      textAlign={'left'}
      templateColumns={{ md: 'repeat(3, 1fr)', base: 'repeat(6, 1fr)' }}
    >
      <GridItem colSpan={{ md: 1, base: 2 }} h={{ md: '135px', base: '100px' }}>
        <Image src={photo} fit={'cover'} boxSize={'100%'}></Image>
      </GridItem>
      <GridItem
        colSpan={{ md: 2, base: 4 }}
        paddingY={{ md: 'auto', base: '10px' }}
        paddingX={{ md: '15px', base: '10px' }}
        bg={LIGHTGREY}
      >
        <Text
          className={'fontSemiBold'}
          fontSize={{ lg: '14px', base: '10px' }}
          color={RED}
        >
          {position}
        </Text>
        <Text
          className={'fontSemiBold'}
          fontSize={{ lg: '18px', base: '13px' }}
        >
          {name}
        </Text>
        <Flex>
          <Box display={{ md: 'inline', base: 'none' }}>
            <ModalCV
              name={name}
              photo={photo}
              position={position}
              content={content}
            ></ModalCV>
          </Box>
          <Box display={{ md: 'none', base: 'inline' }}>
            <ModalMobileCV
              name={name}
              photo={photo}
              position={position}
              content={content}
            ></ModalMobileCV>
          </Box>
        </Flex>
      </GridItem>
    </Grid>
  );
};

export default TreeComponent;
