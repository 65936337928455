import { Box, Flex, HStack, Skeleton, SkeletonText, Stack, Wrap, WrapItem } from "@chakra-ui/react";
import { LIGHTGREY } from "@constants/colors";

import { ReactElement, ReactNode } from "react";

type Props = {
  isLoaded: boolean;
  numberOfCard: number;
  children: ReactNode;
};

type ChildProps = {
  numberOfCard: number;
  isLoaded: boolean;
};

const Child: Function = ({ isLoaded, numberOfCard }: ChildProps) => {
  let result: ReactElement[] = [];
  for (let i = 0; i < numberOfCard; i++) {
    result.push(
      <WrapItem w={{ base: "100%", lg: "48%" }} display={isLoaded ? "none" : "block"} key={i}>
        <Box bg={LIGHTGREY} borderRadius="lg" w="100%" _hover={{ shadow: "base" }} padding="4">
          <Flex direction={{ base: "column", md: "row", lg: "row" }}>
            <Box flex={2}>
              <Skeleton w="100%" h="100%" isLoaded={isLoaded} />
            </Box>
            <Box w="16px" h="16px" />
            <Box flex={3} h="100%">
              <Stack flexDir="column" h="100%">
                <HStack>
                  <Skeleton w="20" height="20px" isLoaded={isLoaded} />
                </HStack>
                <Box flexGrow={2} paddingY="4">
                  <SkeletonText noOfLines={2} skeletonHeight="12px" marginBottom="4" isLoaded={isLoaded} />
                  <SkeletonText noOfLines={4} isLoaded={isLoaded} />
                </Box>
                <HStack alignSelf="end" justifyContent="space-between" w="100%">
                  <Skeleton w="20" height="20px" isLoaded={isLoaded} />
                  <Skeleton w="20" height="20px" isLoaded={isLoaded} />
                </HStack>
              </Stack>
            </Box>
          </Flex>
        </Box>
      </WrapItem>
    );
  }
  return result;
};

const SkeletonCard = ({ isLoaded, numberOfCard = 2, children }: Props) => {
  return (
    <Box>
      <Box display={isLoaded ? "block" : "none"}>{children}</Box>
      <Wrap justify="space-between" spacing="16px" marginTop="6">
        <Child isLoaded={isLoaded} numberOfCard={numberOfCard} />
      </Wrap>
    </Box>
  );
};

export default SkeletonCard;
