import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import CryptoJS from 'crypto-js';

import {
  Center,
  Box,
  VStack,
  Image,
  Text,
  Grid,
  GridItem,
  Input,
  useToast,
} from '@chakra-ui/react';

import imgBackground from '@assets/member/background.svg';
import imgLogo from '@assets/logo.svg';

import { RED, WHITE, BLACK, LIGHTGREY, GREY, BABYGREY } from '@constants/colors';
import { AUTH, BERANDA, LOGIN, MEMBER_DASHBOARD } from '@constants/urls';

import { useGlobalState } from '@contexts/globalState';
import CustomButton from '@components/Button';

import fetchRequest from '@utils/fetcher';

const EmailConfirmation = () => {
  const { signUpInfo, setLoginInfo } = useGlobalState();
  const [otp, setOtp] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const navigate = useNavigate();
  const cookies = new Cookies();

  const toast = useToast();

  useEffect(() => {
    if (!signUpInfo.email) {
      navigate(AUTH + LOGIN);
    }
  }, [signUpInfo]);

  useEffect(() => {
    //check if OTP is fully filled
    if (
      Object.keys(otp).length === 6 &&
      Object.values(otp).filter((val) => val === '').length === 0
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [otp]);

  const handleOTPfield = ({ id, value }: { id: number; value: string }) => {
    const re = /^[0-9\b]+$/;

    //check if number
    if (value === '' || re.test(value)) {
      //handle value > 9
      if (value.length < 2) {
        setOtp((prevValue) => ({ ...prevValue, [id]: value }));
      }
    }

    //handle delete
    if (value === '' && id !== 0) {
      const prevfield = document.querySelector<HTMLInputElement>(
        `input[name=field-${id - 1}]`
      );

      if (prevfield !== null) {
        prevfield.focus();
      }
    }

    //handle add
    if (value !== '' && re.test(value)) {
      if (id !== 5) {
        const nextfield = document.querySelector<HTMLInputElement>(
          `input[name=field-${id + 1}]`
        );

        if (nextfield !== null) {
          nextfield.focus();
        }
      } else {
        const currentfield = document.querySelector<HTMLInputElement>(
          `input[name=field-${id}]`
        );

        if (currentfield !== null) {
          currentfield.blur();
        }
      }
    }
  };

  const handleConfirmEmail = async () => {
    let otpString = '';

    Object.values(otp).forEach((s) => {
      otpString += String(s);
    });

    const bodyParam = {
      code: otpString,
      email: signUpInfo.email,
      password: signUpInfo.password,
    };

    try {
      await fetchRequest({
        method: 'POST',
        path: 'auth/verify',
        data: bodyParam,
      }).then((response) => {
        if (response.authData.refreshToken) {
          const exp = response.authData.accessToken.payload.exp;

          const encryptedEmail = CryptoJS.AES.encrypt(
            signUpInfo.email,
            'user-email'
          ).toString();

          setLoginInfo({
            isLogin: true,
            username: response.userData.name,
            email: signUpInfo.email,
            refreshToken: response.authData.refreshToken.token,
            expireAt: new Date(exp * 1000),
          });

          cookies.set('member-token', response.authData.accessToken.jwtToken, {
            path: '/',
            expires: new Date(exp * 1000),
          });
          cookies.set('member-r-token', response.authData.refreshToken.token, {
            path: '/',
          });
          cookies.set('UE', encryptedEmail, {
            path: '/',
          });

          navigate(MEMBER_DASHBOARD);
        }
      });
    } catch (error: any) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      w="100vw"
      h="100vh"
      bg={RED}
      backgroundImage={`url(${imgBackground})`}
      backgroundRepeat="no-repeat"
      backgroundSize="100%"
      backgroundPosition="bottom"
    >
      <Center w="100%" h="100%">
        <Box
          w={{ base: '80vw', lg: '40vw', xl: '30vw' }}
          h={{ base: '70vh', sm: '50vh' }}
          bg={WHITE}
          borderRadius="lg"
          padding="20px"
        >
          <Center w="100%" h="100%">
            <VStack align="start">
              <Image
                src={imgLogo}
                w={{ base: '40%', lg: '35%' }}
                onClick={() => navigate(BERANDA)}
              />
              <Text
                className="fontBold"
                fontSize={{ lg: '40px', base: '24px' }}
                color={BLACK}
              >
                Konfirmasi Email
              </Text>
              <Text fontSize={{ lg: 'lg', base: 'sm' }}>
                Kami telah mengirimkan email konfirmasi kepada
                <span style={{ color: RED }}>{' ' + signUpInfo.email}</span>.
                Anda tidak bisa mendapatkan akses member sebelum melakukan
                konfirmasi terhadap email Anda.
              </Text>
              <Text fontSize={{ lg: 'lg', base: 'sm' }}>
                Masukkan kode yang Anda dapatkan:
              </Text>
              <Box w="100%" paddingTop="10px" paddingBottom="20px">
                <Grid templateColumns="repeat(6,1fr)" gap={2}>
                  {Array.from(Array(6).keys()).map((val) => (
                    <GridItem
                      w="100%"
                      h="60px"
                      bg={BABYGREY}
                      borderRadius="lg"
                      key={val}
                    >
                      <Center w="100%" h="100%">
                        <Input
                          variant="flushed"
                          w="50%"
                          fontSize="2xl"
                          _focus={{ boxShadow: 'none' }}
                          border="none"
                          defaultValue=""
                          value={otp[val as keyof typeof otp]}
                          onChange={(e) =>
                            handleOTPfield({ id: val, value: e.target.value })
                          }
                          name={'field-' + val}
                        />
                      </Center>
                    </GridItem>
                  ))}
                </Grid>
              </Box>
              <CustomButton
                text="KONFIRMASI"
                w="100%"
                wMobile="100%"
                variant="red"
                icon="none"
                disabled={isButtonDisabled}
                onClick={handleConfirmEmail}
              />
            </VStack>
          </Center>
        </Box>
      </Center>
    </Box>
  );
};

export default EmailConfirmation;
