import React, { useEffect, useState } from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { BLACK, LIGHTGREY, RED, WHITE } from '@constants/colors';
import { MEMBERSHIP_ADVANTAGE_TITLE, MEMBERSHIP_TITLE } from '@constants/texts';
import Category from '@components/Category';
import CustomBreadcrumb from '@components/CustomBreadcrumb';
import fetchRequest from '@utils/fetcher';
import { BREADCRUMB_MEMBERSHIP } from '@constants/breakcrumbs';
import { QuickLinkProps } from '@customTypes/quickLink';

const Membership = () => {
  const [description, setDescription] = useState<string>('');
  const [benefit, setBenefit] = useState<string>('');
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);

  const getResearchData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'about-us/membership',
      }).then((response) => {
        setDescription(response.description);
        setBenefit(response.benefit);
        setQuickLinkList(response.quickLinks || []);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getResearchData();
  }, []);

  return (
    <Box w={'100%'}>
      <Box
        h="30px"
        paddingLeft={{ base: '10px', lg: '3%' }}
        paddingRight={{ base: '10px' }}
        w="100%"
      >
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_MEMBERSHIP} />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop="20px"
      >
        <Box
          w={{ md: '20%' }}
          h={'400px'}
          top={{ md: '150', base: '0' }}
          position={'sticky'}
          display={{ md: 'inline', base: 'none' }}
        >
          <Category headers={[...quickLinkList]} />
        </Box>
        <Box w={{ lg: '65%', md: '75%', sm: '90%' }}>
          <Text
            className={'fontSemiBold'}
            fontSize={{ xl: '40px', lg: '32px', md: '24px' }}
            color={BLACK}
            align={'center'}
          >
            {MEMBERSHIP_TITLE}
          </Text>
          <Box
            w={'100%'}
            padding={'24px'}
            borderRadius={'lg'}
            fontSize={{ lg: '18px', base: '14px' }}
            color={WHITE}
            backgroundColor={RED}
          >
            <Text className={'fontSemiBold'} dangerouslySetInnerHTML={{ __html: description }}/>
          </Box>
          <Box
            w={'100%'}
            padding={'24px'}
            borderRadius={'lg'}
            fontSize={{ lg: '18px', base: '14px' }}
            bg={LIGHTGREY}
          >
            <Text color={RED} className={'fontSemiBold'}>
              {MEMBERSHIP_ADVANTAGE_TITLE}
            </Text>
            <Text
              textAlign={'justify'}
              dangerouslySetInnerHTML={{ __html: benefit }}
            ></Text>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Membership;
