import React, { Component, useEffect, useState } from 'react';

import { Box, Center, VStack, Container } from '@chakra-ui/react';
import { WHITE } from '@constants/colors';
import { DUMMY_TESTIMONIAL } from '@constants/texts';

import bg_testimonial from '@assets/homepage/bg-testimonial.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { TestimoniProps } from '@customTypes/homepage';
import fetchRequest from '@utils/fetcher';

const Testimonial = () => {
  const [testimoniData, setTestimoniData] = useState<TestimoniProps[]>([]);

  const settings = {
    arrows: false,
    autoplay: true,
    infinite: true,
    centerMode: true,
    variableWidth: true,
    initialSlide: 1,
    slidesToShow: 1,
    slidesToScroll: 2,
  };

  const getTestimoniData = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'homepage' }).then(
        (response) => {
          setTestimoniData(response.testimoni);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTestimoniData();
  }, []);

  return (
    <Slider {...settings}>
      {testimoniData.map((content, index) => (
        <Box padding={{ lg: '30px', md: '20px', base: '10px' }} key={index}>
          <Center>
            <VStack
              w={{
                lg: '510px',
                md: '348px',
                base: '230px',
              }}
              h={{ lg: '325px', md: '250px', base: '160px' }}
              paddingY={{ lg: '30px', md: '40px', base: '15px' }}
              paddingX={{ lg: '30px', md: '25px', base: '10px' }}
              shadow={'xl'}
              borderRadius={'lg'}
              bgImage={bg_testimonial}
            >
              <Container
                className={'fontBold'}
                color={WHITE}
                fontSize={{ lg: '18px', md: '14px', base: '10px' }}
              >
                {content.name}
              </Container>
              <Container
                className={'fontRegular'}
                color={WHITE}
                fontSize={{
                  xl: '13px',
                  md: '11px',
                  base: '7px',
                }}
              >
                {content.position}
              </Container>
              <Box h={{ md: '10%', base: '0' }} />
              <Container
                className={'fontSemiBold'}
                color={WHITE}
                fontSize={{
                  xl: '16px',
                  md: '12px',
                  base: '7px',
                }}
                textAlign={{ md: 'justify', base: 'center' }}
              >
                {content.content}
              </Container>
            </VStack>
          </Center>
        </Box>
      ))}
    </Slider>
  );
};

export default Testimonial;
