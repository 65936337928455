import React, { createContext, useContext, useState } from 'react';

type LoginProps = {
  isLogin: boolean;
  username: string;
  email: string;
  refreshToken: string;
  expireAt: Date;
};

type SignUpProps = {
  email: string;
  password: string;
};

type ForgotPassProps = {
  email: string;
};

export const GlobalContext = createContext({
  loginInfo: {} as LoginProps,
  setLoginInfo: {} as React.Dispatch<React.SetStateAction<LoginProps>>,
  signUpInfo: {} as SignUpProps,
  setSignUpInfo: {} as React.Dispatch<React.SetStateAction<SignUpProps>>,
  forgotPassInfo: {} as ForgotPassProps,
  setForgotPassInfo: {} as React.Dispatch<
    React.SetStateAction<ForgotPassProps>
  >,
});

const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [loginInfo, setLoginInfo] = useState<LoginProps>({
    isLogin: false,
    username: '',
    email: '',
    refreshToken: '',
    expireAt: new Date(),
  });

  const [signUpInfo, setSignUpInfo] = useState<SignUpProps>({
    email: '',
    password: '',
  });

  const [forgotPassInfo, setForgotPassInfo] = useState<ForgotPassProps>({
    email: '',
  });

  return (
    <GlobalContext.Provider
      value={{
        loginInfo,
        setLoginInfo,
        signUpInfo,
        setSignUpInfo,
        forgotPassInfo,
        setForgotPassInfo,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobalState = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalContext');
  }
  return context;
};

export { GlobalProvider, useGlobalState };
