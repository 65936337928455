import { useEffect, useState } from 'react';

import {
  Box,
  Stack,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';

import CustomBreadcrumb from '@components/CustomBreadcrumb';

import fetchRequest from '@utils/fetcher';

import { BREADCRUMB_SERTIFIKAT } from '@constants/breakcrumbs';
import { BLACK } from '@constants/colors';

import Card from './Card';

type CertificateProps = {
  eventName: string;
  pdfUrl: string;
  thumbnailUrl: string;
};

const Certificate = () => {
  const [certifData, setCertifData] = useState<CertificateProps[]>([]);

  const getCertifData = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'member/certificate' }).then(
        (response) => {
          if (Array.isArray(response)) {
            setCertifData(response);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCertifData();
  }, []);

  return (
    <Box>
      <Box h="30px" paddingLeft={{ base: '10px', lg: '3%' }} w="100%">
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_SERTIFIKAT} />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop={{ base: '0px', lg: '20px' }}
      >
        <Box
          w={{ md: '20%' }}
          h="400px"
          display={{ md: 'inline', base: 'none' }}
        />
        <Box w="100%">
          <Text
            className={'fontBold'}
            fontSize={{ md: '40px', base: '24px' }}
            color={BLACK}
            textAlign={{ base: 'center', lg: 'start' }}
          >
            Sertifikat
          </Text>
          <Text paddingBottom="30px">
            Berikut adalah daftar sertifikat yang pernah Anda dapatkan melalui
            keikutsertaan dalam acara-acara yang diselenggarakan oleh Astagatra
            Institute
          </Text>
          <Wrap spacing="16px">
            {certifData.map((certif, i) => (
              <WrapItem w={{ base: '100%', md: '25%' }} key={i}>
                <Card {...certif} />
              </WrapItem>
            ))}
          </Wrap>
        </Box>
      </Stack>
    </Box>
  );
};

export default Certificate;
