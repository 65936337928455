import React, { useEffect, useState } from 'react';
import { Box, Container, Stack, Text, Image, VStack, Center } from '@chakra-ui/react';
import {
  ABOUT_US_TITLE,
  FOUNDERS_TITLE,
  HISTORY_TITLE,
} from '@constants/texts';
import { BLACK, LIGHTGREY, RED, WHITE } from '@constants/colors';
import { BREADCRUMB_TENTANG_KAMI } from '@constants/breakcrumbs';
import { QuickLinkProps } from '@customTypes/quickLink';
import CustomBreadcrumb from '@components/CustomBreadcrumb';
import fetchRequest from '@utils/fetcher';
import Category from '@components/Category';
import { FoundersProps } from '@customTypes/founders';

import historyPicture from '@assets/aboutUs/history_picture.svg';

const AboutUs = () => {
  const [history, setHistory] = useState<string>('');
  // const [historyPicture, setHistoryPicture] = useState('');
  const [founders, setFounders] = useState<FoundersProps[]>([]);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);

  const getResearchData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'about-us/history',
      }).then((response) => {
        console.log(response)
        setHistory(response.history);
        // setHistoryPicture(response.historyImage);
        setFounders(response.founders);
        setQuickLinkList(response.quickLinks || []);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getResearchData();
  }, []);

  return (
    <Box w={'100%'}>
      <Box
        h="30px"
        paddingLeft={{ base: '10px', lg: '3%' }}
        paddingRight={{ base: '10px' }}
        w="100%"
      >
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_TENTANG_KAMI} />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop="20px"
      >
        <Box
          w={{ md: '20%' }}
          h={'400px'}
          top={{ md: '150', base: '0' }}
          position={'sticky'}
          display={{ md: 'inline', base: 'none' }}
        >
          <Category headers={[ ...quickLinkList]} />
        </Box>
        <Box w={{ md: '75%', sm: '90%' }}>
          <Text
            className={'fontSemiBold'}
            fontSize={{ xl: '40px', lg: '32px', md: '24px' }}
            color={BLACK}
            align={{ lg: 'left', base: 'center' }}
          >
            {ABOUT_US_TITLE}
          </Text>

          <br />
          <Box w={{ base: '100%' }} h={{ xl: '630px', lg: '780px'  }} bg={RED}>
            <Box
              w={{ lg: '50%', base: '100%' }}
              h={{ lg: '100%', base: '237px' }}
              float={'right'}
            >
              <Image
                src={historyPicture}
                boxSize={{ base: '100%' }}
                objectFit={{ base: 'cover' }}
              />
            </Box>
            <Box
              w={{ lg: '50%', base: '100%' }}
              h={{ lg: '100%' }}
              padding={{ lg: '28px', base: '18px' }}
              color={WHITE}
            >
              <Container
                className={'fontSemiBold'}
                fontSize={{ lg: '28px', base: '20px' }}
              >
                {HISTORY_TITLE}
              </Container>
              <Container
                className={'fontRegular'}
                fontSize={{ '2xl': '17px', lg: '16px', base: '12px' }}
                textAlign={{ base: 'justify' }}
                dangerouslySetInnerHTML={{ __html: history }}
              />
            </Box>
          </Box>
          <br />
          <Box>
            <Text
              className={'fontSemiBold'}
              fontSize={{ md: '32px', base: '24px' }}
              color={BLACK}
              align={'left'}
            >
              {FOUNDERS_TITLE}
            </Text>
            <br />
            <Stack
              h={{ lg: '500px', base: '100%' }}
              align={'center'}
              justify={'center'}
              gap={'10px'}
              direction={{ lg: 'row', base: 'column' }}
            >
              {founders.map((m, i) => (
                <Box w={{ lg: '340px', base: '280px' }} h={'100%'} key={i}>
                  <Image h={'70%'} w={'100%'} src={m.photo} />
                  <VStack
                    padding={'10px'}
                    className={'fontSemiBold'}
                    justify={'space-between'}
                    align={'center'}
                    bg={LIGHTGREY}
                  >
                    <Text fontSize={{ lg: '18px', base: '12px' }}>
                      {m.name}
                    </Text>
                    <Text fontSize={{ lg: '14px', base: '10px' }}>
                      {m.position}
                    </Text>
                  </VStack>
                </Box>
              ))}
            </Stack>
          </Box>
          <br />
        </Box>
      </Stack>
    </Box>
  );
};

export default AboutUs;
