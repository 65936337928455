import { useEffect, useState } from 'react';

import { Grid, GridItem, Box, Stack, Text, Container,Image} from '@chakra-ui/react';

import CustomBreadcrumb from '@components/CustomBreadcrumb';
import Category from '@components/Category';

import { BREADCRUMB_WEBINAR } from '@constants/breakcrumbs';
import { BLACK } from '@constants/colors';
import WEB_CSR from '@assets/service/Thumbnail Web CSR.png'

import type { WebinarProps } from '@customTypes/webinar';
import type { QuickLinkProps } from '@customTypes/quickLink';

import fetchRequest from '@utils/fetcher';

import Card from './Card';

import DOMPurify from 'dompurify';

import './styles.css';

const Webinar = () => {
  const [description, setDescription] = useState('');
  const [webinarList, setWebinarList] = useState<WebinarProps[]>([]);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);

  const getArticleListData = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'event/webinar-series' }).then(
        (response) => {
          setDescription(response.mainDesc || []);
          setWebinarList(response.dataList || []);
          setQuickLinkList(response.quickLinks || []);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getArticleListData();
  }, []);

  return (
    <Box>
      <Box h="30px" paddingLeft={{ base: '10px', lg: '3%' }} w="100%">
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_WEBINAR} />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop={{ base: '0px', lg: '20px' }}
      >
        <Box
          w={{ md: '20%' }}
          h="400px"
          top={{ md: '150', base: '0' }}
          position={'sticky'}
          display={{ md: 'inline', base: 'none' }}
        >
          <Category headers={[...quickLinkList]} />
        </Box>
        <Box w={{ lg: '60%', md: '75%', sm: '90%' }}>
       
          <Text
            className={'fontBold'}
            fontSize={{ md: '40px', base: '24px' }}
            color={BLACK}
            textAlign="center"
            paddingBottom="10px"
          >
            Publikasi Mutiara CSR Indonesia
          </Text>
          {/* <Container 
          w="100%"
          h="1500"
          marginBottom="20px"
          // paddingX="30px"> */}
         
          <Image
            w="100%"
            h={{ lg: '1500px', md: '70%', sm: '40%' }}
            objectFit="cover"
            marginBottom="20px"
            paddingX="30px"
            src={WEB_CSR}
          />

          {/* </Container> */}
         
          <Box
            paddingX="30px"
            paddingBottom="30px"
            textAlign="justify"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description),
            }}
            className="styTextBox"
          />
          {/* <Grid templateColumns="repeat(2, 1fr)" gap={3}>
            {webinarList.map((webinar, i) => (
              <GridItem w="100%" h="380px" key={i} colSpan={{ base: 2, lg: 1 }}>
                <Card {...webinar} />
              </GridItem>
            ))}
          </Grid> */}
          <Box
            w={{ md: '20%' }}
            h="100%"
            display={{ md: 'none', base: 'block' }}
            paddingTop="50px"
            paddingBottom="100px"
          >
            <Category headers={quickLinkList} />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Webinar;
