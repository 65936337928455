import React, { useState } from 'react';

import { Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react';

import { ChevronDownIcon } from '@chakra-ui/icons';

import { PINK, RED } from '@constants/colors';
import { SEARCH_CATEGORY } from '@constants/search';

type Props = {
  selectedCategory: (ct: string) => void;
};

const Filter = ({ selectedCategory }: Props) => {
  const [category, setCategory] = useState('All');

  const handleSelect = (ct: string) => {
    setCategory(ct);
    selectedCategory(ct);
  };

  return (
    <Menu>
      <MenuButton
        height={43}
        as={Button}
        rightIcon={<ChevronDownIcon marginLeft={1} />}
        _focus={{ boxShadow: 'none' }}
        textAlign="start"
      >
        {SEARCH_CATEGORY[category as keyof typeof SEARCH_CATEGORY].name}
      </MenuButton>
      <MenuList>
        {Object.values(SEARCH_CATEGORY).map((ct, i) => (
          <MenuItem
            key={i}
            onClick={() => handleSelect(ct.value)}
            _hover={{ bg: PINK, color: RED }}
            _focus={{ boxShadow: 'none' }}
            _active={{ bg: PINK, color: RED }}
          >
            {ct.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default Filter;
