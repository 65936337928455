import React from "react";
import { useNavigate } from "react-router";

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from "@chakra-ui/react";
import { BLACK, RED } from "@constants/colors";

type Props = {
  breadcrumbs: {
    name: string;
    url: string;
  }[];
};

const CustomBreadcrumb = ({ breadcrumbs }: Props) => {
  const navigate = useNavigate();

  return (
    <Breadcrumb marginTop="20px">
      {breadcrumbs.map((b, i) => (
        <BreadcrumbItem key={i}>
          {b.url !== "" ? (
            <BreadcrumbLink
              color={i === breadcrumbs.length - 1 ? RED : BLACK}
              as="b"
              fontSize={{ md: "md", base: "sm" }}
              onClick={() => navigate(b.url)}
              isTruncated
              maxWidth="50vw"
            >
              {b.name}
            </BreadcrumbLink>
          ) : (
            <Text color={i === breadcrumbs.length - 1 ? RED : BLACK} fontSize={{ md: "md", base: "sm" }} isTruncated maxWidth="50vw">
              {b.name}
            </Text>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
