import { useState } from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Divider,
  Text,
  VStack,
  RadioGroup,
  Radio,
  HStack,
} from '@chakra-ui/react';

import { BLACK } from '@constants/colors';

import CustomButton from '@components/Button';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleSort: (sort: boolean) => void;
};

const SORTS = [
  {
    name: 'Urutkan dari terbaru ke terlama',
    value: 'true',
  },
  {
    name: 'Urutkan dari terlama ke terbaru',
    value: 'false',
  },
];

const SortEvent = ({ isOpen, onClose, handleSort }: Props) => {
  const [isNewest, setIsNewest] = useState('false');

  const handleSortClick = () => {
    handleSort(isNewest === 'true' ? true : false);
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton
          variant="ghost"
          _hover={{ bg: 'transparent' }}
          _focus={{ boxShadow: 'none' }}
          color={BLACK}
          top="15px"
          as="b"
        />
        <DrawerHeader>URUTKAN</DrawerHeader>
        <DrawerBody>
          <VStack align="start">
            <Text paddingBottom="10px">Urutkan berdasarkan...</Text>
            <Divider />
            <RadioGroup
              value={isNewest}
              onChange={setIsNewest}
              defaultValue="true"
              paddingTop="5px"
            >
              <VStack align="start">
                {SORTS.map((sort, i) => (
                  <>
                    <Radio
                      key={i}
                      value={sort.value}
                      colorScheme="red"
                      _focus={{ boxShadow: 'none' }}
                    >
                      {sort.name}
                    </Radio>
                    <Divider paddingTop="5px" paddingBottom="5px" />
                  </>
                ))}
              </VStack>
            </RadioGroup>
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          <HStack>
            <CustomButton
              text="BATAL"
              variant="white"
              icon="none"
              w="100px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="URUTKAN"
              variant="red"
              icon="none"
              w="120px"
              onClick={() => handleSortClick()}
            />
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default SortEvent;
