export const BERANDA = "/";

export const TENTANG_KAMI = "/aboutUs";
export const VISI_MISI = TENTANG_KAMI + "/visionMision";
export const MANAJEMEN = TENTANG_KAMI + "/management";
export const ALF = TENTANG_KAMI + "/alf";
export const MEMBERSHIP = TENTANG_KAMI + "/membership";

export const PRODUK_KAMI = "/product";
export const KAJIAN_DAN_KONSULTASI = PRODUK_KAMI + "/research";
export const KONSULTASI_PUBLIK = KAJIAN_DAN_KONSULTASI + "/publicConsultation";
export const KONSULTASI_KORPORASI = KAJIAN_DAN_KONSULTASI + "/corporationConsultation";
export const PELATIHAN = PRODUK_KAMI + "/learning";
export const WEBINAR = PRODUK_KAMI + "/webinar";

export const LAYANAN = "/service";
export const ARTIKEL = LAYANAN + "/article";
export const BERITA = LAYANAN + "/news";
export const PUBLIKASI = LAYANAN + "/publication";
export const ACARA = LAYANAN + "/event";
export const GALERI = LAYANAN + "/gallery";

export const KONTAK = "/contactUs";

export const AUTH = "/auth";
export const LOGIN = "/login";
export const SIGN_UP = "/signUp";
export const OTP = SIGN_UP + "/otp";
export const EMAIL_CONFIRMATION = "/emailConfirmation";

const PASSWORD = "/password";
export const FORGOT_PASSWORD = PASSWORD + "/forgot";
export const RESET_PASSWORD = PASSWORD + "/reset";

export const MEMBER = "/member";
export const MEMBER_DASHBOARD = MEMBER + "/dashboard";
export const MEMBER_SETTING = MEMBER + "/setting";
export const MEMBER_SETTING_RESET_PASSWORD = MEMBER_SETTING + "/resetPassword";
export const SERTIFIKAT = MEMBER + "/certificate";
