import React from 'react';
import {
  Text,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Box,
  Stack,
  Container,
} from '@chakra-ui/react';
import { GREY, RED } from '@constants/colors';
import { ALFProps } from '@customTypes/alf';
import { SEE_CV_TITLE } from '@constants/texts';

const ModalMobileCV = (data: ALFProps) => {
  const { name, photo, content, position } = data;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Text
        display={{ md: 'none', base: 'inline' }}
        as="u"
        onClick={onOpen}
        textColor={RED}
        fontSize={'12px'}
        className={'fontSemiBold'}
      >
        {SEE_CV_TITLE}
      </Text>
      <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Stack textAlign={'center'} direction="column">
              <Box width="100%" >
              <Image src={photo} maxW='200px' maxH='300px' marginX='auto'/>
              </Box>
              <Container className="fontSemiBold" fontSize={'20px'}>
                {name}
              </Container>
              <Container fontSize={'18px'} color={GREY}>
                {position}
              </Container>
              <Box h={'20px'}></Box>
              <Text
                textAlign={'justify'}
                dangerouslySetInnerHTML={{ __html: content }}
              ></Text>
              <Box h={'20px'}></Box>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalMobileCV;
