import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';

import {
  Box,
  Stack,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
  Image,
  Center,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Checkbox,
  HStack,
  Button,
  useToast,
} from '@chakra-ui/react';

import imgPasswordIcon from '@assets/member/password.svg';
import imgPasswordConfirmIcon from '@assets/member/password-confirm.svg';

import { BLACK, RED } from '@constants/colors';
import { BREADCRUMB_UBAH_PASSWORD } from '@constants/breakcrumbs';

import CustomBreadcrumb from '@components/CustomBreadcrumb';
import CustomButton from '@components/Button';
import fetchRequest from '@utils/fetcher';

const ChangePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [isConfirmPassInvalid, setIsConfirmPassInvalid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');

  const toast = useToast();

  const handleConfirmPassword = (value: string) => {
    setConfirmationPassword(value);
    if (value !== password) {
      setIsConfirmPassInvalid(true);
    } else {
      setIsConfirmPassInvalid(false);
    }
  };

  const handlePassword = (value: string) => {
    setPassword(value);
    if (value !== confirmationPassword) {
      setIsConfirmPassInvalid(true);
    } else {
      setIsConfirmPassInvalid(false);
    }
  };

  const handleSubmit = async () => {
    const cookies = new Cookies();

    const token = cookies.get('member-token') || '';

    const param = {
      oldPassword,
      newPassword: password,
      accessToken: token,
    };

    try {
      await fetchRequest({
        method: 'PATCH',
        path: 'member/password',
        data: param,
      }).then((response) => {
        if (!response.error) {
          toast({
            title: 'Berhasil',
            description: 'Password Anda telah berhasil diubah',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Error',
            description: response.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Box h="30px" paddingLeft={{ base: '10px', lg: '3%' }} w="100%">
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_UBAH_PASSWORD} />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop={{ base: '0px', lg: '20px' }}
      >
        <Box
          w={{ md: '20%' }}
          h="400px"
          display={{ md: 'inline', base: 'none' }}
        />
        <Box w="100%">
          <Text
            className={'fontBold'}
            fontSize={{ md: '40px', base: '24px' }}
            color={BLACK}
            textAlign={{ base: 'center', lg: 'start' }}
          >
            Ubah Password
          </Text>
          <VStack align="start">
            <Text className="fontSemiBold" fontSize={{ lg: 'md', base: 'sm' }}>
              Password Lama
            </Text>
            <InputGroup paddingTop="5px">
              <InputLeftElement
                pointerEvents="none"
                children={<Image src={imgPasswordIcon} w="30%" />}
                paddingTop="5px"
              />
              <Input
                type={showOldPassword ? 'text' : 'password'}
                placeholder="Masukkan Password Lama"
                _focus={{ boxShadow: 'none', borderColor: RED }}
                fontSize={{ lg: 'md', base: 'sm' }}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                isInvalid={password === '' || password.length < 8}
                errorBorderColor={RED}
              />
              <InputRightElement width={showOldPassword ? '130px' : '65px'}>
                <Button
                  h="2rem"
                  size="sm"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                  marginTop="10px"
                  marginRight="5px"
                  _focus={{ boxShadow: 'none' }}
                  fontSize={{ lg: 'md', base: 'xs' }}
                >
                  {showOldPassword ? 'SEMBUNYIKAN' : 'LIHAT'}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Text className="fontSemiBold" fontSize={{ lg: 'md', base: 'sm' }}>
              Password Baru
            </Text>
            <InputGroup paddingTop="5px">
              <InputLeftElement
                pointerEvents="none"
                children={<Image src={imgPasswordIcon} w="30%" />}
                paddingTop="5px"
              />
              <Input
                type={showPassword ? 'text' : 'password'}
                placeholder="Masukkan Password Baru"
                _focus={{ boxShadow: 'none', borderColor: RED }}
                fontSize={{ lg: 'md', base: 'sm' }}
                value={password}
                onChange={(e) => handlePassword(e.target.value)}
                isInvalid={password === '' || password.length < 8}
                errorBorderColor={RED}
              />
              <InputRightElement width={showPassword ? '130px' : '65px'}>
                <Button
                  h="2rem"
                  size="sm"
                  onClick={() => setShowPassword(!showPassword)}
                  marginTop="10px"
                  marginRight="5px"
                  _focus={{ boxShadow: 'none' }}
                  fontSize={{ lg: 'md', base: 'xs' }}
                >
                  {showPassword ? 'SEMBUNYIKAN' : 'LIHAT'}
                </Button>
              </InputRightElement>
            </InputGroup>
            <InputGroup paddingTop="5px">
              <InputLeftElement
                pointerEvents="none"
                children={<Image src={imgPasswordConfirmIcon} w="40%" />}
                paddingTop="5px"
              />
              <Input
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Konfirmasi Password"
                _focus={{ boxShadow: 'none', borderColor: RED }}
                fontSize={{ lg: 'md', base: 'sm' }}
                value={confirmationPassword}
                onChange={(e) => handleConfirmPassword(e.target.value)}
                isInvalid={isConfirmPassInvalid}
                errorBorderColor={RED}
              />
              <InputRightElement width={showConfirmPassword ? '130px' : '65px'}>
                <Button
                  h="2rem"
                  size="sm"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  marginTop="10px"
                  marginRight="5px"
                  _focus={{ boxShadow: 'none' }}
                  fontSize={{ lg: 'md', base: 'xs' }}
                >
                  {showConfirmPassword ? 'SEMBUNYIKAN' : 'LIHAT'}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Box textAlign="end" w="100%">
              <CustomButton
                text="SIMPAN"
                variant="red"
                icon="none"
                w="150px"
                onClick={handleSubmit}
              />
            </Box>
          </VStack>
        </Box>
        <Box
          w={{ md: '20%' }}
          h="400px"
          display={{ md: 'inline', base: 'none' }}
        />
      </Stack>
    </Box>
  );
};

export default ChangePassword;
