import { Cookies } from 'react-cookie';

import axios from 'axios';

type Props = {
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  path: string;
  data?: any;
};

const fetchRequest = async ({ method, path, data }: Props) => {
  const cookies = new Cookies();

  const token = cookies.get('member-token') || '';

  try {
    let url =
      process.env.NODE_ENV == 'production'
        ? `https://api.astagatra.com/v1/${path}`
        : `http://localhost:3000/v1/${path}`;

    let response;

    const standardHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    const headers = token
      ? { ...standardHeaders, Authorization: 'Bearer ' + token }
      : standardHeaders;

    if (method === 'GET') {
      response = await fetch(url, {
        method,
        headers: headers,
      });
    }

    response = await fetch(url, {
      method,
      headers: headers,
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result.error != null) {
      throw result;
    } else {
      return result;
    }
  } catch (error) {
    throw error;
  }
};

export default fetchRequest;

export const fetchFormData = async ({ method, path, data }: Props) => {
  const cookies = new Cookies();

  const token = cookies.get('member-token') || '';

  try {
    let url =
      process.env.NODE_ENV == 'production'
        ? `https://api.astagatra.com/v1/${path}`
        : `http://localhost:3000/v1/${path}`;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + token,
      },
    };

    if (method == 'POST') {
      const response = await axios.post(url, data, config);
      const result = await response.data;
      return result;
    } else if (method == 'PATCH') {
      const response = await axios.patch(url, data, config);
      const result = await response.data;
      return result;
    }
  } catch (error) {
    throw error;
  }
};
