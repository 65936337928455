import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Center,
  Box,
  VStack,
  Image,
  Text,
  Input,
  useToast,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ListItem,
  ListIcon,
  List,
  Button,
} from '@chakra-ui/react';

import { CheckIcon } from '@chakra-ui/icons';

import imgBackground from '@assets/member/background.svg';
import imgLogo from '@assets/logo.svg';
import imgPasswordIcon from '@assets/member/password.svg';
import imgPasswordConfirmIcon from '@assets/member/password-confirm.svg';

import { RED, WHITE, BLACK, GREY } from '@constants/colors';
import { AUTH, BERANDA, FORGOT_PASSWORD, LOGIN } from '@constants/urls';

import CustomButton from '@components/Button';

import fetchRequest from '@utils/fetcher';

import { useGlobalState } from '@contexts/globalState';

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [isConfirmPassInvalid, setIsConfirmPassInvalid] = useState(true);
  const [verifCode, setVerifCode] = useState('');
  const [isVerifCodeInvalid, setIsVerifCodeInvalid] = useState(false);

  const navigate = useNavigate();

  const toast = useToast();

  const { forgotPassInfo } = useGlobalState();

  useEffect(() => {
    if (!forgotPassInfo.email) {
      navigate(AUTH + FORGOT_PASSWORD);
    }
  }, [forgotPassInfo]);

  const handleConfirmPassword = (value: string) => {
    setConfirmationPassword(value);
    if (value !== password) {
      setIsConfirmPassInvalid(true);
    } else {
      setIsConfirmPassInvalid(false);
    }
  };

  const handlePassword = (value: string) => {
    setPassword(value);
    if (value !== confirmationPassword) {
      setIsConfirmPassInvalid(true);
    } else {
      setIsConfirmPassInvalid(false);
    }
  };

  const handleVerifCode = (value: string) => {
    const isNumber = value.match(/^[0-9\b]+$/);

    setVerifCode(value);

    if (isNumber) {
      setIsVerifCodeInvalid(false);
    } else {
      setIsVerifCodeInvalid(true);
    }

    if (value.length !== 6) {
      setIsVerifCodeInvalid(true);
    }
  };

  const handleResetPassword = async () => {
    const param = {
      email: forgotPassInfo.email,
      verificationCode: verifCode,
      password,
    };
    try {
      await fetchRequest({
        method: 'POST',
        path: 'auth/reser-password',
        data: param,
      }).then((response) => {
        if (response.idToken) {
          navigate(AUTH + LOGIN);
        }
      });
    } catch (error: any) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      w="100vw"
      h="100vh"
      bg={RED}
      backgroundImage={`url(${imgBackground})`}
      backgroundRepeat="no-repeat"
      backgroundSize="100%"
      backgroundPosition="bottom"
    >
      <Center w="100%" h="100%">
        <Box
          w={{ base: '80vw', lg: '40vw', xl: '30vw' }}
          h={{ sm: '50vh', lg: '70vh' }}
          bg={WHITE}
          borderRadius="lg"
          padding="20px"
        >
          <Center w="100%" h="100%">
            <VStack align="start">
              <Image
                src={imgLogo}
                w={{ base: '40%', lg: '35%' }}
                onClick={() => navigate(BERANDA)}
              />
              <Text
                className="fontBold"
                fontSize={{ lg: '40px', base: '24px' }}
                color={BLACK}
              >
                Atur Ulang Password
              </Text>
              <Text fontSize={{ lg: 'lg', base: 'sm' }}>
                Masukkan password baru dan 6 digit kode verifikasi yang telah
                dikirimkan ke email{' '}
                <span style={{ color: RED }}>{' ' + forgotPassInfo.email}</span>
                .
              </Text>
              <Box w="100%" paddingTop="10px" paddingBottom="20px">
                <Box paddingY="5px">
                  <Text> Kata sandi Anda harus memiliki: </Text>
                  <List>
                    <ListItem>
                      <ListIcon as={CheckIcon} color={GREY} />
                      Karakter lebih dari 7
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} c color={GREY} />
                      Huruf kapital dan huruf kecil
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color={GREY} />
                      Angka dan karakter spesial (!, @, #, $, %, &, *)
                    </ListItem>
                  </List>
                </Box>
                <InputGroup paddingTop="5px">
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Image src={imgPasswordIcon} w="30%" />}
                    paddingTop="5px"
                  />
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Masukkan Password"
                    _focus={{ boxShadow: 'none', borderColor: RED }}
                    fontSize={{ lg: 'md', base: 'sm' }}
                    value={password}
                    onChange={(e) => handlePassword(e.target.value)}
                    isInvalid={password === '' || password.length < 8}
                    errorBorderColor={RED}
                  />
                  <InputRightElement width={showPassword ? '130px' : '65px'}>
                    <Button
                      h="2rem"
                      size="sm"
                      onClick={() => setShowPassword(!showPassword)}
                      marginTop="10px"
                      marginRight="5px"
                      _focus={{ boxShadow: 'none' }}
                      fontSize={{ lg: 'md', base: 'xs' }}
                    >
                      {showPassword ? 'SEMBUNYIKAN' : 'LIHAT'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <InputGroup paddingTop="5px">
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Image src={imgPasswordConfirmIcon} w="40%" />}
                    paddingTop="5px"
                  />
                  <Input
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Konfirmasi Password"
                    _focus={{ boxShadow: 'none', borderColor: RED }}
                    fontSize={{ lg: 'md', base: 'sm' }}
                    value={confirmationPassword}
                    onChange={(e) => handleConfirmPassword(e.target.value)}
                    isInvalid={isConfirmPassInvalid}
                    errorBorderColor={RED}
                  />
                  <InputRightElement
                    width={showConfirmPassword ? '130px' : '65px'}
                  >
                    <Button
                      h="2rem"
                      size="sm"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      marginTop="10px"
                      marginRight="5px"
                      _focus={{ boxShadow: 'none' }}
                      fontSize={{ lg: 'md', base: 'xs' }}
                    >
                      {showConfirmPassword ? 'SEMBUNYIKAN' : 'LIHAT'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <InputGroup paddingTop="5px">
                  <Input
                    type="number"
                    placeholder="Masukkan Kode Verifikasi"
                    _focus={{ boxShadow: 'none', borderColor: RED }}
                    fontSize={{ lg: 'md', base: 'sm' }}
                    value={verifCode}
                    onChange={(e) => handleVerifCode(e.target.value)}
                    isInvalid={isVerifCodeInvalid}
                    errorBorderColor={RED}
                  />
                </InputGroup>
              </Box>
              <CustomButton
                text="KONFIRMASI"
                w="100%"
                wMobile="100%"
                variant="red"
                icon="none"
                disabled={isConfirmPassInvalid && isVerifCodeInvalid}
                onClick={handleResetPassword}
              />
            </VStack>
          </Center>
        </Box>
      </Center>
    </Box>
  );
};

export default ResetPassword;
