import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import {
  Box,
  Text,
  VStack,
  Input,
  InputGroup,
  InputLeftElement,
  Image,
  Textarea,
  HStack,
  Divider,
  useToast,
} from '@chakra-ui/react';

import imgUsernameIcon from '@assets/member/person-icon.svg';

import CustomButton from '@components/Button';

import type { CommentProps } from '@customTypes/info';

import { useGlobalState } from '@contexts/globalState';

import { GREY, LIGHTGREY, RED } from '@constants/colors';

import fetchRequest from '@utils/fetcher';

const CommentSection = ({ queryKey }: { queryKey: string }) => {
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [commentList, setCommentList] = useState<CommentProps[]>([]);

  const { loginInfo } = useGlobalState();

  const param = '/' + queryKey;

  const toast = useToast();

  const getNewsComment = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'info/comment' + param }).then(
        (response) => {
          if (Array.isArray(response) && response.length > 0) {
            setCommentList(response);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNewsComment();
  }, []);

  const handleCommentButtonClick = async () => {
    try {
      await fetchRequest({
        method: 'POST',
        path: 'info/comment' + param,
        data: {
          queryKey: decodeURIComponent(queryKey),
          name: loginInfo.isLogin ? loginInfo.username : name,
          content: comment,
        },
      }).then((response) => {
        if (response.commentId) {
          toast({
            title: 'Success',
            description: 'Comment has successfully submitted',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });

          setName('');
          setComment('');
          getNewsComment();
        } else {
          toast({
            title: 'Error',
            description: response.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      });
    } catch (error: any) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box w="100%" paddingTop="20px">
      <VStack align="start" w="100%">
        
          <Text fontSize={{ lg: 'lg', sm: 'md' }} className="fontBold">
            Komentar {commentList.length}
          </Text>
          {commentList.length > 0 &&
            commentList.map((commentDetail) => (
              <Box key={commentDetail.commentId} marginTop="20px">
                <VStack align="start">
                  <HStack>
                    <Text fontSize={{ lg: 'md', sm: 'sm' }} as="b">
                      {commentDetail.name}
                    </Text>
                    <Text fontSize={{ lg: 'md', sm: 'sm' }} color={GREY} as="b">
                      {dayjs(commentDetail.createdAt).format('DD MMM YYYY')}
                    </Text>
                  </HStack>
                  <Text fontSize={{ lg: 'md', sm: 'sm' }}>
                    {commentDetail.content}
                  </Text>
                </VStack>
                <Divider marginTop="20px" />
              </Box>
            ))}
        
        <Box paddingTop="20px" w="100%">
        <Box
          borderRadius="lg"
          border={`1px solid ${RED}`}
          w="100%"
          padding="20px"
        >
          <Text className={'fontBold'}>Berikan Kami Pendapat Anda</Text>
          <InputGroup marginTop="20px">
            <InputLeftElement
              pointerEvents="none"
              children={<Image src={imgUsernameIcon} w="30%" />}
            />
            <Input
              placeholder="Masukkan Nama"
              _focus={{ boxShadow: 'none', borderColor: RED }}
              fontSize={{ lg: 'md', base: 'sm' }}
              errorBorderColor={RED}
              value={loginInfo.isLogin ? loginInfo.username : name}
              disabled={loginInfo.isLogin}
              onChange={(e) => setName(e.target.value)}
              isInvalid={loginInfo.isLogin ? false : name === ''}
            />
          </InputGroup>
          <Textarea
            placeholder="Berika Opini Anda..."
            marginTop="20px"
            _focus={{ boxShadow: 'none', borderColor: RED }}
            fontSize={{ lg: 'md', base: 'sm' }}
            errorBorderColor={RED}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            isInvalid={comment === ''}
            borderColor={LIGHTGREY}
            bg={LIGHTGREY}
          />
          <Box marginTop="20px">
            <CustomButton
              w="100%"
              text="KIRIM KOMENTAR"
              variant="red"
              icon="none"
              onClick={handleCommentButtonClick}
            />
          </Box>
        </Box>
        </Box>
       
      </VStack>
    </Box>
  );
};

export default CommentSection;
