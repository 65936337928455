import dayjs from 'dayjs';
import parse, {
  DOMNode,
  domToReact,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import {
  Box,
  Divider,
  HStack,
  Image,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';

import { ICONS } from '@routes/Service/constants/icons';

import CustomButton from '@components/Button';
import Category from '@components/Category';
import CustomBreadcrumb from '@components/CustomBreadcrumb';

import type { InfoDetailProps } from '@customTypes/info';
import type { QuickLinkProps } from '@customTypes/quickLink';

import { useGlobalState } from '@contexts/globalState';

import fetchRequest from '@utils/fetcher';

import { BREADCRUMB_ARTIKEL } from '@constants/breakcrumbs';
import { BLACK, GREY } from '@constants/colors';

const options: HTMLReactParserOptions = {
  replace: (domNode: DOMNode) => {
    if ((domNode as Element).name == 'ol') {
      return (
        <ol style={{ paddingLeft: '20px' }}>
          {domToReact((domNode as Element).children)}
        </ol>
      );
    } else if ((domNode as Element).name == 'ul') {
      return (
        <ul style={{ paddingLeft: '20px' }}>
          {domToReact((domNode as Element).children)}
        </ul>
      );
    }
  },
};

const ArticleDetail = () => {
  const location = useLocation();
  const queryKey = location.pathname.split('/');

  const [categoryList, setCategoryList] = useState([]);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const [articleData, setArticleData] = useState<InfoDetailProps>();
  // const [isUserLike, setIsUserLike] = useState(false);

  const toast = useToast();

  const { loginInfo } = useGlobalState();

  const getArticleDetail = async () => {
    let param = '/' + queryKey[3];

    try {
      await fetchRequest({ method: 'GET', path: 'info' + param }).then(
        (response) => {
          setCategoryList(response.categories || []);
          setQuickLinkList(response.quickLinks || []);
          const responseData = response.data || {};
          setArticleData(responseData);
          // setIsUserLike(responseData?.isUserLike || false);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getArticleDetail();
  }, []);

  const handleSelectedCategoryClick = () => {};

  // const handleButtonClick = async () => {
  //   let param = '/' + queryKey[3];
  //   try {
  //     await fetchRequest({ method: 'POST', path: 'info/like' + param }).then(
  //       (response) => {
  //         console.log(response);
  //         //change button variant to be red
  //         setIsUserLike(true);
  //       }
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <Box>
      <Box h="30px" paddingLeft={{ base: '10px', lg: '3%' }} w="100%">
        <CustomBreadcrumb
          breadcrumbs={[
            ...BREADCRUMB_ARTIKEL,
            { name: articleData?.title || '', url: '' },
          ]}
        />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop={{ base: '0px', lg: '20px' }}
      >
        <Box
          w={{ md: '20%' }}
          h="400px"
          display={{ md: 'inline', base: 'none' }}
        >
          <Category headers={[]} />
          <VStack align="start" paddingTop="8px">
            {categoryList.map((c, i) => (
              <Text
                fontSize="md"
                color={BLACK}
                key={i}
                cursor="pointer"
                onClick={() => handleSelectedCategoryClick()}
              >
                {c}
              </Text>
            ))}
          </VStack>
        </Box>
        <Box w={{ md: '60%' }}>
          <Image
            w="100%"
            h={{ lg:"50vh", sm: "20vh", base: "100%" }}
            objectFit="contain"
            src={articleData?.headerUrl || ''}
          ></Image>
          <Text
            className={'fontBold'}
            fontSize={{ md: '40px', base: '24px' }}
            color={BLACK}
            textAlign={{ base: 'center', lg: 'start' }}
            marginBottom="10"
            marginTop="4"
          >
            {articleData?.title || ''}
          </Text>
          <Box textAlign="justify" fontSize={{ lg: 'md', base: 'sm' }}>
            {parse(articleData?.content || '', options)}
          </Box>
          <Divider paddingTop="20px" />
        </Box>
        <Box w={{ md: '20%' }} fontSize={{ lg: 'md', base: 'sm' }}>
          <VStack align="start">
            <HStack>
              <Image src={ICONS.author} />
              <Text color={GREY}>{articleData?.author}</Text>
            </HStack>
            <HStack>
              <Image src={ICONS.date} />
              <Text color={GREY}>
                {dayjs(articleData?.createdAt).format('DD/MM/YYYY')}
              </Text>
            </HStack>
            <HStack>
              <Image src={ICONS.category} />
              <Text color={GREY}>{articleData?.categories?.join(', ')}</Text>
            </HStack>
            {articleData?.subCategories &&
              articleData?.subCategories?.length > 0 &&
              articleData?.subCategories[0] !== '' && (
                <HStack>
                  <Image src={ICONS.subCategory} />
                  <Text color={GREY}>
                    {articleData?.subCategories?.join(', ')}
                  </Text>
                </HStack>
              )}
            <HStack>
              <Image src={ICONS.view} />
              <Text color={GREY}>{articleData?.views} pembaca</Text>
            </HStack>
            {/* <HStack>
              <Image src={ICONS.like} />
              <Text color={GREY}>{articleData?.likes} penyuka</Text>
            </HStack>
            {loginInfo.isLogin && (
              <CustomButton
                w="100%"
                text={isUserLike ? 'DISUKAI' : 'SUKAI'}
                icon="like"
                variant={isUserLike ? 'red' : 'white'}
                onClick={handleButtonClick}
              />
            )} */}
          </VStack>
        </Box>
      </Stack>
    </Box>
  );
};

export default ArticleDetail;
