import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import CryptoJS from 'crypto-js';

import {
  Box,
  VStack,
  Image,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Button,
  Link,
  HStack,
  useToast,
  CloseButton,
  Spacer,
} from '@chakra-ui/react';

import { EmailIcon, LockIcon } from '@chakra-ui/icons';

import imgLogo from '@assets/logo.svg';

import { BLACK, RED } from '@constants/colors';
import {
  AUTH,
  FORGOT_PASSWORD,
  SIGN_UP,
  MEMBER_DASHBOARD,
  BERANDA,
  OTP,
} from '@constants/urls';

import CustomButton from '@components/Button';

import { useGlobalState } from '@contexts/globalState';

import fetchRequest from '@utils/fetcher';

const LoginCard = () => {
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const cookies = new Cookies();

  const toast = useToast();

  const { setLoginInfo, setSignUpInfo } = useGlobalState();

  const navigate = useNavigate();

  const handleLogin = async () => {
    const bodyParam = {
      email,
      password,
      userGroup: 'MEMBER',
    };

    setSignUpInfo({
      email,
      password,
    });

    try {
      await fetchRequest({
        method: 'POST',
        path: 'auth/authenticate',
        data: bodyParam,
      }).then((response) => {
        if (response.authData.refreshToken) {
          const exp = response.authData.accessToken.payload.exp;

          const encryptedEmail = CryptoJS.AES.encrypt(
            email,
            'user-email'
          ).toString();

          setLoginInfo({
            isLogin: true,
            username: response.userData.name,
            email,
            refreshToken: response.authData.refreshToken.token,
            expireAt: new Date(exp * 1000),
          });
          cookies.set('member-token', response.authData.accessToken.jwtToken, {
            path: '/',
            expires: new Date(exp * 1000),
          });
          cookies.set('member-r-token', response.authData.refreshToken.token, {
            path: '/',
          });
          cookies.set('UE', encryptedEmail, {
            path: '/',
          });

          navigate(MEMBER_DASHBOARD);
        } else if (response.statusCode && response.statusCode !== 201) {
          if (response.error === 'UserNotConfirmedException') {
            navigate(AUTH + OTP);
          } else {
            toast({
              title: 'Error',
              description: response.message,
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
          }
        }
      });
    } catch (error: any) {
      if (error.error === 'UserNotConfirmedException') {
        navigate(AUTH + OTP);
      } else {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box w="100%">
      <VStack align="start">
        <HStack w={'100%'}>
        <Image
          src={imgLogo}
          w={{ base: '40%', lg: '50%' }}
          onClick={() => navigate(BERANDA)}
        />
        <Spacer />
        <CloseButton onClick={ () => navigate(-1)} display={{ md: 'none', base: 'inline'}}></CloseButton>
        </HStack>
        <Text
          className="fontBold"
          fontSize={{ lg: '40px', base: '24px' }}
          color={BLACK}
        >
          Selamat Datang
        </Text>
        <Text fontSize={{ lg: 'lg', base: 'sm' }}>
          Masukkan email dan password terdaftar untuk melanjutkan
        </Text>
        <Box w="100%" paddingTop={{ base: '10px', lg: '30px' }}>
          <Text className="fontSemiBold" fontSize={{ lg: 'md', base: 'sm' }}>
            Email
          </Text>
          <InputGroup paddingTop="5px">
            <InputLeftElement
              pointerEvents="none"
              children={<EmailIcon color="gray.300" />}
              paddingTop="5px"
            />
            <Input
              type="email"
              placeholder="Masukkan Email"
              _focus={{ boxShadow: 'none', borderColor: RED }}
              fontSize={{ lg: 'md', base: 'sm' }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
          <Text
            className="fontSemiBold"
            fontSize={{ lg: 'md', base: 'sm' }}
            paddingTop="10px"
          >
            Password
          </Text>
          <InputGroup paddingTop="5px">
            <InputLeftElement
              pointerEvents="none"
              children={<LockIcon color="gray.300" />}
              paddingTop="5px"
            />
            <Input
              type={showPassword ? 'text' : 'password'}
              placeholder="Masukkan Password"
              _focus={{ boxShadow: 'none', borderColor: RED }}
              fontSize={{ lg: 'md', base: 'sm' }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputRightElement width={showPassword ? '130px' : '65px'}>
              <Button
                h="2rem"
                size="sm"
                onClick={() => setShowPassword(!showPassword)}
                marginTop="10px"
                marginRight="5px"
                _focus={{ boxShadow: 'none' }}
                fontSize={{ lg: 'md', base: 'xs' }}
              >
                {showPassword ? 'SEMBUNYIKAN' : 'LIHAT'}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Box w="100%" textAlign="end" paddingTop="10px">
            <Text as="u" fontSize={{ lg: 'md', base: 'xs' }}>
              <Link href={AUTH + FORGOT_PASSWORD}>Lupa password?</Link>
            </Text>
          </Box>
          <Box w="100%" paddingTop="10px">
            <CustomButton
              text="MASUK"
              w="100%"
              variant="red"
              icon="none"
              wMobile="100%"
              onClick={handleLogin}
            />
          </Box>
          <Box w="100%" paddingTop={{ base: '20px', lg: '30px' }}>
            <VStack align={{ base: 'center', lg: 'start' }}>
              <HStack>
                <Text
                  className="fontSemiBold"
                  fontSize={{ lg: 'md', base: 'xs' }}
                >
                  Belum memiliki akun?
                </Text>
                <Text
                  className="fontSemiBold"
                  color={RED}
                  as="u"
                  fontSize={{ lg: 'md', base: 'xs' }}
                >
                  <Link href={AUTH + SIGN_UP}>Buat Akun</Link>
                </Text>
              </HStack>
            </VStack>
          </Box>
        </Box>
      </VStack>
    </Box>
  );
};

export default LoginCard;
