import React, { useEffect, useState } from 'react';

import {
  Box,
  Center,
  Container,
  HStack,
  Stack,
  Image,
  Text,
  Grid,
  GridItem,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Textarea,
} from '@chakra-ui/react';

import { PINK, RED, WHITE } from '@constants/colors';
import { SOCIAL_MEDIA_LOGO } from '@constants/logos';
import { FooterDataType } from '@customTypes/footer';
import fetchRequest from '@utils/fetcher';
import CustomButton from '@components/Button';
import illustContact from '@assets/contact/contact-illustration.svg';
import imgLocation from '@assets/location.svg';
import imgPhone from '@assets/phone.svg';
import imgMail from '@assets/mail.svg';
import imgFax from '@assets/fax.svg';
import inputMail from '@assets/contact/email-input.svg';
import inputSubject from '@assets/contact/subject-input.svg';
import inputName from '@assets/contact/name-input.svg';
import { FILTERS } from '@constants/logos';

const Contact = () => {
  const [data, setData] = useState<FooterDataType>();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [isEmailInvalid, setIsEmailInvalid] = useState(true);
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');

  const toast = useToast();

  const getContactData = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'contact' }).then(
        (response) => {
          setData(response);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getContactData();
  }, []);

  const handleEmail = (value: string) => {
    const isEmail = value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    setEmail(value);

    if (isEmail) {
      setIsEmailInvalid(false);
    } else {
      setIsEmailInvalid(true);
    }
  };

  const handleSend = async () => {
    const bodyParam = {
      name,
      email,
      message,
      subject,
    };

    try {
      await fetchRequest({
        method: 'POST',
        path: 'contact/message',
        data: bodyParam,
      }).then((response) => {
        toast({
          title: 'Success',
          description: response.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleGoTo = (platformName: string, accountName: string) => {
    switch (platformName) {
      case 'Facebook':
        window.open('https://www.facebook.com/astagatra');
        break;
      case 'Youtube':
        window.open('https://www.youtube.com/channel/UCgKLxv4A1vs_i1Wcb5baL1g');
        break;
      case 'Instagram':
        window.open('https://www.instagram.com/astagatra_institute/');
        break;
      case 'Twitter':
        window.open('https://www.twitter.com/astagatra');
        break;
      case 'Whatsapp':
        let waNumber = accountName.replace(/[^\w\s]/gi, '');
        window.open(`https://api.whatsapp.com/send?phone=${waNumber}&text=`);
        break;
      default:
        break;
    }
  };

  return (
    <Box w={'100%'}>
      <Container
        className={'fontSemiBold'}
        padding={{ md: '50px', base: '30px' }}
        fontSize={{ md: '40px', base: '24px' }}
        textAlign={'center'}
      >
        Kontak
      </Container>
      <Center h={{ base: '100%' }} paddingBottom={{ base: '5%' }}>
        <Stack
          gap={{ md: '100px', base: '0px' }}
          direction={{ md: 'row', base: 'column' }}
        >
          <Box w={'350px'} h={{ base: '100%' }}>
            <HStack>
              <Image
                src={imgLocation}
                filter={FILTERS['#8D0000']}
                marginBottom="auto"
              />
              <Divider w={'5px'} borderColor={'transparent'} />
              <Text
                fontSize={{ base: '16px', lg: '20px' }}
                as={'b'}
                textAlign={'justify'}
              >
                {data?.address}
              </Text>
            </HStack>
          </Box>
          <Box w={'300px'} h={{ base: '100%' }}>
            <HStack>
              <Image src={imgPhone} filter={FILTERS['#8D0000']} alt="Phone" />
              <Divider w={'5px'} borderColor={'transparent'} />
              <Text fontSize={{ base: '16px', lg: '20px' }} as={'b'}>
                {data?.phone}
              </Text>
            </HStack>
            <HStack>
              <Image src={imgMail} filter={FILTERS['#8D0000']} alt="Email" />
              <Divider w={'5px'} borderColor={'transparent'} />
              <Text fontSize={{ base: '16px', lg: '20px' }} as={'b'}>
                {data?.email}
              </Text>
            </HStack>
            {data?.socialMedia.map((sm, i) => {
              const logoName = sm.platformName;
              return (
                <HStack
                  key={i}
                  onClick={() => handleGoTo(sm.platformName, sm.accountName)}
                  _hover={{ cursor: 'pointer' }}
                >
                  <Image
                    src={SOCIAL_MEDIA_LOGO[logoName]}
                    alt={sm.platformName}
                    filter={FILTERS['#8D0000']}
                  />
                  <Divider w={'5px'} borderColor={'transparent'} />
                  <Text fontSize={{ base: '16px', lg: '20px' }} as={'b'}>
                    {sm.accountName}
                  </Text>
                </HStack>
              );
            })}
          </Box>
        </Stack>
      </Center>
      <Center>
        <Grid
          w={{ md: '800px', base: '100%' }}
          h={{ md: '460px' }}
          padding={'20px'}
          border={{ md: '2px' }}
          borderColor={{ md: PINK, base: WHITE }}
          borderRadius={{ md: 'lg' }}
          templateColumns={{ md: 'repeat(5, 1fr)' }}
        >
          <GridItem rowSpan={{ md: 4, base: 1 }} colSpan={{ md: 2, base: 3 }}>
            <Image src={illustContact} margin={'auto'}></Image>
          </GridItem>
          <GridItem rowSpan={{ md: 4, base: 1 }} colSpan={3}>
            <Text
              className={'fontSemiBold'}
              fontSize={{ md: '24px', base: '16px' }}
              align={{ md: 'left', base: 'center' }}
            >
              Tinggalkan pesan untuk kami
            </Text>
            <Divider h={'12px'}></Divider>
            <InputGroup>
              <InputLeftElement
                zIndex="0"
                pointerEvents="none"
                children={<Image src={inputMail} />}
              />
              <Input
                placeholder="Masukkan Email"
                value={email}
                isInvalid={isEmailInvalid}
                onChange={(e: any) => handleEmail(e.target.value)}
                _focus={{ boxShadow: 'none', borderColor: RED }}
                errorBorderColor={RED}
              ></Input>
            </InputGroup>
            <Divider h={'12px'}></Divider>
            <InputGroup>
              <InputLeftElement
                zIndex="0"
                pointerEvents="none"
                children={<Image src={inputSubject} />}
              />
              <Input
                placeholder="Masukkan Subjek"
                _focus={{ boxShadow: 'none', borderColor: RED }}
                errorBorderColor={RED}
                isInvalid={subject === ''}
                value={subject}
                onChange={(e: any) => setSubject(e.target.value)}
              ></Input>
            </InputGroup>
            <Divider h={'12px'}></Divider>
            <InputGroup>
              <InputLeftElement
                zIndex="0"
                pointerEvents="none"
                children={<Image src={inputName} w={'10px'} />}
              />
              <Input
                placeholder="Masukkan Nama"
                _focus={{ boxShadow: 'none', borderColor: RED }}
                errorBorderColor={RED}
                isInvalid={name === ''}
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              ></Input>
            </InputGroup>
          </GridItem>
          <GridItem rowSpan={2} colSpan={{ md: 5, base: 3 }}>
            <Stack direction={'column'}>
              <Textarea
                h={{ md: '100px', base: '80px' }}
                size={'lg'}
                placeholder="Tulis Pesan Anda"
                _focus={{ boxShadow: 'none', borderColor: RED }}
                errorBorderColor={RED}
                isInvalid={message === ''}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              ></Textarea>
              <CustomButton
                text={'Kirim'}
                variant={'red'}
                icon={'none'}
                w={'100%'}
                disabled={
                  message === '' ||
                  name === '' ||
                  subject === '' ||
                  isEmailInvalid
                }
                onClick={handleSend}
              ></CustomButton>
            </Stack>
          </GridItem>
        </Grid>
      </Center>
      <br />
    </Box>
  );
};

export default Contact;
