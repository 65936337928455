import imgAuthor from '@assets/info/rightSideBar/author.svg';
import imgCalendar from '@assets/info/rightSideBar/calendar.svg';
import imgBook from '@assets/info/rightSideBar/book.svg';
import imgPaper from '@assets/info/rightSideBar/paper.svg';
import imgView from '@assets/info/rightSideBar/view.svg';
import imgLike from '@assets/info/rightSideBar/like.svg';
import imgComment from '@assets/info/rightSideBar/comment.svg';
import imgDownload from '@assets/info/rightSideBar/download.svg';

export const ICONS = {
  author: imgAuthor,
  date: imgCalendar,
  category: imgBook,
  subCategory: imgPaper,
  view: imgView,
  like: imgLike,
  comment: imgComment,
  download: imgDownload,
};
