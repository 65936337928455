import React, { useState } from 'react';

import { Button, Image, useBreakpointValue } from '@chakra-ui/react';

import { RED, WHITE } from '@constants/colors';

import { ICONS, FILTERS } from './constants';

type Props = {
  text: string;
  variant: 'red' | 'white' | 'transparent';
  icon: 'none' | 'like' | 'download' | 'sort' | 'user' | 'filter';
  w: string;
  wMobile?: string;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
};

const CustomButton = ({
  text,
  variant,
  icon,
  w,
  wMobile,
  onClick,
  disabled,
}: Props) => {
  const buttonSize = useBreakpointValue({
    base: 'sm',
    md: 'md',
    lg: 'md',
  });

  const BG_COLOR = () => {
    switch (variant) {
      case 'red':
        return RED;
      case 'white':
        return WHITE;
      case 'transparent':
        return 'transparent';
      default:
        return RED;
    }
  };

  const HOVER_BG_COLOR = variant === 'red' ? WHITE : RED;
  const TEXT_COLOR = variant === 'red' ? WHITE : RED;
  const HOVER_TEXT_COLOR = variant === 'red' ? RED : WHITE;
  const BUTTON_VARIANT = variant === 'transparent' ? 'ghost' : 'solid';
  const BORDER_COLOR =
    variant === 'red' || variant === 'transparent' ? RED : WHITE;
  const HOVER_BORDER_BOLOR =
    variant === 'red' || variant === 'transparent' ? RED : WHITE;

  const WIDTH_DESKTOP = w !== '' ? w : 'auto';

  const [isHovered, setIsHovered] = useState(false);

  if (icon === 'none') {
    return (
      <Button
        variant={BUTTON_VARIANT}
        bg={BG_COLOR()}
        _hover={{
          bg: HOVER_BG_COLOR,
          color: HOVER_TEXT_COLOR,
          borderColor: HOVER_BORDER_BOLOR,
        }}
        size={buttonSize}
        _focus={{ boxShadow: 'none' }}
        color={TEXT_COLOR}
        borderColor={BORDER_COLOR}
        border="1px"
        fontSize={{ base: '10px', lg: '16px' }}
        onClick={onClick}
        disabled={disabled || false}
      >
        {text}
      </Button>
    );
  }
  return (
    <Button
      w={{ base: wMobile ?? 'auto', lg: WIDTH_DESKTOP }}
      variant={BUTTON_VARIANT}
      bg={BG_COLOR()}
      leftIcon={
        <Image
          src={ICONS[icon]}
          alt={icon}
          h={{ base: '14px', lg: '18px' }}
          filter={FILTERS[isHovered ? HOVER_TEXT_COLOR : TEXT_COLOR]}
        />
      }
      _hover={{
        bg: HOVER_BG_COLOR,
        color: HOVER_TEXT_COLOR,
        borderColor: HOVER_BORDER_BOLOR,
      }}
      _focus={{ boxShadow: 'none' }}
      color={TEXT_COLOR}
      borderColor={BORDER_COLOR}
      border="1px"
      fontSize={{ base: '10px', lg: '16px' }}
      size={buttonSize}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={disabled || false}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
