import React from 'react';

import { Button, Image, useDisclosure } from '@chakra-ui/react';

import imgSearch from '@assets/search.svg';

import Search from '@components/Search';

const SearchButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        variant="ghost"
        _hover={{ bg: 'transparent' }}
        _focus={{ boxShadow: 'none' }}
        onClick={onOpen}
      >
        <Image
          src={imgSearch}
          alt="Search"
          maxWidth={{ base: '20px', lg: '33px' }}
        />
      </Button>
      <Search isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default SearchButton;
