import React from 'react';
import {
  Text,
  Image,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { GREY } from '@constants/colors';
import { ALFProps } from '@customTypes/alf';
import { SEE_CV_TITLE } from '@constants/texts';

const ModalCV = (data: ALFProps) => {
  const { name, photo, content, position } = data;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box as="u" onClick={onOpen}>
        {SEE_CV_TITLE}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size={'5xl'} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Grid padding={'20px'} templateColumns="repeat(5, 1fr)" gap={4}>
              <GridItem colSpan={2}>
                <Image src={photo} />
              </GridItem>
              <GridItem
                rowSpan={4}
                colSpan={3}
                fontSize={'16px'}
                h={'580px'}
                paddingX={'20px'}
                overflowY={'scroll'}
                dangerouslySetInnerHTML={{ __html: content }}
              >
              </GridItem>
              <GridItem colSpan={2}>
                <Text className="fontSemiBold" fontSize={'24px'}>
                  {name}
                </Text>
                <Text fontSize={'18px'} color={GREY}>
                  {position}
                </Text>
              </GridItem>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalCV;
