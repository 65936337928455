import { Center, Box, CloseButton } from '@chakra-ui/react';

import imgBackground from '@assets/member/background.svg';

import { RED, WHITE } from '@constants/colors';

import SignUpCard from './SignUpCard';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {

  const navigate = useNavigate();

  return (
    <Box
      w="100vw"
      h="100vh"
      bg={RED}
      backgroundImage={`url(${imgBackground})`}
      backgroundRepeat="no-repeat"
      backgroundSize="100%"
      backgroundPosition="bottom"
    >
      <Center w="100%" h="100%">
        <CloseButton position={'absolute'} right={'5px'} top={'10px'} color={WHITE} display={{ md: 'inline', base: 'none'}} onClick={ () => navigate(-2)}></CloseButton>
        <Box
          w={{ base: '80vw', lg: '31vw' }}
          bg={WHITE}
          borderRadius="lg"
          padding="20px"
        >
          <SignUpCard />
        </Box>
      </Center>
    </Box>
  );
};

export default SignUp;
