import imgDownload from '@assets/button/download.svg';
import imgLike from '@assets/button/like.svg';
import imgSort from '@assets/button/sort.svg';
import imgUser from '@assets/button/user.svg';
import imgFilter from '@assets/button/filter.svg';

export const ICONS = {
  like: imgLike,
  download: imgDownload,
  sort: imgSort,
  user: imgUser,
  filter: imgFilter,
};

export const FILTERS = {
  '#8D0000':
    'invert(14%) sepia(60%) saturate(4329%) hue-rotate(353deg) brightness(82%) contrast(121%)',
  '#FFFFFF':
    'invert(100%) sepia(100%) saturate(38%) hue-rotate(321deg) brightness(110%) contrast(110%)',
};
