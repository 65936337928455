import { useEffect } from 'react';
import { useLocation } from 'react-router';

const Newsletter = () => {
  const location = useLocation();

  const token = location.search.split('=')[1];

  const handleHitAPI = async () => {
    try {
      let url =
        process.env.NODE_ENV == 'production'
          ? `https://api.astagatra.com${
              location.pathname
            }?token=${encodeURIComponent(token)}`
          : `http://localhost:3000${location.pathname}${location.search}`;

      let response;

      const standardHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };

      const headers = standardHeaders;

      await fetch(url, {
        method: 'PATCH',
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    handleHitAPI();
  }, []);

  return <p>Anda telah berhasil berhenti berlangganan newsletter.</p>;
};

export default Newsletter;
