import { useNavigate } from 'react-router-dom';

import { Box, HStack, VStack, Text, Flex, Spacer } from '@chakra-ui/react';

import { BLACK, LIGHTGREY, RED } from '@constants/colors';

import { ReadingHistoryProps } from '@customTypes/member';

import CustomButton from '@components/Button';

const INFO_TYPE = {
  article: 'ARTIKEL',
  news: 'BERITA',
  publication: 'PUBLIKASI',
};

const ReadingHistoryCard = (props: ReadingHistoryProps) => {
  const { queryKey, title, type } = props;

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/service/' + type + '/' + encodeURIComponent(queryKey));
  };

  return (
    <Box w="100%" bg={LIGHTGREY} borderRadius="lg" marginBottom="10px">
      <HStack
        align="start"
        justifyContent="space-between"
        padding="20px"
        alignItems="center"
      >
        <VStack align="start" w="100%">
          <Text as="b" color={RED} fontSize="sm">
            {INFO_TYPE[type]}
          </Text>
          <Flex w="100%">
            <Text as="b" fontSize="sm" color={BLACK} w="70%">
              {title}
            </Text>
            <Spacer></Spacer>
            <CustomButton
              text="BACA LAGI"
              variant="transparent"
              icon="none"
              w="100px"
              onClick={handleButtonClick}
            />
          </Flex>
        </VStack>
      </HStack>
    </Box>
  );
};

export default ReadingHistoryCard;
