import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  VStack,
  Text,
  Box,
  Tag,
  HStack,
} from "@chakra-ui/react";

import CustomButton from "@components/Button";

import { PINK, RED } from "@constants/colors";

import { EventProps } from "@customTypes/event";

import { EVENT_TYPE } from "./constants";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  data: EventProps;
  handleJoin: () => void;
};

const DetailModal = ({ isOpen, onClose, data, handleJoin }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}>
      <ModalOverlay />
      <ModalContent marginX="4">
        <ModalHeader>DETAIL ACARA</ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: "none" }} />
        <ModalBody>
          <VStack align="start">
            <Box w="100%">
              <Text as="b" fontSize="md">
                Nama Acara
              </Text>
              <Text fontSize="md">{data.name}</Text>
            </Box>
            <Box w="100%" paddingTop="20px">
              <Text as="b" fontSize="md">
                Kegiatan
              </Text>
              <Text>
                <Tag bg={PINK} color={RED} fontSize="md" marginTop="5px">
                  {EVENT_TYPE[data.type]}
                </Tag>
              </Text>
            </Box>
            <Box w="100%" paddingTop="20px">
              <HStack alignContent="space-between" textAlign="start">
                <VStack align="start" w="100%">
                  <Text as="b" fontSize="md">
                    Waktu
                  </Text>
                  <Text fontSize="md">{data.time}</Text>
                </VStack>
                <VStack align="start" w="100%">
                  <Text as="b" fontSize="md">
                    Tanggal
                  </Text>
                  <Text fontSize="md">{data.date}</Text>
                </VStack>
              </HStack>
            </Box>
            <Box w="100%" paddingTop="20px">
              <Text as="b" fontSize="md">
                Deskripsi Acara
              </Text>
              <Text paddingX="20px" fontSize="md" dangerouslySetInnerHTML={{ __html: data.content }}></Text>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <CustomButton text="IKUTI" variant="red" icon="none" w="100%" onClick={handleJoin} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DetailModal;
