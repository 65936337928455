import React, { ReactElement, useState, useEffect } from "react";
import { useNavigate } from "react-router";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Grid,
  GridItem,
  Image,
  Center,
  Input,
  Box,
  Divider,
  VStack,
  Text,
  HStack,
  Stack,
  Skeleton,
  SkeletonText,
} from "@chakra-ui/react";

import Filter from "./Filter";

import imgSearch from "@assets/search/search.svg";

import { BLACK, LIGHTGREY, RED } from "@constants/colors";
import { ARTIKEL, BERITA, PUBLIKASI } from "@constants/urls";

import fetchRequest from "@utils/fetcher";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type SearchProps = {
  title: string;
  linkTo: string;
  type: "Publikasi" | "Artikel" | "Berita" | "Halaman";
};

type SkeletonProps = {
  numberOfCard: number;
  isLoaded: boolean;
};

const SearchSkeleton: Function = ({ numberOfCard, isLoaded }: SkeletonProps) => {
  let result: ReactElement[] = [];
  for (let i = 0; i < numberOfCard; i++) {
    result.push(
      <Box display={isLoaded ? "none" : "flex"} key={i} bg={LIGHTGREY} w="100%" paddingY={"12px"} paddingX={"16px"} borderRadius="lg">
        <VStack spacing={0} w="100%" align="start" margin="auto">
          <Skeleton w="20" height="12px" marginBottom={4} isLoaded={isLoaded} />
          <SkeletonText w="100%" skeletonHeight="12px" noOfLines={2} isLoaded={isLoaded} />
        </VStack>
      </Box>
    );
  }
  return result;
};

const Search = ({ isOpen, onClose }: Props) => {
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("All");
  const [isHovered, setIsHovered] = useState<Record<string, boolean>>({});
  const [searchData, setSearchData] = useState<SearchProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleFilterChange = (ct: string) => {
    setCategory(ct);
    getSearchData(ct, keyword);
  };

  const handleSearch = (kw: string) => {
    setIsLoading(true);
    setKeyword(kw);
    if (kw === "") {
      setSearchData([]);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (keyword !== "") {
        getSearchData(category, keyword);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [keyword]);

  const getSearchData = async (c: string, k: string) => {
    try {
      await fetchRequest({
        method: "GET",
        path: "search?types=" + c + "&query=" + k,
      }).then((response) => {
        if (Array.isArray(response)) {
          setSearchData(response || []);
        }
        setIsLoading(false);
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleClick = (type: string, linkTo: string) => {
    const lt = linkTo.slice(1);
    setKeyword("");
    setCategory("All");
    switch (type) {
      case "Artikel":
        navigate(ARTIKEL + "/" + encodeURIComponent(lt));
        onClose();
        return;
      case "Berita":
        navigate(BERITA + "/" + encodeURIComponent(lt));
        onClose();
        return;
      case "Publikasi":
        navigate(PUBLIKASI + "/" + encodeURIComponent(lt));
        onClose();
        return;
      case "Halaman":
        navigate(lt);
        onClose();
        return;
      default:
        return;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick size="2xl">
      <ModalOverlay />
      <ModalContent background={"none"} shadow={"none"} marginX={4}>
        <ModalBody padding={0} margin={0}>
          <Stack direction={"row"} background={"white"} padding={"12.5px"} borderRadius="lg" shadow={"lg"}>
            <Center display={{ base: "none", lg: "inherit" }}>
              <Image src={imgSearch} alt="Search" width={"24px"} marginX={3} />
            </Center>
            <Center>
              <Filter selectedCategory={handleFilterChange} />
            </Center>
            <Center w={"100%"} h={"100%"}>
              <Input
                value={keyword}
                placeholder="Ketik kata kunci yang diinginkan..."
                border={"none"}
                width={"100%"}
                onChange={(e: any) => handleSearch(e.target.value)}
                _focus={{ boxShadow: "none" }}
              />
            </Center>
          </Stack>
        </ModalBody>
        {keyword && (
          <ModalFooter marginTop={6} background={"white"} padding={"15px"} borderRadius="lg" shadow={"lg"}>
            <VStack spacing={2} w="100%" align="start" margin="auto">
              <SearchSkeleton isLoaded={!isLoading} numberOfCard={3} />
              <Center w="100%" display={searchData.length == 0 && !isLoading ? "inherit" : "none"}>
                Kata kunci yang anda masukkan tidak cocok dengan dokumen apapun
              </Center>
              {searchData.map((sd) => (
                <Box
                  display={!isLoading ? "flex" : "none"}
                  bg={LIGHTGREY}
                  w="100%"
                  paddingY={"12px"}
                  paddingX={"16px"}
                  borderRadius="lg"
                  _hover={{ bg: RED, cursor: "pointer" }}
                  onMouseOver={() =>
                    setIsHovered({
                      [sd.linkTo]: true,
                    })
                  }
                  onMouseLeave={() =>
                    setIsHovered({
                      [sd.linkTo]: false,
                    })
                  }
                  onClick={() => handleClick(sd.type, sd.linkTo)}
                >
                  <VStack spacing={0} w="100%" align="start" margin="auto">
                    <Text fontSize="sm" as="b" color={isHovered[sd.linkTo] ? "white" : RED} margin={0}>
                      {sd.type}
                    </Text>
                    <Text fontSize="md" color={isHovered[sd.linkTo] ? "white" : BLACK} margin={0}>
                      {sd.title}
                    </Text>
                  </VStack>
                </Box>
              ))}
            </VStack>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default Search;
