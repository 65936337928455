import {
  Box,
  Center,
  Text,
  Image,
  Wrap,
  WrapItem,
  Grid,
  GridItem,
  Flex,
  Divider,
  OrderedList,
  ListItem,
} from '@chakra-ui/react';
import {
  CORPORATION_CONSULTATION_TITLE,
  CONSULT_EXCECUTIVE_TITLE,
  CORPORATION_CONSULT_EXCECUTIVE_DESC,
  CONSULT_EXCECUTIVE_FIELD_TITLE,
  CORPORATION_CONSULT_EXCECUTIVE_FIELD,
  CORPORATE_BENEFIT,
} from '@constants/texts';
import { BLACK, LIGHTGREY, RED } from '@constants/colors';
import CONSULT_ILLUS from '@assets/service/research/consult-illustration.svg';
import { BenefitProps, ItemProps } from '@customTypes/service';

interface Props {
  desc: string;
  item: ItemProps[];
  benefit: BenefitProps;
}

const CorporateConsultation = (props: Props) => {
  const { desc, item, benefit } = props;
  return (
    <>
      <Center>
        <br />
        <Box w={{ base: '100%' }}>
          <Center>
            <Text
              className={'fontBold'}
              fontSize={{ md: '28px', base: '18px' }}
              color={BLACK}
            >
              {CORPORATION_CONSULTATION_TITLE}
            </Text>
          </Center>
          <Box bg={LIGHTGREY} marginY={'30px'} borderRadius={'lg'}>
            <Grid templateColumns="repeat(3, 1fr)" gap={2}>
              <GridItem colSpan={{ base: 3, lg: 1 }}>
                <Center w="100%" h={{ lg: '100%' }}>
                  <Image
                    src={benefit.icon || CONSULT_ILLUS}
                    objectFit="scale-down"
                    padding="20px"
                  />
                </Center>
              </GridItem>
              <GridItem colSpan={{ base: 3, lg: 2 }}>
                <Flex
                  h={'100%'}
                  flexDirection={'column'}
                  justify={'center'}
                  align={{ lg: 'start', base: 'center' }}
                  padding={'20px'}
                >
                  <Text fontSize={{ md: '20px', base: '16px' }} as="b">
                  Keuntungan dan Manfaat
                  </Text>
                  <Divider h={'5px'} borderColor={'transparent'}></Divider>
                  <OrderedList
                    fontSize={{ md: '18px', base: '12px' }}
                    className={'fontRegular'}
                    textAlign={'justify'}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: benefit.content || '',
                      }}
                    />
                  </OrderedList>
                </Flex>
              </GridItem>
            </Grid>
          </Box>
          <Box>
            <Text
              className={'fontSemiBold'}
              fontSize={{ md: '24px', base: '16px' }}
              paddingTop="30px"
              paddingBottom="30px"
              color={BLACK}
            >
              Konsultasi Pengembangan Bisnis Perusahaan
            </Text>
            <Text
              className={'fontRegular'}
              fontSize={{ md: '18px', base: '14px' }}
              color={BLACK}
              textAlign={'justify'}
            >
              {desc}
            </Text>
          </Box>
          <Box>
            <Text
              className={'fontSemiBold'}
              fontSize={{ md: '24px', base: '16px' }}
              paddingTop="30px"
              paddingBottom="30px"
              color={BLACK}
            >
              {CONSULT_EXCECUTIVE_FIELD_TITLE}
            </Text>
            <Wrap w={'100%'} spacing={'20px'} justify={'center'}>
              {item.map((it, i) => (
                <WrapItem key={i}>
                  <Center
                   h={{ xl: '150px', base: '100%' }}
                   bg={RED}
                   borderRadius={'lg'}
                   color={'white'}
                   textAlign={'center'}
                   padding={{ md: '20px', base: '10px' }}
                   flexDirection={'column'}
                  >
                    <Text
                      className={'fontBold'}
                      fontSize={{ md: '20px', base: '16px' }}
                    >
                      {it.title}
                    </Text>
                    <Divider h={'15px'} borderColor={'transparent'}></Divider>
                    <Text
                      className={'fontRegular'}
                      fontSize={{ md: '18px', base: '12px' }}
                    >
                      {it.content}
                    </Text>
                  </Center>
                </WrapItem>
              ))}
            </Wrap>
          </Box>
        </Box>
      </Center>
    </>
  );
};

export default CorporateConsultation;
