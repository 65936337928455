import { useState, useEffect } from 'react';

import {
  Grid,
  GridItem,
  Box,
  Stack,
  Text,
  useDisclosure,
  VStack,
  Center,
} from '@chakra-ui/react';

import fetchRequest from '@utils/fetcher';

import type {
  MemberDashboardProps,
  MemberDataProps,
} from '@customTypes/member';

import EventTable from '@components/EventTable';

import { PINK } from '@constants/colors';

import SideBar from './SideBar';
import NotActiveCard from './NotActiveCard';
import ReadingHistoryCard from './ReadingHistoryCard';

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState<MemberDashboardProps>();
  const [errorStatus, setErrorStatus] = useState('');
  const [memberData, setMemberData] = useState<Partial<MemberDataProps>>();

  const fetchMemberData = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'member/dashboard' }).then(
        (response) => {
          if (response.code) {
            setErrorStatus(response.code);
            setMemberData(response.userData);
          } else {
            setDashboardData(response);
            setErrorStatus('');
          }
        }
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    fetchMemberData();
  }, []);

  return (
    <Box w={{ lg: '100%', base: '99%' }}>
      <Stack
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop={{ base: '20px', lg: '50px' }}
      >
        <Box w={{ md: '20%', base: '100%' }} h="400px">
          <SideBar
            memberData={dashboardData?.memberData as Partial<MemberDataProps> || memberData }
            isError={errorStatus !== ''}
          />
        </Box>
        <Box w={{ lg: '100%', base: '99%' }}>
          {errorStatus ? (
            <Center w={{ lg: '100%', base: '99%' }} h="100%">
              <Box w={{ base: '90%', lg: '60%' }}>
                <NotActiveCard status={errorStatus} />
              </Box>
            </Center>
          ) : (
            <>
            <Box w="100%" >
                <Text as="b" fontSize="2xl">
                  Acara
                </Text>
                <Box
                  w={{ base: '90vw', lg: '100%' }}
                  marginTop="20px"
                  border="solid 1px"
                  borderColor={PINK}
                  borderRadius="lg"
                  overflowX="scroll"
                >
                  <EventTable data={dashboardData?.events.dataList || []} />
                </Box>
              </Box>
              <Box w={{ base: '99%', lg: '100%' }} paddingTop="30px">
                <Text as="b" fontSize="2xl">
                  Riwayat Bacaan
                </Text>
                <Box w="100%" paddingTop={{ base: '10px', lg: '20px' }}>
                  {dashboardData?.readHistories.map((history, i) => (
                    <ReadingHistoryCard key={i} {...history} />
                  ))}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default Dashboard;
