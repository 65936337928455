import {
  ACARA,
  ALF,
  ARTIKEL,
  BERITA,
  GALERI,
  KAJIAN_DAN_KONSULTASI,
  KONSULTASI_KORPORASI,
  KONSULTASI_PUBLIK,
  MANAJEMEN,
  MEMBERSHIP,
  PELATIHAN,
  PUBLIKASI,
  TENTANG_KAMI,
  VISI_MISI,
  WEBINAR,
  MEMBER_DASHBOARD,
  MEMBER_SETTING,
} from '../urls';

export const BREADCRUMB_TENTANG_KAMI = [
  {
    name: 'Tentang Kami',
    url: TENTANG_KAMI,
  },
];

export const BREADCRUMB_MANAJEMEN = [
  ...BREADCRUMB_TENTANG_KAMI,
  {
    name: 'Manajemen',
    url: MANAJEMEN,
  },
];

export const BREADCRUMB_ALF = [
  ...BREADCRUMB_TENTANG_KAMI,
  {
    name: 'Astagatra Leaders Forum',
    url: ALF,
  },
];

export const BREADCRUMB_MEMBERSHIP = [
  ...BREADCRUMB_TENTANG_KAMI,
  {
    name: 'Keanggotaan',
    url: MEMBERSHIP,
  },
];

export const BREADCRUMB_VISI_MISI = [
  ...BREADCRUMB_TENTANG_KAMI,
  {
    name: 'Visi Misi',
    url: VISI_MISI,
  },
];

export const BREADCRUMB_PRODUK_KAMI = [
  {
    name: 'Produk Kami',
    url: '',
  },
];

export const BREADCRUMB_KAJIAN_DAN_KONSULTASI = [
  ...BREADCRUMB_PRODUK_KAMI,
  {
    name: 'Kajian dan Konsultasi',
    url: KAJIAN_DAN_KONSULTASI,
  },
];

export const BREADCRUMB_KONSULTASI_PUBLIK = [
  ...BREADCRUMB_KAJIAN_DAN_KONSULTASI,
  {
    name: 'Konsultasi Organisasi Publik',
    url: KONSULTASI_PUBLIK,
  },
];

export const BREADCRUMB_KONSULTASI_KORPORASI = [
  ...BREADCRUMB_KAJIAN_DAN_KONSULTASI,
  {
    name: 'Konsultasi Strategis',
    url: KONSULTASI_KORPORASI,
  },
];

export const BREADCRUMB_PELATIHAN = [
  ...BREADCRUMB_PRODUK_KAMI,
  {
    name: 'Pelatihan',
    url: PELATIHAN,
  },
];

export const BREADCRUMB_WEBINAR = [
  ...BREADCRUMB_PRODUK_KAMI,
  {
    name: 'Publishing & Report',
    url: WEBINAR,
  },
];

export const BREADCRUMB_LAYANAN = [
  {
    name: 'Layanan',
    url: '',
  },
];

export const BREADCRUMB_ARTIKEL = [
  ...BREADCRUMB_LAYANAN,
  {
    name: 'Artikel',
    url: ARTIKEL,
  },
];

export const BREADCRUMB_PUBLIKASI = [
  ...BREADCRUMB_LAYANAN,
  {
    name: 'Publikasi',
    url: PUBLIKASI,
  },
];

export const BREADCRUMB_BERITA = [
  ...BREADCRUMB_LAYANAN,
  {
    name: 'Berita',
    url: BERITA,
  },
];

export const BREADCRUMB_ACARA = [
  ...BREADCRUMB_LAYANAN,
  {
    name: 'Acara',
    url: ACARA,
  },
];

export const BREADCRUMB_GALERI = [
  ...BREADCRUMB_LAYANAN,
  {
    name: 'Galeri',
    url: GALERI,
  },
];

export const BREADCRUMB_DASHBOARD = [
  {
    name: 'Dashboard',
    url: MEMBER_DASHBOARD,
  },
];

export const BREADCRUMB_SERTIFIKAT = [
  ...BREADCRUMB_DASHBOARD,
  {
    name: 'Sertifikat',
    url: '',
  },
];

export const BREADCRUMB_PENGATURAN = [
  ...BREADCRUMB_DASHBOARD,
  {
    name: 'Pengaturan',
    url: MEMBER_SETTING,
  },
];

export const BREADCRUMB_UBAH_PASSWORD = [
  ...BREADCRUMB_PENGATURAN,
  {
    name: 'Ubah Password',
    url: '',
  },
];
