import { useState } from 'react';
import { useNavigate } from 'react-router';

import {
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  HStack,
  Tag,
  useDisclosure,
} from '@chakra-ui/react';

import type { EventProps } from '@customTypes/event';

import CustomButton from '@components/Button';

import { PINK, RED } from '@constants/colors';

import { useGlobalState } from '@contexts/globalState';

import DetailModal from './DetailModal';
import { EVENT_TYPE } from './constants';
import { AUTH, LOGIN, MEMBER_DASHBOARD } from '@constants/urls';

type Props = {
  data: EventProps[];
  needLogin?: boolean;
};

const EventTable = ({ data, needLogin = false }: Props) => {
  const TABLE_HEADER = ['Nama Acara', 'Kegiatan', 'Tanggal', 'Waktu', 'Aksi'];

  const navigate = useNavigate();

  const [isDetailOpen, setIsDetailOpen] = useState<Record<number, boolean>>({});

  const { loginInfo } = useGlobalState();

  const handleDetailButtonClick = (id: number) => {
    const currentModal = isDetailOpen[id];
    if (typeof currentModal === 'undefined') {
      setIsDetailOpen({
        [id]: true,
      });
    } else {
      setIsDetailOpen({
        [id]: !currentModal,
      });
    }
  };

  const handleJoinButtonClick = (link: string = '') => {
    if (needLogin) {
      if (!loginInfo.isLogin) {
        navigate(AUTH + LOGIN);
      } else {
        navigate(MEMBER_DASHBOARD);
      }
    } else {
      if (link.length > 0) {
        let newLink = link;
        if (!newLink.match('//')) {
          newLink = '//' + link;
        }
        window.open(newLink, '_blank');
      }
    }
  };

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          {TABLE_HEADER.map((header, i) => (
            <Th key={i} bg={PINK} color={RED}>
              {header}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {data.length > 0 &&
          data.map((d, i) => (
            <Tr key={i}>
              <Td>{d.name}</Td>
              <Td>
                <Tag variant="solid" bg={PINK} color={RED}>
                  {EVENT_TYPE[d.type]}
                </Tag>
              </Td>
              <Td w={{ base:'15%'}}>{d.date}</Td>
              <Td>{d.time}</Td>
              <Td>
                <HStack gap={2}>
                  <CustomButton
                    text="DETAIL"
                    icon="none"
                    variant="white"
                    w="80px"
                    onClick={() => handleDetailButtonClick(i)}
                  />
                  <DetailModal
                    isOpen={isDetailOpen[i]}
                    onClose={() => handleDetailButtonClick(i)}
                    data={d}
                    handleJoin={() => handleJoinButtonClick(d.link)}
                  />
                  <CustomButton
                    text="IKUTI"
                    icon="none"
                    variant="red"
                    w="80px"
                    onClick={() => handleJoinButtonClick(d.link)}
                  />
                </HStack>
              </Td>
            </Tr>
          ))}
      </Tbody>
    </Table>
  );
};

export default EventTable;
