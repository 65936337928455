import React, { useEffect, useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { Box, Center, Text } from '@chakra-ui/react';
import { BLACK, LIGHTGREY, PINK } from '@constants/colors';
import { BREADCRUMB_MANAJEMEN } from '@constants/breakcrumbs';

import CustomBreadcrumb from '@components/CustomBreadcrumb';
import TreeComponent from './TreeComponent';
import { BREAKPOINTS } from '@constants/breakpoints';
import { ALFProps } from '@customTypes/alf';
import fetchRequest from '@utils/fetcher';

const Manajemen = () => {
  const [data, setData] = useState<ALFProps[]>([]);

  const getData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'about-us/management',
      }).then((response) => {
        setData(response.management);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box w={{ base: '100%' }}>
      <Box
        h="30px"
        paddingLeft={{ base: '10px', lg: '3%' }}
        paddingRight={{ base: '10px' }}
        w="100%"
      >
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_MANAJEMEN} />
      </Box>
      <Box w={{ base: '100%' }}>
        <Text
          className={'fontSemiBold'}
          fontSize={{ xl: '40px', lg: '32px', md: '24px' }}
          color={BLACK}
          align={'center'}
        >
          Manajemen
        </Text>
        <br />
        <Box
          w={{ md: '100%', base: BREAKPOINTS.sm }}
          h={{ base: '100%' }}
          overflowX={'scroll'}
        >
          <Tree
            lineWidth={'2px'}
            lineHeight={'36px'}
            lineColor={PINK}
            label={
              <Center
                className={'fontSemiBold'}
                w={{ lg: '250px', base: '180px' }}
                h={{ lg: '100px', base: '80px' }}
                backgroundColor={LIGHTGREY}
                marginX={'auto'}
                fontSize={{ lg: '18px', base: '13px' }}
              >
                Board of Commissioner
              </Center>
            }
          >
            {data.length > 0 && (
              <TreeNode
                label={
                  <TreeComponent
                    name={data[0].name || ''}
                    position={data[0].position || ''}
                    content={data[0].content || ''}
                    photo={data[0].photo || ''}
                  ></TreeComponent>
                }
              >
                {data
                  .filter((_, i) => i !== 0)
                  .map((m, i) => {
                    return (
                      <TreeNode
                        key={i}
                        label={
                          <TreeComponent
                            name={m.name || ''}
                            position={m.position || ''}
                            content={m.content || ''}
                            photo={m.photo || ''}
                          ></TreeComponent>
                        }
                      />
                    );
                  })}
              </TreeNode>
            )}
          </Tree>
        </Box>
      </Box>
      <Box h={'100px'}></Box>
    </Box>
  );
};

export default Manajemen;
