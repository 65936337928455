import { useNavigate } from 'react-router-dom';

import {
  useDisclosure,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  Button,
} from '@chakra-ui/react';

import { BLACK, GOLD, RED, WHITE } from '@constants/colors';

import type { MenuProps } from 'customTypes/navbar';

type Props = {
  menu: MenuProps;
};

const NavbarMenu = ({ menu }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const handleSubMenuClicked = (url: string) => {
    navigate(url);
  };

  const isUrlMatch = (url: string) => {
    if (!url) return false;
    if (url === '/') return window.location.pathname === '/';
    return window.location.pathname.includes(url);
  };

  return (
    <Menu isOpen={isOpen}>
      <MenuButton
        as={Button}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        onClick={() => menu.pageExist && handleSubMenuClicked(menu.url)}
        variant="ghost"
        color={isUrlMatch(menu.url) ? RED : BLACK}
        fontWeight="black"
        _hover={{ bg: 'transparent' }}
        _focus={{ boxShadow: 'none' }}
        _active={{ bg: 'transparent' }}
      >
        {menu.name.toUpperCase()}
      </MenuButton>
      {menu.subMenu.length > 0 && (
        <MenuList
          margin={0}
          width="auto"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          paddingY={0}
          borderRadius={0}
          border="none"
          shadow={'lg'}
          borderTop={`4px solid ${GOLD}`}
        >
          {menu.subMenu.map((sm, i) => (
            <MenuItem
              key={i}
              height={'49px'}
              bg={isUrlMatch(sm.url) ? GOLD : WHITE}
              onClick={() => handleSubMenuClicked(sm.url)}
              fontWeight={isUrlMatch(sm.url) ? 'black' : 'medium'}
              _hover={{ bg: GOLD, fontWeight: 'black' }}
            >
              {sm.name.toUpperCase()}
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Menu>
  );
};

export default NavbarMenu;
