import { Cookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Grid,
  GridItem,
  VStack,
  Image,
  Text,
  Center,
  HStack,
  Button,
} from '@chakra-ui/react';

import { ChevronRightIcon, EmailIcon, PhoneIcon } from '@chakra-ui/icons';

import imgPhotoPlaceholder from '@assets/member/photo-placeholder.jpeg';
import imgCertificate from '@assets/member/certificate.svg';

import CustomButton from '@components/Button';

import { useGlobalState } from '@contexts/globalState';

import type { MemberDataProps } from '@customTypes/member';

import { GREY, LIGHTGREY } from '@constants/colors';
import { AUTH, LOGIN, MEMBER_SETTING, SERTIFIKAT } from '@constants/urls';

import fetchRequest from '@utils/fetcher';

type Props = {
  memberData: Partial<MemberDataProps> | undefined;
  isError?: boolean;
};

const SideBar = ({ memberData, isError = false }: Props) => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const { loginInfo, setLoginInfo } = useGlobalState();

  let userName = loginInfo.username;
  let userEmail = loginInfo.email;
  let userPhoto = imgPhotoPlaceholder;
  let userIsActive = false;
  let userPhoneNumber = '';

  if (memberData !== undefined) {
    const { name, email, photoUrl, isActive, phoneNumber } = memberData;

    userName = name || loginInfo.username;
    userEmail = email || loginInfo.email;
    userPhoto = photoUrl || imgPhotoPlaceholder;
    userIsActive = isActive || false;
    userPhoneNumber = phoneNumber || '';
  }

  const fetchLogout = async () => {
    try {
      await fetchRequest({ method: 'POST', path: 'member/logout' }).then(
        (response) => {
          if (response['$metadata']) {
            navigate(AUTH + LOGIN);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = () => {
    fetchLogout();

    cookies.remove('member-r-token', { path: '/' });
    cookies.remove('member-token', { path: '/' });
    cookies.remove('UE', { path: '/' });

    setLoginInfo({
      isLogin: false,
      username: '',
      email: '',
      refreshToken: '',
      expireAt: new Date(),
    });

    navigate(AUTH + LOGIN);
  };

  return (
    <Box w="100%">
      <VStack align="start">
        <Grid templateColumns="repeat(3,1fr)" gap={6}>
          <GridItem w="100%" h="80px">
            <Center w="100%" h="100%">
              <Box w="80px" h="80px">
                <Image
                  src={userPhoto}
                  borderRadius="full"
                  boxSize="80px"
                  alt={userName}
                  objectFit={'cover'}
                />
              </Box>
            </Center>
          </GridItem>
          <GridItem w="100%" h="80px" colSpan={2}>
            <VStack align="start">
              <Text as="b">{userName.toUpperCase()}</Text>
              {!isError && (
                <CustomButton
                  text="PENGATURAN"
                  icon="none"
                  w="100%"
                  variant="white"
                  onClick={() => navigate(MEMBER_SETTING)}
                />
              )}
            </VStack>
          </GridItem>
        </Grid>
        <Text as="b" paddingTop={{ base: '10px', lg: '20px' }}>
          Data Diri
        </Text>
        {userPhoneNumber && (
          <HStack>
            <PhoneIcon color={GREY} />
            <Text>{userPhoneNumber}</Text>
          </HStack>
        )}
        <HStack>
          <EmailIcon color={GREY} />
          <Text>{userEmail}</Text>
        </HStack>
        {userIsActive && (
          <>
            <Text as="b" paddingTop={{ base: '10px', lg: '20px' }}>
              Dokumen
            </Text>
            <Button
              rightIcon={<ChevronRightIcon color={GREY} />}
              w="100%"
              bg={LIGHTGREY}
              color={GREY}
              _focus={{ boxShadow: 'none' }}
              justifyContent="space-between"
              onClick={() => navigate(SERTIFIKAT)}
            >
              <HStack gap={2}>
                <Image src={imgCertificate} />
                <Text>Sertifikat</Text>
              </HStack>
            </Button>
          </>
        )}
        <Box w="100%" paddingTop={{ base: '10px', lg: '20px' }}>
          <CustomButton
            text="KELUAR"
            variant="red"
            icon="none"
            w="100%"
            wMobile="100%"
            onClick={handleLogout}
          />
        </Box>
      </VStack>
    </Box>
  );
};

export default SideBar;
