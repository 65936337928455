// PUBLIKASI




//
// HOMEPAGE
//
export const HOMEPAGE_TITLE = 'Astagatra Institute';
export const HOMEPAGE_DESC =
  'Lembaga kajian dan pengembangan yang fokus pada bidang kepemimpinan, manajemen, ekonomi, dan komunikasi berwawasan kebangsaan. ';
export const PRODUCT_TITLE = 'Produk Kami';
export const THREE_PRODUCT = [
  {
    title: 'Research and Consulting',
    desc: 'Memberikan layanan solusi melalui proses konsultasi dan pendampingan bersama para ahli di bidangnya. Layanan kajian dan konsultasi membantu organisasi baik di sektor publik dan swasta untuk meningkatkan nilai daya saing di bidang ekonomi, komunikasi, dan pengembangan organisasi.',
  },
  {
    title: 'Executive Learning',
    desc: 'Memberikan layanan berupa webinar, pelatihan, serta coaching yang berfokus pada tema kepemimpinan, manajemen, ekonomi, dan komunikasi. Layanan Executive Learning membantu individu maupun organisasi yang ingin mengembangkan kemampuan dalam bidang-bidang yang ditawarkan dan dilatih oleh pakar yang ahli dalam bidangnya.',
  },
  {
    title: 'Astagatra Leaders Forum',
    desc: 'Astagatra Leaders Forum merupakan sebuah forum diskusi dengan tema besar kepemimpinan, manajemen, ekonomi, komunikasi, dan isu-isu strategis terkini. Diskusi dilakukan oleh Board of Experts beranggotakan para ahli dari berbagai sektor dan fokusan bidang yang akan melahirkan artikel dan publikasi ilmiah.',
  },
];
export const DUMMY_NEWEST_POST_INFO = ['ARTIKEL TERBARU'];
export const DUMMY_NEWEST_POST = [
  {
    title: 'Membangun Optimisme Di Masa Pandemi',
    desc: 'Berbica terkait pandemi, situasi ini memaksa semua orang untuk dapat beradaptasi. Situasi ini menyebabkan kelelahan setelah 1,5 tahun  pandemi berlalu, COVID-19 yang menjadi musuh...',
  },
];
export const TESTIMONIAL_TITLE = 'Testimoni';
export const DUMMY_TESTIMONIAL = [
  {
    name: 'I Nyoman Suwirta, S.Pd, M.M',
    title: 'Bupati Klungkung',
    desc: 'Apresiasi terhadap kajian ini. Saya kira (hasil kajian) ini seperti hasil (taskap akhir) Lemhannas (Lembaga  Ketahanan Nasional Republik Indonesia)',
  },
  {
    name: 'I Gede Ngurah Patriana Krisna, S.T., M.T.',
    title: 'Wakil Bupati Jembrana',
    desc: 'Dengan (adanya) kegiatan ini, kita (Unsur Forkopimda) bisa mengambil pengalaman dari daerah lain maupun narasumber agar penanganan COVID-19 (di daerah) lebih tepat',
  },
  {
    name: 'drg. Agus Suprapto, M.Kes',
    title:
      'Deputi Bidang Koordinasi Peningkatan Kualitas Kesehatan dan Pembangunan Kependudukan, Kemenko PMK RI',
    desc: 'Apresiasi terhadap kajian ini. Saya kira (hasil kajian) ini seperti hasil (taskap akhir) Lemhannas (Lembaga  Ketahanan Nasional Republik Indonesia)',
  },
];

//
// Tentang Kami
//
export const ABOUT_US_TITLE = 'Tentang Kami';
export const HISTORY_TITLE = 'Sejarah';
export const HISTORY_DESC_1 =
  'Astagatra Institute merupakan lembaga kajian dan pengembangan yang terbentuk  pada tahun 2020 dari tiga orang alumni Lembaga Ketahanan Nasional Republik Indonesia (LEMHANNAS RI) Angkatan LX. Pendekatan desain kajian yang kami hasilkan berbasis kepada “modular build-up”, dimana skema tersebut dirancang untuk membangun suatu building blocks sebagai fondasi yang kokoh dalam rangka mendukung pembahasan secara menyeluruh yang berfokuskan pada bidang kajian kepemimpinan, manajemen, ekonomi, dan komunikasi.';
export const HISTORY_DESC_2 =
  'Kami menanamkan semangat kolaborasi dalam setiap proses pengembangan desain kajian. Oleh karena itu, kami telah bekerjasama dengan berbagai Kementerian/Lembaga dari berbagai sektor, seperti Kementerian Koordinator Bidang Ekonomi, Satuan Tugas COVID-19 Nasional, Badan Riset dan Inovasi Nasional, Universitas Indonesia, dan masing banyak lagi. Kerjasama tersebut tidak terlepas dari peranan jajaran founder kami yang berasal dari berbagai latar belakang profesi dan keahlian. Hal ini mendukung lembaga kami untuk memaksimalkan implementasi pendekatan pentahelix dalam pengembangan kajian.';
export const FOUNDERS_TITLE = 'Pendiri';
export const FOUNDERS = [
  {
    name: 'I Nyoman Gde Agus Asrama, S.Kom',
    title: 'Executive Director Astagatra Institute',
  },
  {
    name: 'Dr. Wahyu Tri Setyobudi, M.M.',
    title: 'Co-Founder Astagatra Institute',
  },
  {
    name: 'Dr. Lilik Sudarwati A., S.Psi, M.H.',
    title: 'Co-Founder Astagatra Institute',
  },
];

//
// Visi Misi
//
export const VISI_MISI_TITLE = 'Visi dan Misi';
export const VISI_TITLE = 'VISI';
export const VISI_DESC =
  'Menjadi lembaga kajian dan pengembangan berlandaskan wawasan kebangsaan yang terkemuka di Indonesia, dan terpandang di Asia';
export const MISI_TITLE = 'MISI';
export const MISI_DESC = [
  'Membangun dan mengembangkan ekosistem ilmu pengetahuan yang selaras dengan isu strategis nasional',
  'Membina karakter insan Indonesia yang berjiwa nasionalisme di lingkup individu, organisasi, dan komunitas',
  'Menggalang kolaborasi lintas sektoral dalam rangka mendukung pembangunan nasional',
];
export const LOGO_PHILOSOPHY_TITLE = 'FILOSOFI LOGO';
export const LOGO_COLOR_TITLE = 'Makna Dari Logo Astagatra Institute:';
export const LOGO_COLOR_MEANING = [
  'Warna Emas pada logo menggambarkan energi positif dan kreatif, kebijaksanaan, optimisme serta kecerdasan.',
  'Warna Merah pada logo menggambarkan kekuatan dan kepercayaan diri.',
];
export const LOGO_SYMBOL_TITLE = 'Simbol bunga teratai memiliki arti:';
export const LOGO_SYMBOL_MEANING = [
  'Bunga teratai pada logo bermakna, kehidupan, pembaharuan dan pendidikan.',
  'Tiga kelopak bunga berwarna kuning merupakan lambang dari Trigatra (aspek alamiah) yaitu Geografi, Demografi, dan Sumber Daya Alam.',
  'Lima kelopak bunga berwarna merah merupakan lambang dari Pancagatra (aspek sosial) yaitu Ideologi, Politik, Ekonomi, Sosial dan Budaya, serta Pertahanan dan Keamanan.',
];

//
// Manajemen
//
export const MANAJEMEN_LV_1 = {
  name: 'Board of Supervisor',
};
export const MANAJEMEN_LV_2 = {
  name: 'I Nyoman Gde Agus Asrama, S.Kom',
  title: 'Executive Director',
  titleHistory: [
    'Executive Director Astagatra Institute',
    'Ketua Unit Kajian Strategis Tim Pakar Satuan Tugas Penanganan COVID-19 Nasional',
    'Ketua Tim Koordinasi, Integrasi dan Kolaborasi Organisasi Hindu Tingkat Nasional',
    'Komisaris PT. Cahaya Anggun Niaga',
    'Dewan Pertimbangan Pimpinan Nasional Peradah Indonesia',
    'Direktur Utama PT. Agung Kandel Sinergi',
  ],
  education: [
    'S1 - Sarjana Manajemen Informatika, Universitas Gunadarma',
    'Program Pendidikan Reguler Angkatan (PPRA) LX - Lembaga Ketahanan Nasional Republik Indonesia',
  ],
  experience: [
    'Executive Director Astagatra Institute (2020 - sekarang)',
    'Ketua Unit Kajian Strategis Tim Pakar Satuan Tugas Penanganan COVID-19 Nasional (2022 - sekarang)',
    'Anggota Tim Pakar Satuan Tugas Penanganan COVID-19 Nasional (2020 - 2021)',
    'Ketua Tim Koordinasi, Integrasi dan Kolaborasi Organisasi Hindu Tingkat Nasional (2020 - sekarang)',
    'Komisaris PT. Cahaya Anggun Niaga (2019 - sekarang)',
    'Dewan Pertimbangan Pimpinan Nasional Peradah Indonesia (2019 - sekarang)',
    'Direktur Utama PT. Agung Kandel Sinergi (2014 - sekarang)',
  ],
};
export const MANAJEMEN_LV_3 = [
  {
    name: 'Niluh Ketut Meilandari',
    title: 'General Affairs Manager',
    titleHistory: [],
    education: ['S1 - Sarjana Akuntansi, Universitas Trisakti'],
    experience: [
      'General Affairs Manager Astagatra Institute (2021 - sekarang)',
      'Asisten Tim Pakar Satuan Tugas Penanganan COVID-19 Nasional (2020 - sekarang)',
      'Staff Administrasi, Kesekretariatan, Sponsorship, & Finance Narada Event Organizer (2018 - sekarang)',
      'Staff Administrasi Yayasan Jabar Agung (2017 - 2018)',
    ],
  },
  {
    name: 'Rizka Aulia Maghfira',
    title: 'Executive Learning Manager',
    titleHistory: [],
    education: [
      'S2 - Magister Manajemen, Sekolah Tinggi Manajemen PPM',
      'S1 - Sarjana Ilmu Komunikasi, Universitas Sumatera Utara',
    ],
    experience: [
      'Executive Learning Manager Astagatra Institute (2021 - sekarang)',
      'Asisten Tim Pakar Satuan Tugas Penanganan COVID-19 Nasional (2021 - sekarang)',
      'General Coordinator Aekna Pine Resort (2021)',
      'Sales Executive PPM School of Management (2020 - 2021)',
      'Digital and Communuication Unit Greenpeace Indonesia (2019 -  2020)',
      'Media Analyst Pemerintah Kota Medan (2017 - 2018)',
    ],
  },
  {
    name: 'Ikhsan Fauzi Munawir',
    title: 'Research and Consulting Manager',
    titleHistory: [],
    education: [
      'S2 - Magister Manajemen, Sekolah Tinggi Manajemen PPM',
      'S1 - Sarjana Ekonomi, Institut Pertanian Bogor',
    ],
    experience: [
      'Research and Consulting Manager Astagatra Institute (2021 - sekarang)',
      'Asisten Tim Pakar Satuan Tugas Penanganan COVID-19 Nasional (2020 - sekarang)',
      'Account Executive PT Mora Telematika Indonesia (2018)',
    ],
  },
  {
    name: 'Shitta Aspendira D. A.',
    title: 'Busineses and Organization Development Manager',
    titleHistory: [],
    education: [
      'S2 - Master of Public Administration, University College London',
      'S1 - Sarjana Teknik, Universitas Indonesia',
    ],
    experience: [
      'Business and Organization Development Manager Astagatra Institute (2021 - sekarang)',
      'Asisten Tim Pakar Satuan Tugas Penanganan COVID-19 Nasional (2021 - sekarang)',
      'Personal Assistant Lembaga Ketahanan Nasional Republik Indonesia (2020)',
      'Personal Assistant Dewan Perwakilan Rakyat Republik Indonesia (2019 - 2020)',
      'Koordinator Asisten Laboratorium Universitas Indonesia (2018)',
    ],
  },
];

//
// ALF
//
export const ALF_TITLE = 'Astagatra Leaders Forum';
export const ALF_DESC =
  'Astagatra Leaders Forum merupakan sebuah forum diskusi dengan tema besar kepemimpinan, manajemen, ekonomi, komunikasi, dan isu-isu strategis terkini. Diskusi dilakukan oleh Board of Experts beranggotakan para ahli dari berbagai sektor dan fokusan bidang yang akan melahirkan artikel dan publikasi ilmiah.';
export const EXPERTS_TITLE = 'Board of Experts';
export const EXPERTS = [
  {
    name: 'Prof. drh. Wiku Adisasmito, M.Sc., Ph.D',
    title: 'Member of Board of Experts',
    titleHistory: [
      'Juru Bicara Pemerintah untuk Penanganan COVID-19',
      'Koordinator Tim Pakar Satgas Penanganan COVID-19',
      'Anggota Majelis Wali Amanat Universitas Indonesia',
      'Dosen Fakultas Kesehatan Masyarakat Universitas Indonesia',
      'Adjunct Professor of Infectious Disease and Global Health Tufts University',
      'Steering Committee Asian Partnership for Emerging Infectious Disease Research (APEIR)',
    ],
    education: [
      'Lembaga Ketahanan Nasional Republik Indonesia (PPSA Angkatan VXIII)',
      'Advance Epidemiology, NEEI - Tufts University',
      'Strengthening Hospital Management, Boston University',
      'S3 - Environmental Health & Policy, Colorado State University',
      'S2 - Environmental Health, Colorado State University',
      'S1 - Kedokteran Hewan, Institut Pertanian Bogor',
    ],
    experience: [
      'Affiliate Professor di Minnesota University',
      'Executive Board dari Coordinating Organizations of Regional Disease Surveillance (CORDS)',
      'Anggota Ecohealth Field Building Leadership Initiatives (Ecohealth FBLI)',
      'Koordinator Indonesia One Health University Network (INDOHUN)',
      'Sekretaris Majelis Wali Amanat Universitas Indonesia',
      'Direktur Inkubator Bisnis Universitas Indonesia',
      'Senior Vice President Badan Penyehatan Perbankan Nasional (BPPN)',
      'Direktur Eksekutif LSM PELANGI Indonesia',
    ],
  },
  {
    name: 'Dr. Wahyu Tri Setyobudi, M.M.',
    title: 'Member of Board of Experts',
    titleHistory: [
      'Dosen Sekolah Tinggi Manajemen PPM',
      'Konsultan Bidang Inovasi dan Transformasi Strategis',
      'Ketua Himpunan Alumni Matematika Institut Teknologi Bandung',
    ],
    education: [
      'Lembaga Ketahanan Nasional Republik Indonesia (PPRA Angkatan LX)',
      'S3 - Doktor Program Studi Manajemen, Institut Teknologi Bandung',
      'S2 - Magister Manajemen, Sekolah Tinggi Manajemen PPM',
      'S1 - Sarjana Sains - Matematika, Institut Teknologi Bandung',
    ],
    experience: [
      'Wakil Dekan Bidang Keuangan dan Hubungan Eksternal Sekolah Tinggi Manajemen PPM',
      'Kepala Bidang Riset Sekolah Tinggi Manajemen PPM',
      'Kepala Center for Innovation and Collaboration Sekolah Tinggi Manajemen PPM',
      'Kepala Research Center and Case Clearing House Sekolah  Tinggi Manajemen PPM',
      'Developer Oracle PT Dinamika Data & System',
      'Anggota Tim Kajian Pakar Satgas Penanganan COVID-19 Nasional',
    ],
  },
  {
    name: 'Dr. Lilik Sudarwati A., S.Psi, M.H.',
    title: 'Member of Board of Experts',
    titleHistory: [
      'Koordinator Sub Bidang Protokol Kesehatan Perubahan Perilaku Satgas Penanganan COVID-19 Nasional',
      'Ketua Bidang Sport Science dan Penerapan IPTEK KONI Pusat',
      'Ketua Ikatan Psikologi Olahraga',
      'Ketua Umum Yayasan Autoimunu MCF',
      'Ketua Bidang Sport Science PB IFA Floorball',
      'Wakil Direktur Akademi Prestasi Nasional',
    ],
    education: [
      'Lembaga Ketahanan Nasional Republik Indonesia (PPRA Angkatan LX)',
      'S3 - Doktor Bidang Hukum, Universitas Trisakti',
      'S2 - Magister Hukum, Universitas Trisakti',
      'S1 - Sarjana Psikologi, Universitas Indonesia',
    ],
    experience: [
      'Kepala Bidang Litbang & Psikologi PB. PBSI',
      'Wakil Ketua dan Anggota Tim Seleksi Program Indonesia Emas (PRIMA)',
      'Wakil Kepala Bidang Sport Science dan Penerapan IPTEK KONI Pusat',
      'Komisioner Badan Standarisasi, Akreditasi Nasional Keolaahragaan (BSANK)',
      'Anggota Tim Kajian Pakar Satgas Penanganan COVID-19 Nasional',
    ],
  },
  {
    name: 'I Nyoman Gde Agus Asrama, S.Kom',
    title: 'Member of Board of Experts',
    titleHistory: [
      'Executive Director Astagatra Institute',
      'Ketua Unit Kajian Strategis Tim Pakar Satuan Tugas Penanganan COVID-19 Nasional',
      'Ketua Tim Koordinasi, Integrasi dan Kolaborasi Organisasi Hindu Tingkat Nasional',
      'Komisaris PT. Cahaya Anggun Niaga',
      'Dewan Pertimbangan Pimpinan Nasional Peradah Indonesia',
      'Direktur Utama PT. Agung Kandel Sinergi',
    ],
    education: [
      'Lembaga Ketahanan Nasional Republik Indonesia (PPRA Angkatan LX)',
      'S1 - Sarjana Manajemen Informatika, Universitas Gunadarma',
    ],
    experience: [
      'Anggota Tim Kajian Pakar Satgas Penanganan COVID-19 Nasional',
      'Ketua Banjar Purna Widya',
      'Ketua Umum Perhimpunan Pemuda Hindu Indonesia',
      'Anggota Lembaga Pendidikan Parisada Hindu Dharma  Indonesia Pusat',
    ],
  },
];
export const SEE_CV_TITLE = 'Lihat CV >';

//
// Membership
//
export const MEMBERSHIP_TITLE = 'Keanggotaan';
export const MEMBERSHIP_DESC =
  'Astagatra Institute membuka kesempatan bagi publik untuk dapat mengakses produk dari layanan yang kami sediakan dengan cara menjadi bagian dari member Astagatra Institute.';
export const MEMBERSHIP_ADVANTAGE_TITLE = 'Keuntungan';
export const MEMBERSHIP_ADVANTAGE_DESC =
  'Setiap bulannya, Astagatra Leaders Forum akan menghasilkan sebuah artikel yang merupakan pengolahan hasil diskusi para Board of Experts. Artikel tersebut dapat diakses melalui media massa resmi Astagatra Institute. Selain itu, hasil forum diskusi tersebut juga akan dikembangkan lebih lanjut untuk pengembangan layanan Astagatra Institute maupun pembuatan publikasi dengan akses berbayar.';

//
// Kajian dan konsultasi
//
export const RESEARCH_TITLE = 'Research and Consulting';
export const RESEARCH_DESC =
  'Kajian dan Konsultasi Astagatra Institute memberikan layanan solusi melalui proses konsultasi dan pendampingan bersama para ahli di bidangnya. Layanan kajian dan konsultasi membantu organisasi baik di sektor publik dan swasta untuk meningkatkan nilai daya saing di bidang ekonomi, komunikasi, dan pengembangan organisasi.';
export const WHY_US_TITLE = 'Kenapa Kami?';
export const WHY_US_DESC =
  'Kajian dan Konsultasi Astagatra Institute membantu organisasi dalam menciptakan akselerasi intervensi strategis berdasarkan kebutuhan organisasi. Kami mendampingi organisasi secara intensif melalui feedback yang diberikan selama periode konsultasi untuk mengatasi permasalahan yang dialami secara intensif dan real-time. Astagatra Institute merupakan partner strategis perusahaan dengan menggunakan tiga pendekatan yang khas.';
export const THREE_APPROACH_TITLE = '3 Pendekatan Konsultasi';
export const THREE_APPROACH_DESC =
  'Tiga pendekatan khas ditujukan untuk menciptakan profesionalitas kami dalam pelaksanaan konsultasi';
export const CONSULT_TITLE = 'Program Konsultasi';
export const THREE_APPROACH = [
  {
    title: 'Modular Build-up',
    desc: 'Membangun kerangka berpikir yang sistematis berbasiskan pada building-blocks, untuk membantu pencapaian strategis secara bertahap dan mencapai quick-win secara cepat.',
  },
  {
    title: 'Appreciative Inquiry',
    desc: 'Membangun kerangka berpikir yang sistematis berbasiskan pada building-blocks, untuk membantu pencapaian strategis secara bertahap dan mencapai quick-win secara cepat.',
  },
  {
    title: 'Transfer of Expertise',
    desc: 'Menjadi partner kolaborasi selama proses pengembangan untuk berbagi pengalaman, pengetahuan dan membantu memfasilitasi pemecahan masalah menggunakan pendekatan terpadu.',
  },
];

//
// Kajian dan Konsultais
//
export const PUBLIC_CONSULTATION_TITLE = 'Penguatan Organisasi Publik';
export const CORPORATION_CONSULTATION_TITLE = 'Pengembangan Bisnis Perusahaan';
export const CONSULT_EXCECUTIVE_TITLE = 'Konsultasi Eksekutif (Advisory)';
export const CORPORATION_CONSULT_EXCECUTIVE_DESC =
  'Konsultasi eksekutif merupakan suatu program yang ditujukan untuk menjawab isu strategis organisasi yang khas. Pendekatan yang digunakan dapat menggunakan metode yang terkustomisasi sesuai dengan kebutuhan organisasi';
export const PUBLIC_CONSULT_EXCECUTIVE_DESC =
  'Konsultasi eksekutif ditujukan untuk menjawab isu strategis organisasi melalui pendampingan oleh pakar secara langsung. Feedback diberikan selama periode konsultasi untuk mengatasi permasalahan yang dialami secara intensif dan real-time. Pendekatan ini digunakan sebagai solusi atas situasi yang khas di organisasi baik di sektor publik atau swasta. ';
export const CONSULT_EXCECUTIVE_FIELD_TITLE =
  'Bidang Konsultasi Pengembangan Bisnis Perusahaan';
export const CORPORATION_CONSULT_EXCECUTIVE_FIELD = [
  {
    title: 'Sistem Pengembangan SDM',
    desc: 'Strategi digitalisasi yang mendorong aktivitas ekonomi daerah yang diwujudkan melalui sistem layanan publik yang lebih terintegrasi',
  },
  {
    title: 'Desain & Pengembangan Organisasi',
    desc: 'Strategi yang diciptakan untuk mendorong potensi aktivitas ekonomi sektor-sektor strategis yang dimiliki oleh daerah',
  },
  {
    title: 'Strategi Transformasi Organisasi',
    desc: 'Strategi optimalisasi media informasi daerah untuk menciptakan sistem komunikasi yang faktual, aktual, dan terpercaya',
  },
];
export const PUBLIC_CONSULT_EXCECUTIVE_FIELD = [
  {
    title: 'Strategi Penguatan Ekonomi Berbasis Digital',
    desc: 'Strategi digitalisasi yang mendorong aktivitas ekonomi daerah yang diwujudkan melalui sistem layanan publik yang lebih terintegrasi',
  },
  {
    title: 'Strategi Penguatan Ekonomi Sektoral',
    desc: 'Strategi yang diciptakan untuk mendorong potensi aktivitas ekonomi sektor-sektor strategis yang dimiliki oleh daerah',
  },
  {
    title: 'Strategi Komunikasi Publik Terintegrasi',
    desc: 'Strategi optimalisasi media informasi daerah untuk menciptakan sistem komunikasi yang faktual, aktual, dan terpercaya',
  },
];
export const PUBLIC_BENEFIT = [
  'Solusi praktik terbaik yang berlandaskan dengan nilai-nilai organisasi',
  'Pendampingan eksklusif bersama dengan pakar di bidangnya',
  'Eksplorasi potensi organisasi dalam pengelelolaan strategis',
];
export const CORPORATE_BENEFIT = [
  'Peningkatan produktivitas kerja',
  'Pencapaian strategi quick-win',
  'Terciptanya roadmap strategi',
];

//
// Pelatihan
//
export const LEARNING_TITLE = 'Executive Learning';
export const LEARNING_EXECUTIVE_TITLE = 'Executive Learning';
export const LEARNING_EXECUTIVE_DESC =
  'Executive Learning Astagatra Institute menjadi sarana bagi individu pada organisasi untuk mengembangkan serta meningkatkan kemampuan dalam bidang kepemimpinan, manajemen, ekonomi, dan komunikasi. Melalui pendekatan pembelajaran problem based, dynamic, dan participant centered, pelatihan kami dirancang dalam 3 tahapan pembelajaran dan pengembangan. ';
export const LEARNING_SERVICE_TITLE = 'Layanan Pelatihan';
export const LEARNING_SERVICE = [
  {
    title: 'Meta-Leadership Program',
    desc: 'Program pengembangan kepemimpinan unggul berbasis wawasan kebangsaan dengan melatih pola pikir extraordinary dalam menghadapi berbagai macam situasi.',
  },
  {
    title: 'Economy Acceleration Program',
    desc: 'Strategi yang diciptakan untuk mendorong potensi aktivitas ekonomi sektor-sektor strategis yang dimiliki oleh daerah.',
  },
  {
    title: 'Communication Program',
    desc: 'Strategi optimalisasi media informasi daerah untuk menciptakan sistem komunikasi yang faktual, aktual, dan terpercaya.',
  },
];
export const LEARNING_METHOD_TITLE = 'Metode Pelatihan';
export const LEARNING_METHOD = [
  {
    title: 'In-Class Training',
  },
  {
    title: 'Live Online Training',
  },
];
