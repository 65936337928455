import { useNavigate } from "react-router";

import { Box, Grid, GridItem, Tag, Text, VStack, HStack, Center, Link, Image, Stack, Container, Flex, Spacer } from "@chakra-ui/react";

import { GREY, LIGHTGREY, PINK, RED } from "@constants/colors";

import imgView from "@assets/info/view.svg";
import imgLike from "@assets/info/like.svg";
import imgDownload from "@assets/info/download.svg";
import imgComment from "@assets/info/comment.svg";
import imgPlaceholder from "@assets/image-placeholder.png";

import type { InfoProps } from "customTypes/info";

const Card = (data: InfoProps) => {
  const { categories, title, queryKey, summary, likes, views, downloads, headerUrl, comments, type } = data;

  const navigate = useNavigate();

  const handleClickViewMore = () => {
    navigate("/service/" + type + "/" + encodeURIComponent(queryKey));
  };

  return (
    <Box bg={LIGHTGREY} borderRadius="lg" w="100%" _hover={{ shadow: "base" }} padding="4">
      <Flex direction={{ base: "column", md: "row", lg: "row" }}>
        <Box flex={2}>
          <Image src={headerUrl ?? imgPlaceholder} alt={title} objectFit="cover" boxSize="100%" />
        </Box>
        <Box w="16px" h="16px" />
        <Box flex={3} h="100%">
          <Stack flexDir="column" h="100%">
            <HStack>
              {categories.map((ct, i) => (
                <Tag key={i} bg={PINK} color={RED} fontSize={{ base: "xs", lg: "xs" }} fontWeight="bold">
                  {ct}
                </Tag>
              ))}
            </HStack>
            <Box flexGrow={2}>
              <Text fontSize={{ base: "md", lg: "lg" }} as="b">
                {title}
              </Text>
              <Text fontSize={{ base: "sm", lg: "md" }} marginTop={2}>
                {summary}
              </Text>
            </Box>
            <HStack alignSelf="end" justifyContent="space-between" w="100%">
              <Text as="u" fontSize={{ base: "xs", lg: "md" }} color={RED} onClick={() => handleClickViewMore()}>
                <Link as="b">{"Lihat Lebih >"}</Link>
              </Text>
              <HStack>
                <Image src={imgView} alt="Views" />
                <Text fontSize={{ base: "xs", lg: "sm" }} color={GREY}>
                  {views}
                </Text>
                {/* {(type === "article" || type === "news") && (
                  <>
                    <Image src={imgLike} alt="Likes" />
                    <Text fontSize={{ base: "xs", lg: "sm" }} color={GREY}>
                      {likes}
                    </Text>
                  </>
                )} */}
                {type === "publication" && (
                  <>
                    <Image src={imgDownload} alt="Downloads" />
                    <Text fontSize={{ base: "xs", lg: "sm" }} color={GREY}>
                      {downloads}
                    </Text>
                  </>
                )}
                {/* {type === "news" && (
                  <>
                    <Image src={imgComment} alt="Comments" />
                    <Text fontSize={{ base: "xs", lg: "sm" }} color={GREY}>
                      {comments}
                    </Text>
                  </>
                )} */}
              </HStack>
            </HStack>
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
};

export default Card;
