import React, { useEffect, useState } from 'react';
import { Box, Stack, Text, Image, Grid } from '@chakra-ui/react';
import { BLACK, LIGHTGREY, RED, WHITE } from '@constants/colors';
import { ALF_TITLE, EXPERTS_TITLE } from '@constants/texts';
import { BREADCRUMB_ALF } from '@constants/breakcrumbs';
import { QuickLinkProps } from '@customTypes/quickLink';
import { ALFProps } from '@customTypes/alf';
import CustomBreadcrumb from '@components/CustomBreadcrumb';
import Category from '@components/Category';
import fetchRequest from '@utils/fetcher';
import ModalCV from '@routes/AboutUs/components.tsx/Modal';
import ModalMobileCV from '@routes/AboutUs/components.tsx/ModalMobile';

const Forum = () => {
  const [desc, setDesc] = useState<string>('');
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const [ALFData, setALFData] = useState<ALFProps[]>([]);
  const [isHovered, setIsHovered] = useState(false);

  const getALFData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'about-us/alf',
      }).then((response) => {
        setQuickLinkList(response.quickLinks || []);
        setALFData(response.astagatraLeadersForum);
        setDesc(response.description);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getALFData();
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box w={'100%'}>
      <Box
        h="30px"
        paddingLeft={{ base: '10px', lg: '3%' }}
        paddingRight={{ base: '10px' }}
        w="100%"
      >
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_ALF} />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop="20px"
      >
        <Box
          w={{ md: '20%' }}
          h={'400px'}
          top={'150'}
          position={'sticky'}
          display={{ md: 'fixed', base: 'none' }}
        >
          <Category headers={[...quickLinkList]} />
        </Box>
        <Box w={{ lg: '65%', md: '75%', sm: '90%' }}>
          <Text
            className={'fontSemiBold'}
            fontSize={{ xl: '40px', lg: '32px', md: '24px' }}
            align={'center'}
          >
            {ALF_TITLE}
          </Text>
          <br />
          <Text
            w={'100%'}
            fontSize={{ lg: '18px', base: '14px' }}
            textAlign={'justify'}
          >
            <div dangerouslySetInnerHTML={{ __html: desc }} />
          </Text>
          <br />
          <Text
            className={'fontSemiBold'}
            fontSize={{ lg: '24px', base: '18px' }}
          >
            {EXPERTS_TITLE}
          </Text>
          <br />
          <Grid
            templateColumns={{ md: 'repeat(3, 1fr)', base: 'repeat(2, 1fr)' }}
            gap={2}
          >
            {ALFData.map((m, i) => (
              <Box key={i} 
              className={`red-hover ${isHovered ? 'red-hover' : ''}`} 
              onMouseEnter={handleMouseEnter} 
              onMouseLeave={handleMouseLeave}
              h={{ lg: '400px', md: '300px', base: '260px' }}
              w={{ lg: '250px', md: '180px', base: '160px' }}
              
              >
                <Box
                  className="shownALF"
                  h={{ lg: '400px', md: '300px', base: '260px' }}
                  w={{ lg: '250px', md: '180px', base: '160px' }}
                >
                  <Image h={'70%'} w={'100%'} src={m.photo} />
                  <Text
                    maxH={'30%'}
                    w={'100%'}
                    padding={'12px'}
                    fontSize={{ lg: '18px', md: '16px', base: '12px' }}
                    bg={LIGHTGREY}
                    color={BLACK}
                    className={'fontSemiBold'}
                    noOfLines={3}
                  >
                    {m.name}
                    <br />
                    <ModalMobileCV
                      name={m.name}
                      position={m.position}
                      content={m.content}
                      photo={m.photo}
                    ></ModalMobileCV>
                  </Text>
                </Box>
                <Box
                  className={'hiddenALF'}
               
                  h={{ lg: '400px', md: '300px', base: '260px' }}
                  w={{ lg: '250px', md: '180px', base: '160px' }}
                >
                  <Box
                    padding={'12px'}
                    fontSize={{ lg: '18px', md: '16px', base: '12px' }}
                    color={WHITE}
                    textAlign={'center'}
                    marginY={'50%'}
                  >
                    <Text className={'fontBold'}>{m.name}</Text>
                    <Box>
                      <ModalCV
                        name={m.name}
                        position={m.position}
                        content={m.content}
                        photo={m.photo}
                      ></ModalCV>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Grid>
          <br />
        </Box>
      </Stack>
    </Box>
  );
};

export default Forum;
