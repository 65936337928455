import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import GeneralRoutes from './generalRoutes';
import AuthRoutes from './authRoutes';

import { BERANDA, AUTH } from '@constants/urls';
import ScrollToTop from './scrollToTop';

import Newsletter from './Newsletter';

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path={BERANDA + '*'} element={<GeneralRoutes />} />
          <Route path={AUTH + '*'} element={<AuthRoutes />} />

          <Route path={'/v1/member/*'} element={<Newsletter />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
