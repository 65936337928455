import {
  Box,
  Grid,
  GridItem,
  Tag,
  Text,
  VStack,
  HStack,
  Center,
  Link,
  Image,
  Stack,
  Container,
  Flex,
  Spacer,
} from '@chakra-ui/react';

import { GREY, LIGHTGREY, PINK, RED } from '@constants/colors';

import CustomButton from '@components/Button';

interface Props {
  eventName: string;
  pdfUrl: string;
  thumbnailUrl: string;
}

const Card = (data: Props) => {
  const { eventName, pdfUrl, thumbnailUrl } = data;

  return (
    <Box
      bg={LIGHTGREY}
      borderRadius="lg"
      w="100%"
      _hover={{ shadow: 'base' }}
      padding="4"
    >
      <VStack align="start">
        <Image
          src={
            thumbnailUrl ||
            'https://www.seekpng.com/png/detail/966-9665317_placeholder-image-person-jpg.png'
          }
          fit="cover"
          w="100%"
          h="130px"
        />
        <Text as="b">{eventName || ''}</Text>
        <CustomButton
          text="Lihat Sertifikat"
          icon="none"
          variant="transparent"
          w="150px"
          onClick={() => window.open(pdfUrl)}
        />
      </VStack>
    </Box>
  );
};

export default Card;
