import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  Box,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
  Center,
  Image,
  Tag,
  HStack,
} from '@chakra-ui/react';

import CustomBreadcrumb from '@components/CustomBreadcrumb';

import fetchRequest from '@utils/fetcher';

import { BREADCRUMB_GALERI } from '@constants/breakcrumbs';
import { GREY } from '@constants/colors';

import { GalleryDetailProps } from '@customTypes/info';
import PhotoModal from './PhotoModal';

const GalleryDetail = () => {
  const [galleryData, setGalleryData] = useState<GalleryDetailProps>();

  const location = useLocation();
  const queryKey = location.pathname.split('/')[3];

  const [isModalOpen, setIsModalOpen] = useState<Record<string, boolean>>({});

  const handleModal = (id: string) => {
    const currentModal = isModalOpen[id];
    if (typeof currentModal === 'undefined') {
      setIsModalOpen({
        [id]: true,
      });
    } else {
      setIsModalOpen({
        [id]: !currentModal,
      });
    }
  };

  const getGalleryData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'gallery/' + queryKey,
      }).then((response) => {
        setGalleryData(response.data || {});
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategoryClick = (category: string) => {};

  useEffect(() => {
    getGalleryData();
  }, []);

  return (
    <Box>
      <Box h="30px" paddingLeft={{ base: '10px', lg: '3%' }} w="100%">
        <CustomBreadcrumb
          breadcrumbs={[
            ...BREADCRUMB_GALERI,
            { name: galleryData?.title || '', url: '' },
          ]}
        />
      </Box>
      <VStack w="100%" padding={{ lg: '0', base: "20px"}}>
        <Box w={{ base: '100%', md: '80%' }}>
          <VStack align="center">
            <Text as="b" fontSize="3xl" textAlign="center">
              {galleryData?.title || ''}
            </Text>
            <Text color={GREY}>
              {galleryData?.createdAt
                ? dayjs(galleryData.createdAt).format('DD MMMM YYYY')
                : ''}
            </Text>
            <HStack>
              {galleryData?.categories &&
                galleryData.categories.length > 0 &&
                galleryData.categories.map((category) => (
                  <Tag
                    key={category}
                    colorScheme="red"
                    onClick={() => handleCategoryClick(category)}
                  >
                    {category}
                  </Tag>
                ))}
            </HStack>
            <Text paddingBottom="20px" textAlign="center">{galleryData?.content || ''}</Text>
          </VStack>
        </Box>
        <Center w={{ base: '90%' }}>
        {galleryData?.items && (
          <Wrap justify='center'>
            {galleryData?.items.map((gl) => {
              let itemUrl = gl.url;

              if (gl.itemType === 'Video') {
                const videoUrl = new URL(gl.url);
                itemUrl =
                  'https://www.youtube.com/embed/' +
                    videoUrl.searchParams.get('v') || '';
              }

              return (
                <WrapItem key={gl.url} w={{ md: '200px', base: '100%' }}>
                  {gl.itemType === 'Photo' ? (
                    <>
                      <Image
                        src={itemUrl}
                        objectFit="cover"
                        w={{ lg: '200px', base: '100%' }}
                        h="200px"
                        onClick={() => handleModal(itemUrl)}
                        _hover={{ cursor: 'pointer' }}
                      />
                      <PhotoModal
                        itemUrl={itemUrl}
                        isOpen={isModalOpen[itemUrl]}
                        onClose={() => handleModal(itemUrl)}
                      />
                    </>
                  ) : (
                    <Box
                      w={{ base: '100%', md: '200px' }}
                      onClick={() => window.open(gl.url)}
                      h="200px"
                    >
                      <iframe width="100%" height="200" src={itemUrl} />
                    </Box>
                  )}
                </WrapItem>
              );
            })}
          </Wrap>
        )}
        </Center>
      </VStack>
      <Box h="20px"></Box>
    </Box>
  );
};

export default GalleryDetail;
