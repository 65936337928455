import { useEffect, useState } from "react";

import { Box, HStack, Text, Stack, useDisclosure, Center } from "@chakra-ui/react";

import CustomBreadcrumb from "@components/CustomBreadcrumb";
import CustomButton from "@components/Button";
import EventTable from "@components/EventTable";
import SortEvent from "@components/EventTable/SortEvent";
import TypeFilter from "@components/EventTable/TypeFilter";

import { BREADCRUMB_ACARA } from "@constants/breakcrumbs";
import { BLACK, PINK } from "@constants/colors";

import fetchRequest from "@utils/fetcher";

import { EventProps } from "@customTypes/event";

interface GetProps {
  isNewest: boolean;
  selectedType: string[];
}

const Event = () => {
  const [eventData, setEventData] = useState<EventProps[]>([]);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [types, setTypes] = useState<string[]>([]);

  const { isOpen: sortIsOpen, onClose: sortOnClose, onOpen: sortOnOpen } = useDisclosure();

  const { isOpen: filterIsOpen, onClose: filterOnClose, onOpen: filterOnOpen } = useDisclosure();

  const getEventData = async (props: Partial<GetProps>) => {
    const { isNewest = false, selectedType = [] } = props;

    let param = "?page=" + currentPage + "&newest=" + isNewest;

    if (selectedType) {
      selectedType.forEach((type) => {
        param += "&types=" + type;
      });
      setTypes(selectedType);
    }

    try {
      await fetchRequest({
        method: "GET",
        path: "event" + param,
      }).then((response) => {
        setEventData(response.dataList || []);
        setLastPage(response.lastPage || 1);
        setTotal(response.count || 0);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEventData({});
  }, []);

  return (
    <Box>
      <Box h="30px" paddingLeft={{ base: "10px", lg: "3%" }} w="100%">
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_ACARA} />
      </Box>
      <Center w={{ base: "100%" }} margin={{ md: 'auto' }}>
        <Box w={{ md: "75%", sm: "90%" }}>
          <Text className={"fontBold"} fontSize={{ md: "36px", base: "24px" }} color={BLACK} textAlign={{ base: "center", lg: "start" }}>
            Acara
          </Text>
          <Box w="100%" h="40px" textAlign="end">
            <HStack w="100%">
              <Box w="100%">
                <CustomButton text="FILTER" variant="transparent" icon="filter" w="135px" onClick={() => filterOnOpen()} />
                <TypeFilter
                  isOpen={filterIsOpen}
                  onClose={filterOnClose}
                  handleFilter={(selectedType) => getEventData({ selectedType: selectedType })}
                />
              </Box>
              <Box>
                <CustomButton text="URUTKAN" variant="transparent" icon="sort" w="135px" onClick={() => sortOnOpen()} />
                <SortEvent isOpen={sortIsOpen} onClose={sortOnClose} handleSort={(sort) => getEventData({ isNewest: sort })} />
              </Box>
            </HStack>
          </Box>
          <Text paddingBottom="30px">
            Menampilkan {total} acara
            {types &&
              types.map((type, i) => {
                if (i === types.length - 1) {
                  return ` "${type}"`;
                } else {
                  ` "${type}",`;
                }
              })}
          </Text>
          <Box w={{ base: "90vw", lg: "100%" }} border="solid 1px" borderColor={PINK} borderRadius="lg" overflowX="scroll">
            <EventTable data={eventData || []} needLogin />
          </Box>
        </Box>
      </Center>
    </Box>
  );
};

export default Event;
