import { useState } from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Divider,
  Text,
  VStack,
  RadioGroup,
  Radio,
  HStack,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';

import { BLACK } from '@constants/colors';

import CustomButton from '@components/Button';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleFilter: (selectedType: string[]) => void;
};

const TYPE_LIST = [
  {
    name: 'Pelatihan',
    value: 'training',
  },
  {
    name: 'Webinar',
    value: 'webinar',
  },
  {
    name: 'Kajian & Konsultasi',
    value: 'study-consultation',
  },
];

const TypeFilter = ({ isOpen, onClose, handleFilter: handleSort }: Props) => {
  const [selectedType, setSelectedType] = useState<string[]>([]);

  const handleSortClick = () => {
    handleSort(selectedType);
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton
          variant="ghost"
          _hover={{ bg: 'transparent' }}
          _focus={{ boxShadow: 'none' }}
          color={BLACK}
          top="15px"
          as="b"
        />
        <DrawerHeader>FILTER</DrawerHeader>
        <DrawerBody>
          <VStack align="start">
            <Text paddingBottom="10px">Tampilkan hanya kegiatan: </Text>
            <Divider />
            <CheckboxGroup
              value={selectedType}
              onChange={(value) => setSelectedType(value as string[])}
              defaultValue={TYPE_LIST.map((tl) => tl.value)}
            >
              <VStack align="start">
                {TYPE_LIST.map((filter, i) => (
                  <>
                    <Checkbox
                      key={i}
                      value={filter.value}
                      colorScheme="red"
                      _focus={{ boxShadow: 'none' }}
                    >
                      {filter.name}
                    </Checkbox>
                    <Divider paddingTop="5px" paddingBottom="5px" />
                  </>
                ))}
              </VStack>
            </CheckboxGroup>
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          <HStack>
            <CustomButton
              text="BATAL"
              variant="white"
              icon="none"
              w="100px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="FILTER"
              variant="red"
              icon="none"
              w="120px"
              onClick={() => handleSortClick()}
            />
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default TypeFilter;
