import { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Center,
  Stack,
  Text,
  WrapItem,
  Image,
  Divider,
  HStack,
  VStack,
} from '@chakra-ui/react';
import {
  RESEARCH_TITLE,
  RESEARCH_DESC,
  WHY_US_TITLE,
  WHY_US_DESC,
  THREE_APPROACH_TITLE,
  THREE_APPROACH_DESC,
  THREE_APPROACH,
  CONSULT_TITLE,
} from '@constants/texts';
import { RED, BLACK } from '@constants/colors';
import { BREADCRUMB_KAJIAN_DAN_KONSULTASI } from '@constants/breakcrumbs';
import Category from '@components/Category';
import CustomBreadcrumb from '@components/CustomBreadcrumb';
import ContactUs from '@routes/Product/components/ContactUs';
import DownloadCatalog from '@routes/Product/components/DownloadCatalog';
import flowResearch from '@assets/service/research/flow-research.png';
import fetchRequest from '@utils/fetcher';
import type { QuickLinkProps } from '@customTypes/quickLink';
import PublicConsultation from '../routes/PublicConsultation';
import CorporateConsultation from '../routes/CorporateConsultation';
import { BenefitProps, ItemProps } from '@customTypes/service';

const Research = () => {
  const [catalog, setCatalog] = useState('');
  const [mainPicture, setMainPicture] = useState('');
  const [description, setDescription] = useState('');
  const [whyUsDesc, setWhyUsDesc] = useState('');
  const [methodDesc, setMethodDesc] = useState('');
  const [methodItem, setMethodItems] = useState<ItemProps[]>([]);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const [strategicDesc, setStrategicDesc] = useState('');
  const [advisoryDesc, setAdvisoryDesc] = useState('');
  const [strategicItem, setStrategicItem] = useState<ItemProps[]>([]);
  const [advisoryItem, setAdvisoryItem] = useState<ItemProps[]>([]);
  const [strategicBenefit, setStrategicBenefit] = useState<BenefitProps>({
    content: '',
    icon: '',
  });
  const [advisoryBenefit, setAdvisoryBenefit] = useState<BenefitProps>({
    content: '',
    icon: '',
  });

  const getResearchData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'service/study-and-consul',
      }).then((response) => {
        setCatalog(response.catalog || []);
        setQuickLinkList(response.quickLinks || []);
        setStrategicBenefit(response.strategicBenefit || {});
        setAdvisoryBenefit(response.advisoryBenefit || {});
        setStrategicDesc(response.strategicDesc || '');
        setAdvisoryDesc(response.advisoryDesc || '');
        setStrategicItem(response.strategicItem || []);
        setAdvisoryItem(response.advisoryItem || []);
        setMainPicture(response.mainPicture || {});
        setDescription(response.mainDesc || {});
        setWhyUsDesc(response.whyUsDesc || {});
        setMethodDesc(response.methodDesc || {});
        setMethodItems(response.methodItem || []);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getResearchData();
  }, []);

  return (
    <Box w={'100%'}>
      <Box
        h="30px"
        paddingLeft={{ base: '10px', lg: '3%' }}
        paddingRight={{ base: '10px' }}
        w="100%"
      >
        <CustomBreadcrumb breadcrumbs={BREADCRUMB_KAJIAN_DAN_KONSULTASI} />
      </Box>
      <Stack
        w={{ xl: '100%' }}
        direction={['column', 'row']}
        spacing={{ md: '50px', base: '30px' }}
        paddingLeft={{ base: '3%' }}
        paddingRight={{ base: '3%' }}
        paddingTop="20px"
      >
        <Box
          w={{ md: '20%' }}
          h={'400px'}
          top={{ md: '150', base: '0' }}
          position={'sticky'}
          display={{ md: 'inline', base: 'none' }}
        >
          <Category headers={[...quickLinkList]} />
        </Box>
        <Box w={{ lg: '60%', md: '75%', sm: '90%' }}>
          <Center>
            <Text
              className={'fontSemiBold'}
              fontSize={{ xl: '40px', lg: '32px', md: '24px' }}
              color={BLACK}
              align={'center'}
            >
              {RESEARCH_TITLE}
            </Text>
          </Center>
          <Box paddingTop="30px" paddingBottom="30px">
            <DownloadCatalog pageTitle={RESEARCH_TITLE} catalogUrl={catalog} />
          </Box>
          <Box>
            <Image src={mainPicture} width={'100%'} />
          </Box>
          <br />
          <Box>
            <Text
              className={'fontRegular'}
              fontSize={{ md: '18px', base: '14px' }}
              paddingTop="30px"
              color={BLACK}
              textAlign="justify"
            >
              {description}
            </Text>
          </Box>
          <Box>
            <Text
              className={'fontSemiBold'}
              fontSize={{ md: '24px', base: '16px' }}
              paddingTop="30px"
              paddingBottom="30px"
              color={BLACK}
            >
              {WHY_US_TITLE}
            </Text>
            <Text
              className={'fontRegular'}
              fontSize={{ md: '18px', base: '14px' }}
              color={BLACK}
              textAlign="justify"
            >
              {whyUsDesc}
            </Text>
          </Box>
          <br />
          <Box>
            <Text
              className={'fontSemiBold'}
              fontSize={{ md: '24px', base: '16px' }}
              paddingTop="30px"
              paddingBottom="30px"
              color={BLACK}
            >
              {THREE_APPROACH_TITLE}
            </Text>
            <Text
              className={'fontRegular'}
              fontSize={{ md: '18px', base: '14px' }}
              color={BLACK}
              textAlign="justify"
            >
              {methodDesc}
            </Text>
          </Box>
          <br />
          <VStack
            w={'100%'}
            spacing={{ base: '20px' }}
            justify={{ base: 'center' }}
          >
            {methodItem.map((m, i) => (
              <WrapItem key={i}>
                <Center
                  h={{ xl: '150px', base: '100%' }}
                  bg={RED}
                  borderRadius={'lg'}
                  color={'white'}
                  textAlign={'center'}
                  padding={{ md: '20px', base: '10px' }}
                  flexDirection={'column'}
                >
                  <Text
                    className={'fontBold'}
                    fontSize={{ md: '20px', base: '16px' }}
                  >
                    {m.title}
                  </Text>
                  <Divider h={'15px'} borderColor={'transparent'}></Divider>
                  <Text
                    className={'fontRegular'}
                    fontSize={{ md: '18px', base: '12px' }}
                  >
                    {m.content}
                  </Text>
                </Center>
              </WrapItem>
            ))}
          </VStack>
          <br />
          <Box>
            <Text
              className={'fontSemiBold'}
              fontSize={{ md: '28px', base: '18px' }}
              paddingTop="30px"
              paddingBottom="30px"
            >
              {CONSULT_TITLE}
            </Text>
          </Box>
          <PublicConsultation
            desc={strategicDesc}
            item={strategicItem}
            benefit={strategicBenefit}
          />
          <br />
          <CorporateConsultation
            desc={advisoryDesc}
            item={advisoryItem}
            benefit={advisoryBenefit}
          />
          <br />
          <Center>
            <ContactUs />
          </Center>
          <Box
            w={{ md: '20%' }}
            h="100%"
            display={{ md: 'none', base: 'block' }}
            paddingTop="50px"
            paddingBottom="100px"
          >
            <Category headers={quickLinkList} />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default Research;
