import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';

import './index.css';
import App from './routes';

import { GlobalProvider } from '@contexts/globalState';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BREAKPOINTS } from '@constants/breakpoints';

import { Fonts } from './Fonts';

const theme = extendTheme({
  breakpoints: BREAKPOINTS,
  fonts: {
    body: 'Avenir Next Cyr, sans-serif',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Fonts />
      <ChakraProvider theme={theme}>
        <GlobalProvider>
          <App />
        </GlobalProvider>
      </ChakraProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
