import React from 'react';

import {
  Box,
  Center,
  Grid,
  GridItem,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';

import { LIGHTGREY, RED } from '@constants/colors';

import imgDownloadCatalog from '@assets/service/download-catalog.svg';
import CustomButton from '@components/Button';

type Props = {
  pageTitle: string;
  catalogUrl: string;
};

const DownloadCatalog = ({ pageTitle, catalogUrl }: Props) => {
  const handleClickButton = () => {
    window.open(catalogUrl);
  };

  return (
    <Box bg={LIGHTGREY} borderRadius={'lg'}>
      <Grid templateColumns="repeat(3, 1fr)" gap={2}>
        <GridItem
          w="100%"
          h={{ base: '140px', lg: '220px' }}
          colSpan={{ base: 3, lg: 1 }}
        >
          <Center w="100%" h="100%">
            <Image
              src={imgDownloadCatalog}
              alt="Download Catalog"
              boxSize="100%"
              objectFit="scale-down"
              padding="20px"
            />
          </Center>
        </GridItem>
        <GridItem
          w="100%"
          h={{ base: '180px', lg: '220px' }}
          colSpan={{ base: 3, lg: 2 }}
        >
          <Center w="100%" h="100%">
            <VStack align={{ base: 'center', lg: 'start' }}>
              <Text fontSize={{ base: 'md', lg: 'xl' }} as="b">
                Katalog Layanan
              </Text>
              <Text
                paddingBottom={{ base: '0px', lg: '20px' }}
                fontSize={{ base: 'sm', lg: 'md' }}
                align={{ base: 'center', lg: 'start' }}
              >
                Dapatkan penawaran mengenai Layanan {pageTitle} kami
                dengan mengunduh katalog di bawah ini
              </Text>
              <CustomButton
                text="Unduh Katalog"
                variant="transparent"
                icon="download"
                w="160px"
                onClick={() => handleClickButton()}
              />
            </VStack>
          </Center>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default DownloadCatalog;
