import {
  ACARA,
  ALF,
  ARTIKEL,
  BERANDA,
  BERITA,
  GALERI,
  KAJIAN_DAN_KONSULTASI,
  KONTAK,
  LAYANAN,
  MANAJEMEN,
  MEMBERSHIP,
  PELATIHAN,
  PRODUK_KAMI,
  PUBLIKASI,
  TENTANG_KAMI,
  VISI_MISI,
  WEBINAR,
} from "../urls";

export const MENU = [
  {
    name: "Beranda",
    url: BERANDA,
    pageExist: true,
    subMenu: [],
  },
  {
    name: "Tentang Kami",
    url: TENTANG_KAMI,
    pageExist: true,
    subMenu: [
      {
        name: "Visi Misi",
        url: VISI_MISI,
        pageExist: true,
      },
      {
        name: "Manajemen",
        url: MANAJEMEN,
        pageExist: true,
      },
      {
        name: "Astagatra Leaders Forum",
        url: ALF,
        pageExist: true,
      },
      // {
      //   name: "Keanggotaan",
      //   url: MEMBERSHIP,
      //   pageExist: true,
      // },
    ],
  },
  {
    name: "Produk Kami",
    url: PRODUK_KAMI,
    pageExist: false,
    subMenu: [
      {
        name: "Research and Consulting",
        url: KAJIAN_DAN_KONSULTASI,
        pageExist: true,
      },
      {
        name: "Executive Learning",
        url: PELATIHAN,
        pageExist: true,
      },
      {
        name: "Publishing & Report",
        url: WEBINAR,
        pageExist: true,
      },
    ],
  },
  {
    name: "Layanan",
    url: LAYANAN,
    pageExist: false,
    subMenu: [
      {
        name: "Artikel",
        url: ARTIKEL,
        pageExist: true,
      },
      {
        name: "Publikasi",
        url: PUBLIKASI,
        pageExist: true,
      },
      {
        name: "Berita",
        url: BERITA,
        pageExist: true,
      },
      {
        name: "Acara",
        url: ACARA,
        pageExist: true,
      },
      {
        name: "Galeri",
        url: GALERI,
        pageExist: true,
      },
    ],
  },
  {
    name: "Kontak",
    url: KONTAK,
    pageExist: true,
    subMenu: [],
  },
];
