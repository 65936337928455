import imgInstagram from '@assets/instagram.svg';
import imgYoutube from '@assets/youtube.svg';
import imgFacebook from '@assets/facebook.svg';
import imgTwitter from '@assets/twitter.svg';
import imgWhatsapp from '@assets/whatsapp.svg';


export const SOCIAL_MEDIA_LOGO = {
  Instagram: imgInstagram,
  Facebook: imgFacebook,
  Youtube: imgYoutube,
  Twitter: imgTwitter,
  Whatsapp: imgWhatsapp,
 
};

export const FILTERS = {
  '#8D0000':
    'brightness(0) saturate(100%) invert(9%) sepia(89%) saturate(5585%) hue-rotate(360deg) brightness(77%) contrast(110%)',
};
