import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Divider,
} from "@chakra-ui/react";

import { MENU } from "@constants/menus";
import { BLACK, PINK, RED, WHITE } from "@constants/colors";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const MobileMenu = ({ isOpen, onClose }: Props) => {
  const navigate = useNavigate();

  const handleSubMenuClicked = (url: string) => {
    navigate(url);
  };

  const isUrlMatch = (url: string) => {
    if (!url) return false;
    if (url === "/") return window.location.pathname === "/";
    return window.location.pathname.includes(url);
  };

  return (
    <Drawer isOpen={isOpen} placement="top" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton variant="ghost" _hover={{ bg: "transparent" }} _focus={{ boxShadow: "none" }} color={RED} left="10px" />
        <DrawerHeader />
        <DrawerBody>
          <Accordion defaultIndex={[0]} allowMultiple marginTop="20px">
            {MENU.map((m, i) => (
              <AccordionItem key={i}>
                <h2>
                  <AccordionButton
                    _hover={{ bg: PINK, color: RED }}
                    _focus={{ boxShadow: "none" }}
                    _active={{ bg: PINK, color: RED }}
                    color={isUrlMatch(m.url) ? RED : BLACK}
                    bg={isUrlMatch(m.url) ? PINK : WHITE}
                  >
                    <Box
                      flex="1"
                      textAlign="left"
                      onClick={() => {
                        m.pageExist && handleSubMenuClicked(m.url);
                        m.pageExist && onClose();
                      }}
                    >
                      {m.name.toUpperCase()}
                    </Box>
                    {m.subMenu.length > 0 && <AccordionIcon />}
                  </AccordionButton>
                </h2>
                {m.subMenu.map((sm, idx) => (
                  <AccordionPanel
                    pb={1}
                    key={idx}
                    onClick={() => {
                      handleSubMenuClicked(sm.url);
                      onClose();
                    }}
                    _hover={{ bg: PINK, color: RED }}
                    _active={{ bg: PINK, color: RED }}
                    color={isUrlMatch(sm.url) ? RED : BLACK}
                    bg={isUrlMatch(sm.url) ? PINK : WHITE}
                  >
                    {sm.name}
                    <Divider />
                  </AccordionPanel>
                ))}
              </AccordionItem>
            ))}
          </Accordion>
        </DrawerBody>
        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
};

export default MobileMenu;
