import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  VStack,
  Image,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Button,
  Link,
  HStack,
  useToast,
  Spacer,
  CloseButton,
  ListItem,
  ListIcon,
  List,
} from '@chakra-ui/react';

import {
  CheckIcon,
  EmailIcon,
  PhoneIcon,
} from '@chakra-ui/icons';

import imgLogo from '@assets/logo.svg';
import imgUsernameIcon from '@assets/member/person-icon.svg';
import imgPasswordIcon from '@assets/member/password.svg';
import imgPasswordConfirmIcon from '@assets/member/password-confirm.svg';

import { BLACK, GREY, RED } from '@constants/colors';
import { AUTH, BERANDA, LOGIN, OTP } from '@constants/urls';

import CustomButton from '@components/Button';
import { useGlobalState } from '@contexts/globalState';

import fetchRequest from '@utils/fetcher';

const SignUpCard = () => {
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isConfirmPassInvalid, setIsConfirmPassInvalid] = useState(true);
  const [isEmailInvalid, setIsEmailInvalid] = useState(true);
  const [isPhoneInvalid, setIsPhoneInvalid] = useState(true);

  const navigate = useNavigate();

  const { setSignUpInfo } = useGlobalState();

  const toast = useToast();

  const handleSignUp = async () => {
    const bodyParam = {
      name,
      email,
      password,
      phoneNumber,
    };

    try {
      await fetchRequest({
        method: 'POST',
        path: 'auth/register',
        data: bodyParam,
      }).then((response) => {
        if (response.statusCode && response.statusCode !== 201) {
          toast({
            title: 'Error',
            description: response.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        } else {
          setSignUpInfo({ email, password });
          navigate(AUTH + OTP);
        }
      });
    } catch (error: any) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleConfirmPassword = (value: string) => {
    setConfirmationPassword(value);
    if (value !== password) {
      setIsConfirmPassInvalid(true);
    } else {
      setIsConfirmPassInvalid(false);
    }
  };

  const handlePassword = (value: string) => {
    setPassword(value);
    if (value !== confirmationPassword) {
      setIsConfirmPassInvalid(true);
    } else {
      setIsConfirmPassInvalid(false);
    }
  };

  const handleEmail = (value: string) => {
    const isEmail = value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    setEmail(value);

    if (isEmail) {
      setIsEmailInvalid(false);
    } else {
      setIsEmailInvalid(true);
    }
  };

  const handlePhoneNumber = (value: string) => {
    const isNumber = value.match(/^[0-9\b]+$/);

    setPhoneNumber(value);

    if (isNumber) {
      setIsPhoneInvalid(false);
    } else {
      setIsPhoneInvalid(true);
    }

    if (value.length < 11) {
      setIsPhoneInvalid(true);
    }
  };

  return (
    <Box w="100%">
      <VStack align="start">
        <HStack w={'100%'}>
          <Image
            src={imgLogo}
            w={{ base: '40%', lg: '50%' }}
            onClick={() => navigate(BERANDA)}
          />
          <Spacer />
          <CloseButton
            onClick={() => navigate(-2)}
            display={{ md: 'none', base: 'inline' }}
          ></CloseButton>
        </HStack>
        <Text
          className="fontBold"
          fontSize={{ lg: '40px', base: '24px' }}
          color={BLACK}
        >
          Buat Akun Baru
        </Text>
        <Text fontSize={{ lg: 'lg', base: 'sm' }}>
          Masukkan informasi yang sesuai untuk membuat akun baru
        </Text>
        <Box w="100%" paddingTop={{ base: '10px', lg: '30px' }}>
          <Text className="fontSemiBold" fontSize={{ lg: 'md', base: 'sm' }}>
            Data Diri
          </Text>
          <InputGroup paddingTop="5px">
            <InputLeftElement
              pointerEvents="none"
              children={<Image src={imgUsernameIcon} w="30%" />}
              paddingTop="5px"
            />
            <Input
              type="text"
              placeholder="Masukkan Nama"
              _focus={{ boxShadow: 'none', borderColor: RED }}
              fontSize={{ lg: 'md', base: 'sm' }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              errorBorderColor={RED}
              isInvalid={name === ''}
            />
          </InputGroup>
          <InputGroup paddingTop="5px">
            <InputLeftElement
              pointerEvents="none"
              children={<PhoneIcon color="#AAAAAA" />}
              paddingTop="5px"
            />
            <Input
              type="text"
              placeholder="Masukkan Nomor HP"
              _focus={{ boxShadow: 'none', borderColor: RED }}
              fontSize={{ lg: 'md', base: 'sm' }}
              value={phoneNumber}
              onChange={(e) => handlePhoneNumber(e.target.value)}
              isInvalid={isPhoneInvalid}
              errorBorderColor={RED}
            />
          </InputGroup>
          <Text
            className="fontSemiBold"
            fontSize={{ lg: 'md', base: 'sm' }}
            paddingTop="10px"
          >
            Data Akun
          </Text>
          <InputGroup paddingTop="5px">
            <InputLeftElement
              pointerEvents="none"
              children={<EmailIcon color="#AAAAAA" />}
              paddingTop="5px"
            />
            <Input
              type="email"
              placeholder="Masukkan Email"
              _focus={{ boxShadow: 'none', borderColor: RED }}
              fontSize={{ lg: 'md', base: 'sm' }}
              value={email}
              onChange={(e) => handleEmail(e.target.value)}
              isInvalid={isEmailInvalid}
              errorBorderColor={RED}
            />
          </InputGroup>
          <Box paddingY="5px">
            <Text> Kata sandi Anda harus memiliki: </Text>
            <List>
              <ListItem>
                <ListIcon as={CheckIcon} color={GREY} />
                Karakter lebih dari 7
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} c color={GREY} />
                Huruf kapital dan huruf kecil
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color={GREY} />
                Angka dan karakter spesial (!, @, #, $, %, &, *)
              </ListItem>
            </List>
          </Box>
          <InputGroup paddingTop="5px">
            <InputLeftElement
              pointerEvents="none"
              children={<Image src={imgPasswordIcon} w="30%" />}
              paddingTop="5px"
            />
            <Input
              type={showPassword ? 'text' : 'password'}
              placeholder="Masukkan Password"
              _focus={{ boxShadow: 'none', borderColor: RED }}
              fontSize={{ lg: 'md', base: 'sm' }}
              value={password}
              onChange={(e) => handlePassword(e.target.value)}
              isInvalid={password === '' || password.length < 8}
              errorBorderColor={RED}
            />
            <InputRightElement width={showPassword ? '130px' : '65px'}>
              <Button
                h="2rem"
                size="sm"
                onClick={() => setShowPassword(!showPassword)}
                marginTop="10px"
                marginRight="5px"
                _focus={{ boxShadow: 'none' }}
                fontSize={{ lg: 'md', base: 'xs' }}
              >
                {showPassword ? 'SEMBUNYIKAN' : 'LIHAT'}
              </Button>
            </InputRightElement>
          </InputGroup>
          <InputGroup paddingTop="5px">
            <InputLeftElement
              pointerEvents="none"
              children={<Image src={imgPasswordConfirmIcon} w="40%" />}
              paddingTop="5px"
            />
            <Input
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Konfirmasi Password"
              _focus={{ boxShadow: 'none', borderColor: RED }}
              fontSize={{ lg: 'md', base: 'sm' }}
              value={confirmationPassword}
              onChange={(e) => handleConfirmPassword(e.target.value)}
              isInvalid={isConfirmPassInvalid}
              errorBorderColor={RED}
            />
            <InputRightElement width={showConfirmPassword ? '130px' : '65px'}>
              <Button
                h="2rem"
                size="sm"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                marginTop="10px"
                marginRight="5px"
                _focus={{ boxShadow: 'none' }}
                fontSize={{ lg: 'md', base: 'xs' }}
              >
                {showConfirmPassword ? 'SEMBUNYIKAN' : 'LIHAT'}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Box w="100%" paddingTop="30px">
            <CustomButton
              text="MASUK"
              w="100%"
              variant="red"
              icon="none"
              wMobile="100%"
              onClick={handleSignUp}
              disabled={
                isConfirmPassInvalid ||
                isEmailInvalid ||
                isPhoneInvalid ||
                password === '' ||
                name === '' ||
                password.length < 8
              }
            />
          </Box>
          <Box w="100%" paddingTop={{ base: '20px', lg: '30px' }}>
            <VStack align={{ base: 'center', lg: 'start' }}>
              <HStack>
                <Text
                  className="fontSemiBold"
                  fontSize={{ lg: 'md', base: 'xs' }}
                >
                  Sudah memiliki akun?
                </Text>
                <Text
                  className="fontSemiBold"
                  color={RED}
                  as="u"
                  fontSize={{ lg: 'md', base: 'xs' }}
                >
                  <Link href={AUTH + LOGIN}>Masuk Akun</Link>
                </Text>
              </HStack>
            </VStack>
          </Box>
        </Box>
      </VStack>
    </Box>
  );
};

export default SignUpCard;
