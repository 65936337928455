export const SEARCH_CATEGORY = {
  All: {
    name: 'Semua',
    value: 'All',
  },
  News: {
    name: 'Berita',
    value: 'News',
  },
  Article: {
    name: 'Artikel',
    value: 'Article',
  },
  Publication: {
    name: 'Publikasi',
    value: 'Publication',
  },
};
